export const headerConfig = {
  headerTitle: 'Employees',
  headerDescription: 'List of Employees',
  refreshButton: {
    id: 'refresh',
    label: 'Refresh',
    icon: 'RefreshIcon',
  },
  headerAction1: {
    id: 'invite_employee',
    label: 'Add Employee',
    icon: 'PlusIcon',
  },
};

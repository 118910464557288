//@ts-nocheck
import moment from 'moment';
import { getFormData } from './getFormData';
import { USER_LEVEL } from '@/config/userManagement.config';
import { APPLICATION_STATUS } from '@/config/applicationStatus.config';

export const getWidgetData = (props: {
  data: any;
  initialData: any;
  applicationNo: any;
  credentialNo: any;
  workPermitFormData: any;
  csmeFormData: any;
  renewCsmeFormData: any;
  timelinedata: any;
  marriageExemptionFormData: any;
  csmeVerificationFormData: any;
  renewWorkPermitFormData: any;
  roeworkPermitFormData: any;
  issuedData: any;
  from: any;
  whoAmI: any;
  orgrole: any;
}) => {
  const {
    data,
    initialData,
    applicationNo,
    credentialNo,
    workPermitFormData,
    csmeFormData,
    roeworkPermitFormData,
    renewCsmeFormData,
    marriageExemptionFormData,
    csmeVerificationFormData,
    renewWorkPermitFormData,
    from,
    whoAmI,
    timelinedata,
    orgrole
  } = props;

  console.log('issuedAt:->initniadnsdf', initialData?.type);
  const formatCreatedDate = (date: Date) => {
    if (date) {
      return moment(date).format('LLL');
    }
  };
  const formatString = (inputString: any) => {
    const stringWithSpaces = inputString.replace('_', ' ');
    const words = stringWithSpaces.split(' ');
    const pascalCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    const formattedString = pascalCaseWords.join(' ');
    return formattedString;
  };

  const getFormattedStatus = (status: string,displayStatus: string) => {
    if (displayStatus === 'INCOMPLETE') {
      if (APPLICATION_STATUS?.[status]) {
        return APPLICATION_STATUS?.[status];
      }
    }
    if (displayStatus === 'PAYMENT_REQUIRED') {
      if (APPLICATION_STATUS?.[status]) {
        return APPLICATION_STATUS?.[status];
      }
    }
    if(!displayStatus){
      if (APPLICATION_STATUS?.[status]) {
        return APPLICATION_STATUS?.[status];
      }
    }
    else {if (APPLICATION_STATUS?.[displayStatus]) {
        return APPLICATION_STATUS?.[displayStatus];
      }
    }
    return '-';
  
  };

  const renderIf = (data: any, ele: any) => {
    console.log(data, 'lo->');
    if (Array.isArray(data) && data.length > 0) {
      return [ele];
    }
    return [];
  };

  let orgFormData: any[] = [];
  let userFormData: any[] = [];
  let orgFileData: any[] = [];
  let userFileData: any[] = [];
  let evidenceData: any[] = [];
  let certificateData: any[] = [];
  let evidenceformatdata: any[] = [];
  let specialData: any[] = [];
  let specialNavItems: any[] = [];
  let evidenceDatacmt: any[] = [];
  let issuedData: any[] = [];
  let evidenceDataTitle = {
    BIRTH_CERTIFICATE_PROOF: 'Birth Certificate Proof',
    NAIONALITY_PROOF: 'Nationality Proof',
    OCCUPATION_PROOF: 'Occupation Proof',
    PASSPORT_PROOF: 'Passport Proof',
    QUALIFICATION_PROOF: 'Qualification Proof',
    WORK_EXPERIENCE_PROOF: 'Work Experience Proof',
    PHOTOGRAPH: 'Photograph',
    POLICE_CLEARANCE_CERTIFICATE: 'Police Clearance Certificate',
    RESUME_PROOF: 'Resume Proof',
    COVERING_LETTER_PROOF: 'Covering Letter Proof',
    PREVIOUS_EMPLOYER_PROOF: 'Previous Employer Proof',
    PREVIOUS_EMPLOYER_PROOF_DEP: 'Previous Employer Proof Dep',
    JOB_TITLE_PROOF: 'Job Title Proof',
    TAX_COMPILANCE: 'Tax Compilance',
    BY_ADVERTISEMENT_FILE: 'By Advertisement File',
    STEP_TAKEN_PROOF: 'Step Taken Proof',
    APPLICANT_SIGNATURE: 'Applicant Signature',
    EMPLOYER_SIGNATURE: 'Employer Signature',
    CANDIDATE_RESUME: 'Candidate Resume',
    ACADEMIC_CERTIFICATE_PROOF: 'Academic Certificate Proof',
    MARRIAGE_CERTIFICATE_PROOF: 'Marriage Certificate Proof',
    DIVORCE_CERTIFICATE_PROOF: 'Divorce Certificate Proof',
    ADOPTION_CERTIFICATE_PROOF: 'Adoption Certificate Proof',
    NAME_CHANGE_PROOF: 'Name Change Proof',
    CERTIFIED_COPY_OF_BIRTH_CERTIFICATE: 'Certified Copy Of Birth Certificate',
    BIODATA_PAGE_COPY: 'Biodata Page Copy',
    DEED_POLL_COPY: 'Deed Poll Copy',
  };
  console.log('find->->', initialData);
  console.log('cred->', credentialNo, typeof credentialNo);

  if (initialData && Object.entries(initialData)?.length > 0) {
    Object.entries(initialData)?.forEach((item) => {
      if (
        item[0] === '_initialData' ||
        item[0] === '__initialData' ||
        item[0] === 'issuedData'
      ) {
        console.log('jrampppp', item);
        const { fileData, formData, isUser, _specialData, _specialNavItems } =
          getFormData(
            item[1],
            applicationNo,
            credentialNo,
            workPermitFormData,
            csmeFormData,
            renewCsmeFormData,
            marriageExemptionFormData,
            csmeVerificationFormData,
            renewWorkPermitFormData,
            roeworkPermitFormData
          );
        console.log('find12->', _specialNavItems, isUser, specialNavItems);
        if (isUser) {
          userFileData = [...userFileData, ...fileData];
          userFormData = [...userFormData, ...formData];
        } else {
          orgFileData = [...orgFileData, ...fileData];
          orgFormData = [...orgFormData, ...formData];
        }
        (specialData = [...specialData, ..._specialData]),
          (specialNavItems = [...specialNavItems, ..._specialNavItems]);
      }
    });
    console.log('ug->', userFormData);
    if (Object.entries(initialData?.issuedData)?.length > 0) {
      userFormData = userFormData?.map((item) => {
        if (item?.title === 'Business Name') {
          return { ...item, title: 'Employer' };
        }
        return { ...item };
      });
    }
    if (initialData?.certificate) {
      const ans = initialData?.certificate;
      console.log('pdfcoming...');
      certificateData.push({
        type: 'pdf',
        src: ans?.src,
        title: [ans?.name],
      });
    }
    if (initialData?._initialData?.evidencedocuments) {
      // Object.entries(initialData._initialData?.evidencedocuments)?.forEach((item) => {
      //   if(item.length > 0){
      //     if(item[1].length>0)
      //     evidenceData=item[1];
      evidenceData = initialData?._initialData?.evidencedocuments;
      if (
        initialData?.appealInfo &&
        Array.isArray(initialData?.appealInfo) &&
        initialData?.appealInfo?.length > 0 &&
        initialData?.appealInfo[0]?.comment
      ) {
        evidenceDatacmt.push({
          title:
            initialData?.appealInfo[initialData?.appealInfo?.length - 1]
              ?.comment,
          // subTitle:'hii',
          icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
        });
      }

      if (Array.isArray(evidenceData)) {
        if (evidenceData?.length > 0) {
          console.log('service args container', evidenceData);
          for (let i = 0; i < evidenceData?.length; i++) {
            if (evidenceData[i]?.type === 'image/png') {
              evidenceformatdata.push({
                type: 'img',
                src: evidenceData[i]?.src,
                meta: [evidenceData[i]?.name, evidenceData[i]?.size],
                title:
                  evidenceDataTitle?.[evidenceData[i]?.docType] ||
                  'Evidence Data',
              });
            } else if (evidenceData[i]?.type === 'application/pdf') {
              evidenceformatdata.push({
                type: 'pdf',
                src: evidenceData[i]?.src,
                meta: [evidenceData[i]?.name, evidenceData[i]?.size],
                title:
                  evidenceDataTitle?.[evidenceData[i]?.docType] ||
                  'Evidence Data',
              });
            } else if (evidenceData[i]?.type === 'image/jpeg') {
              evidenceformatdata.push({
                type: 'img',
                src: evidenceData[i]?.src,
                meta: [evidenceData[i]?.name, evidenceData[i]?.size],
                title:
                  evidenceDataTitle?.[evidenceData[i]?.docType] ||
                  'Evidence Data',
              });
            }
          }
        }
      }
    }
    console.log('usrfile->', userFileData, orgFileData);
  }
  console.log('issued->', issuedData, userFormData);
  //@ts-ignore
  const profileData =
    initialData?._initialData &&
    Object.entries(initialData?._initialData)?.length > 0
      ? initialData?._initialData
      : initialData?.issuedData;
  console.log('profile->', profileData, initialData);

  const certificateItems = initialData?.certitrn
    ? [
        {
          key: 'trn',
          title: 'TRN',
          subTitle: initialData?.certitrn,
          icon: 'https://i.ibb.co/J7LRK9g/tax-1.png',
        },
      ]
    : [];
  const additionalItems =
    applicationNo && applicationNo?.startsWith('NCSME')
      ? []
      : [
          {
            key: 'trn',
            title: 'TRN',
            subTitle: profileData?.businessTrn,
            icon: 'https://i.ibb.co/J7LRK9g/tax-1.png',
          },
        ];
  let lastModified = formatCreatedDate(initialData?.lastModified || '') || '';
  let issuedon = formatCreatedDate(initialData?.issuedon || '') || '';
  let appliedOn = formatCreatedDate(initialData?.appliedOn || '') || '';
  let addOn = appliedOn
    ? [
        {
          key: 'appliedOn',
          title: 'Applied on',
          subTitle: appliedOn || '-',
          icon: 'https://i.ibb.co/48t2Jzj/calendar-2.png',
        },
      ]
    : [];

  console.log('gho->', issuedData);
  const appType = credentialNo
    ? []
    : [
        {
          key: 'applicationType',
          title: credentialNo ? 'Type' : 'Application Type',
          subTitle: formatString(initialData?.type || '') || '-',
          icon: 'https://i.ibb.co/9g5t0Cx/documents-2.png',
        },
      ];

  const widgetData = {
    profileData: {
      //@ts-ignore,
      legacy:initialData?.legacy,
      badgeColor:initialData?.badgeColor,
      badgeBg:initialData?.badgeBg,
      downloadButtonText: 'Download',
      ...(from === 'credential' || from ==='renew' && {
        statusProps: {
          statusComp: {
            statusType: 'info',
            title: `Verify on Blockchain`,
          },
        },
        submitBtnText: '',
        verifyBtnText: 'Verify on Blockchain',
      }),
      verifyBtnText: credentialNo ? 'Verify on Blockchain' : '',
      ...((initialData?.status === 'EXPIRING_SOON' ||
        initialData?.status === 'EXPIRED') &&
      initialData?.renewalStatus === 'PENDING_FOR_RENEWAL' &&
      ((USER_LEVEL.owner.includes(whoAmI) &&
        (initialData?.type === 'WORK_PERMIT' || orgrole==='CARICOM_BUSSINESS_ORG')) || 
        !USER_LEVEL.owner.includes(whoAmI)) && orgrole!=='ISSUER'
        ? { submitBtnText: 'Renew' }
        : initialData?.status === 'READY_TO_PAY' ||
          initialData?.status === 'APPLICATION_APPROVED' ||
          initialData?.status === 'WAITING_FOR_WP_FEE'
        ? { submitBtnText: 'Proceed to Pay' }
        : initialData?.status === 'APPLICATION_DENIED' &&
          initialData?.status !== 'APPEALED'
        ? {
            statusProps: {
              statusComp: {
                statusType: 'danger',
                title: data?._initialData?.comment
                  ? `Comment : ${data?._initialData?.comment}`
                  : '',
              },
            },
            submitBtnText: 'Appeal',
          }
        : initialData?.status === 'APPEALED'
        ? {
            statusProps: {
              statusComp: {
                statusType: 'primary',
                title: 'Appealed',
              },
            },
          }
        : initialData?.status === 'MISSING_INFORMATION'
        ? {
            statusProps: {
              statusComp: {
                statusType: 'danger',
                title: data?._initialData?.comment
                  ? `Comments : ${data?._initialData?.comment}`
                  : null,
              },
            },
            submitBtnText: 'Update Missing Information',
          }
        : initialData?.status === 'DOCUMENTATION_REJECTED'
        ? {
            statusProps: {
              statusComp: {
                statusType: 'danger',
                title: data?._initialData?.comment
                  ? `Comments : ${data?._initialData?.comment}`
                  : null,
              },
            },
            submitBtnText: 'Update Documents',
          }
        : {}),
      ...(from === 'renew' &&
      ((initialData?.status !== 'EXPIRED' &&
        initialData?.type === 'WORK_PERMIT' &&
        USER_LEVEL.owner.includes(whoAmI) &&
        initialData?.status !== 'TERMINATION_REQUESTED' &&
        initialData?.status !== 'TERMINATED') ||
        initialData?.status === 'EXPIRING_SOON') &&
      !(initialData?.type === 'MARRIAGE_EXEMPTION_CERTIFICATE') &&
      ((USER_LEVEL.owner.includes(whoAmI) &&
       initialData?.type === 'WORK_PERMIT') || 
        !USER_LEVEL.owner.includes(whoAmI)) && orgrole!=='ISSUER'
        ? { terminateText: 'Terminate' }
        : initialData?.status === 'TERMINATED'
        ? {
            statusProps: {
              statusComp: {
                statusType: 'danger',
                title: `Terminated`,
              },
            },
          }
        : initialData?.status === 'TERMINATION_REQUESTED'
        ? {
            statusProps: {
              statusComp: {
                statusType: 'warning',
                title: `Termination Requested`,
              },
            },
          }
        : {}),
      activeKey:
        certificateData?.length > 0
          ? 'certificate'
          : orgFormData?.length > 0
          ? 'org-details'
          : 'personal-details',
      profileImage: profileData?.photograph
        ? Array.isArray(profileData?.photograph) &&
          profileData?.photograph?.length > 0 &&
          profileData?.photograph?.[0]?.src
        : '',
      name: `${profileData?.lastName ?? ''}, ${profileData?.firstName ?? ''}`,
      isDetail: true,
      legacy:profileData?.legacy,
      badgeColor:profileData?.badgeColor,
      badgeBg:profileData?.badgeBg,
      listItems: [
        {
          key: 'id',
          title: applicationNo
            ? 'Application ID'
            : initialData?.type === 'WORK_PERMIT'
            ? 'Work Permit No'
            : 'Certificate No',
          subTitle: applicationNo ?? credentialNo,
          icon: 'https://i.ibb.co/QH6mxdw/id-card-3.png',
        },
        ...addOn,
        issuedon
          ? {
              key: 'issuedOn',
              title: 'Issued on',
              subTitle: issuedon || '-',
              icon: 'https://i.ibb.co/48t2Jzj/calendar-2.png',
            }
          : {
              key: 'lastModified',
              title: 'Last Modified on',
              subTitle: lastModified || '-',
              icon: 'https://i.ibb.co/48t2Jzj/calendar-2.png',
            },
        {
          key: 'status',
          title: 'Status',
          subTitle: getFormattedStatus(data?.status,data?.displayStatus),
          icon: 'https://i.ibb.co/rx6mPgs/status-1.png',
        },
        ...appType,

        // ...additionalItems,
        ...certificateItems,
      ],

      navItems: [
        ...renderIf(certificateData, {
          key: 'certificate',
          title: 'Certificate',
          link: '/certificate',
          type: 'navbar',
        }),
        ...renderIf(issuedData, {
          key: 'issued',
          title: 'Details',
          link: '/issued',
          type: 'navbar',
        }),
        ...renderIf(orgFormData, {
          key: 'org-details',
          title: 'Organization Details',
          link: '/org-details',
          type: 'navbar',
        }),
        ...renderIf(orgFileData, {
          key: 'org-documents',
          title: 'Organization Documents',
          link: '/org-documents',
          type: 'navbar',
        }),
        ...renderIf(userFormData, {
          key: 'personal-details',
          title: 'Applicant Details',
          link: '/personal-details',
          type: 'navbar',
        }),

        ...renderIf(userFileData, {
          key: 'user-documents',
          title: 'Applicant Documents',
          link: '/user-documents',
          type: 'navbar',
        }),
        ...specialNavItems,
        ...renderIf(evidenceData, {
          key: 'appeal-details',
          title: 'Appeal details',
          link: '/appeal-details',
          type: 'navbar',
        }),
        ...renderIf(timelinedata, {
          key: 'timeline',
          title: 'Timeline',
          link: '/timeline',
          type: 'navbar',
        }),
      ],
    },
    itemSpecData: [
      {
        type: 'media-carousel',
        key: 'certificate',
        data: certificateData,
      },
      {
        type: 'multi-col-list',
        key: 'org-details',
        numberOfColumns: '3',
        data: orgFormData,
      },
      {
        type: 'multi-col-list',
        key: 'issued',
        numberOfColumns: '3',
        data: issuedData,
      },
      {
        type: 'multi-col-list',
        key: 'personal-details',
        numberOfColumns: '3',
        data: userFormData,
      },
      ...specialData,
      {
        type: 'media-carousel',
        key: 'org-documents',
        data: orgFileData,
      },
      {
        type: 'media-carousel',
        key: 'user-documents',
        data: userFileData,
      },
      {
        type: 'accordion',
        key: 'appeal-details',
        data: [
          {
            type: 'multi-col-list',
            title: `Appeal Comment`,
            numberOfColumns: '3',
            data: evidenceDatacmt || [],
          },
          {
            type: 'media-carousel',
            title: `Appeal Documents`,
            data: evidenceformatdata,
          },
        ],
      },
      {
        type: 'timeline',
        key: 'timeline',
        data: timelinedata,
      },
    ],
    paginationProps: {
      totalCount: 2,
      totalPages: 1,
      forcePage: 0,
      totalItems: 2,
    },
  };
  console.log('widget->', widgetData);
  // (data?._initialData?.comment && {
  //   key: 'comment',
  //   title: 'Reason for Rejection',
  //   subTitle: data?._initialData?.comment,
  //   icon: 'https://w7.pngwing.com/pngs/151/667/png-transparent-computer-icons-no-symbol-reject-angle-sign-rim-thumbnail.png',
  // })

  // if(data?._initialData?.comment){}
  return widgetData;
};

import { StorageModule } from '@vlinder-web/storage-module-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
export const ProtectedImage = (props: any) => {
    let user = props?.alt === 'Avatar' ? true : false;
    let userpic = 'https://i.ibb.co/fCJmCc7/user.png';
    let docpic = 'https://i.ibb.co/xMnh6jS/picture.png';
  const [imageSrc, setImageSrc] = useState('');
  const { src } = props;
  useEffect(() => {
    const fetchPrivateImage = async () => {
      try {
        const storageSrv = StorageModule.getInstance();
        let _sessionToken = storageSrv.get('userProfile', 'session');
        let _localToken = storageSrv.get('userProfile', 'local');
        let _userToken = _sessionToken ?? _localToken;
        let _token = _userToken?.accessToken;
        const response = await axios
          .get(src, {
            headers: {
              'Content-Type': 'application/json',
              Authentication: `${_token}`,
            },
            responseType: 'arraybuffer',
          })
          .then((data) => {
            const reader: any = new FileReader();
            reader.readAsDataURL(new Blob([data?.data]));
            reader.onload = () => {
              setImageSrc(reader.result);
            };
            console.log('zcsdfsa-->data-->image', data);
          })
          .catch((err) => console.log('zcsdfsa-->err-->image', err));
      } catch (error) {
        console.error('Error fetching private image:', error);
      }
    };

    fetchPrivateImage();
  }, []);
  console.log('protectedimage-->props-->', imageSrc);
  return <Image {...props} src={imageSrc || (user?userpic:docpic)} />;
};

const renderIf = (data: any, ele: any) => {
  console.log(data, 'lo->');
  if (Array.isArray(data) && data.length > 0) {
    return [ele];
  }
  return [];
};

export const getWidgetData = (
  fields: any[],
  initialData: any,
  isOwner: boolean,
  isRenew?: boolean
) => {
  const _specialData: any[] = [];
  const _specialNavItems: any[] = [];
  const special: any = {
    qualification: 'Qualification',
    dependentInfo: 'Dependents',
    workExperience: 'Work Experience',
  };
  let orgFormData: any[] = [];
  let userFormData: any[] = [];
  let orgFileData: any[] = [];
  let userFileData: any[] = [];
  for (let step = 0; step < (isOwner ? 2 : 1); step++) {
    console.log(step, fields, 'lp->');
    fields?.[step]?.forEach((_item: any) => {
      let ans =initialData?.[_item?.compProps?.id] && (typeof(initialData?.[_item?.compProps?.id])==='string' || typeof(initialData?.[_item?.compProps?.id])==='number')? initialData?.[_item?.compProps?.id]+'':initialData?.[_item?.compProps?.id];
      console.log("awjfhasdjf",_item?.compProps?.id,initialData?.[_item?.compProps?.id],typeof(initialData?.[_item?.compProps?.id]));
      console.log('first->', _item, _item?.compProps?.id, ans);
      if (
        special[_item?.compProps?.id] &&
        Array.isArray(ans) &&
        ans.length > 0
      ) {
        _specialNavItems.push({
          key: _item?.compProps?.id,
          title: special[_item?.compProps?.id],
          link: `/${_item?.compProps?.id}`,
          type: 'navbar',
        });
        const reqEle = _item?.compProps?.attributeForm?.fields;
        const totalData: any[] = [];
        console.log('liu->', ans);
        ans?.forEach((val) => {
          const currData: any[] = [];
          Object.entries(val)?.forEach((_item) => {
            const ans = reqEle?.filter(
              (element: any) => _item[0] === element?.compProps?.id
            )?.[0];
            console.log('liu->', ans);
            if (_item[0] !== 'id' && ans?.compProps?.label && _item[1]) {
              currData.push({
                title: ans?.compProps?.label,
                subTitle: _item[1],
                icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
              });
            }
          });
          console.log('liu->', currData);
          totalData.push(currData);
        });

        _specialData.push({
          type: 'accordion',
          key: _item?.compProps?.id,
          data: totalData?.map((data, key: number) => {
            return {
              type: 'multi-col-list',
              title: `${special[_item?.compProps?.id]} ${key + 1}`,
              numberOfColumns: '3',
              data: data,
            };
          }),
        });
      } else if (Array.isArray(ans)) {
        if (ans?.length > 0) {
          if (
            (ans[0]?.type === 'image/png' ||
              ans[0]?.type === 'image/jpg' ||
              ans[0]?.type === 'image/jpeg') &&
            !ans[0]?.operation
          ) {
            if (step === 1) {
              orgFileData.push({
                type: 'img',
                src: ans[0]?.base64 || ans[0]?.src,
                meta: [ans[0]?.name, ans[0]?.size],
                title: [_item?.compProps?.label],
              });
            } else {
              userFileData.push({
                type: 'img',
                src: ans[0]?.base64 || ans[0]?.src,
                meta: [ans[0]?.name, ans[0]?.size],
                title: [_item?.compProps?.label],
              });
            }
          } else if (ans[0]?.type === 'application/pdf' && !ans[0]?.operation) {
            if (step === 1) {
              orgFileData.push({
                type: 'pdf',
                src: ans[0]?.base64 || ans[0]?.src,
                meta: [ans[0]?.name, ans[0]?.size],
                title: [_item?.compProps?.label],
              });
            } else {
              userFileData.push({
                type: 'pdf',
                src: ans[0]?.base64 || ans[0]?.src,
                meta: [ans[0]?.name, ans[0]?.size],
                title: [_item?.compProps?.label],
              });
            }
          }
        }
      } else {
        if (step === 1) {
          console.log(
            'hi->',
            _item?.compProps?.id,
            _item?.compProps?.label,
            ans,
            _item?.compProps?.id,
            !_item?.compProps?.id?.startsWith('is')
          );
          if (
            _item?.compProps?.label &&
            ans &&
            typeof ans === 'string' &&
            _item?.compProps?.id &&
            !_item?.compProps?.id?.startsWith('is')
          ) {
            orgFormData.push({
              title: _item?.compProps?.label,
              subTitle: ans?.includes(':') ? ans?.split(':')?.[1] : ans,
              icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
            });
          }
        } else {
          if (
            _item?.compProps?.label &&
            ans &&
            typeof ans === 'string' &&
            _item?.compProps?.id &&
            !_item?.compProps?.id?.startsWith('is')
          ) {
            userFormData.push({
              title: _item?.compProps?.label,
              subTitle: ans?.includes(':') ? ans?.split(':')?.[1] : ans,
              icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
            });
          }
        }
      }
    });
  }

  const userData = initialData?.firstName
    ? {
        profileImage: initialData?.photograph?.[0]?.base64,
        name: `${initialData?.lastName ?? ''} ${initialData?.firstName ?? ''}`,
        listItems: [
          // {
          //       key: 'id',
          //       title: 'ID',
          //       subTitle: applicationNo,
          //       icon: 'https://w7.pngwing.com/pngs/263/375/png-transparent-computer-icons-user-profile-corporate-id-card-text-rectangle-identity-document-thumbnail.png',
          //     },
          // {
          //   key: 'name',
          //   title: 'Name',
          //   subTitle: initialData?.lastName ?? initialData?.firstName,
          //   icon: 'https://w7.pngwing.com/pngs/930/532/png-transparent-computer-icons-personal-web-page-user-name-icon-monochrome-user-tag.png',
          // },
          // {
          //   key: 'trn',
          //   title: 'TRN',
          //   subTitle: initialData?.businessTrn,
          //   icon: 'https://w7.pngwing.com/pngs/770/281/png-transparent-income-tax-computer-icons-indirect-tax-others-saving-angle-company-thumbnail.png',
          // },
        ],
      }
    : {};
  const userDetails = !isOwner ? [..._specialNavItems] : [];
  const orgDetails = isOwner ? [..._specialNavItems] : [];
  const widgetData = {
    submitData: {
      submitBtnText: 'Submit',
      checkBoxText:
        'I hereby certify that the information submitted in this Application is true, accurate and complete to the best of my knowledge and belief and nothing has been concealed or distorted. I understand and agree that any false or misleading information will justify a refusal of the application and/or, revocation of the work permit if granted.',
    },
    profileData: {
      ...userData,
      activeKey: orgFormData?.length > 0 ? 'org-details' : 'personal-details',

      navItems: [
        ...renderIf(orgFormData, {
          key: 'org-details',
          title: 'Organization Details',
          link: '/org-details',
          type: 'navbar',
        }),
        ...renderIf(orgFileData, {
          key: 'org-documents',
          title: 'Organization Documents',
          link: '/org-documents',
          type: 'navbar',
        }),
        ...orgDetails,
        ...renderIf(userFormData, {
          key: 'personal-details',
          title: 'Applicant Details',
          link: '/personal-details',
          type: 'navbar',
        }),
        ...renderIf(userFileData, {
          key: 'user-documents',
          title: 'Applicant Documents',
          link: '/user-documents',
          type: 'navbar',
        }),
        ...userDetails,
      ],
    },
    itemSpecData: [
      {
        type: 'accordion',
        key: 'org-details',
        data: [
          {
            type: 'multi-col-list',
            title: 'Organization Details',
            numberOfColumns: '3',
            data: orgFormData,
          },
        ],
      },

      ..._specialData,
      {
        type: 'accordion',
        key: 'personal-details',
        data: [
          {
            type: 'multi-col-list',
            title: 'Applicant Details',
            numberOfColumns: '3',
            data: userFormData,
          },
        ],
      },
      {
        type: 'media-carousel',
        key: 'org-documents',
        data: orgFileData,
      },
      {
        type: 'media-carousel',
        key: 'user-documents',
        data: userFileData,
      },
    ],
    paginationProps: {
      totalCount: 2,
      totalPages: 1,
      forcePage: 0,
      totalItems: 2,
    },
  };
  console.log('lo->', widgetData);

  return widgetData;
};

import React from 'react';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  UserRemoveIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import moment from 'moment-timezone';
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
  Image,
} from 'react-bootstrap';
import { ToggleAction } from './ToggleAction';
import { StorageModule } from '@vlinder-web/storage-module-react';

import * as S from './styles';

const capitalizeFirstLetter = (string: any) =>
  string[0].toUpperCase() + string.slice(1);

const getFirstLetterOfEachWord = (text: any) => {
  try {
    let Rtext = text && text !== '' ? text?.match(/\b\w/g).join('') : '';
    console.log(`Rtext`, Rtext, Rtext.length);
    if (Rtext && Rtext.length > 2) {
      return Rtext.substring(0, 2);
    } else {
      return Rtext;
    }
  } catch (error) {}
};

export const DomainTableRow = (props: any) => {
  const {
    id,
    verified,
    status,
    image,
    firstName,
    lastName,
    middleName,
    applicationId,
    appliedOn,
    type,
    data,
    email,
    dateCreated,
    isSelected,
    selectUser,
    deleteUsers,
    onTogglePress,
    onViewDetailClick,
    onViewClick,
    role,
  } = props;
  console.log('finalprops', props);
  const { applicantInfo, updatedAt } = props.data;

  console.log('final:::', applicantInfo, updatedAt, status);
  const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;
  const storageSrv = StorageModule?.getInstance();
  const userProfile = storageSrv.get('myProfile', 'session');

  const variantColor =
    status === 'completed'
      ? '#00AB08'
      : status === 'inProgress'
      ? '#FE8D01'
      : status === 'rejected'
      ? '#DC3545'
      : status === 'verified'
      ? '#691783'
      : '#0000FF';

  const statusVariant =
    status === 'active'
      ? 'success'
      : status === 'created'
      ? 'warning'
      : status === 'invited'
      ? 'purple'
      : status === 'verified'
      ? 'purple'
      : status === 'suspended'
      ? 'danger'
      : 'primary';

  const onToggle = (key: string) => {
    console.log('ifcaseparent', id, applicationId);
    if (key === 'view') {
      console.log('1stifcase');
      onViewClick && onViewClick(applicationId);
    } else {
      console.log('2ndifcase');
      onTogglePress && onTogglePress(key, applicationId);
    }
  };
  const formatCreatedDate = (date: Date) => {
    if (date) {
      return moment(date).format('LLL');
    }
  };

  const renderCircle = (roles: any) => {
    const colors = ['008000', '800080', 'A52A2A', 'FF7F50', 'd2d2d2', '29A6A6'];

    const output: any = [];

    const returnImg = () => {
      if (Array.isArray(roles)) {
        roles?.forEach((item, index) => {
          output.push(
            <li className="d-inline-block">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="m-0">{item}</Tooltip>}
              >
                <Image
                  style={{ width: '40px' }}
                  className="img-40 rounded-circle"
                  src={`https://ui-avatars.com/api/?name=${item}&background=${colors[index]}&color=fff`}
                />
              </OverlayTrigger>
            </li>
          );
        });
      }
      if (typeof role === 'string') {
        const randomIndex = Math.floor(Math.random() * colors.length);
        output.push(
          <li className="d-inline-block">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip className="m-0">{role}</Tooltip>}
            >
              <Image
                style={{ width: '40px' }}
                className="img-40 rounded-circle"
                src={`https://ui-avatars.com/api/?name=${role}&background=${colors[randomIndex]}&color=fff`}
              />
            </OverlayTrigger>
          </li>
        );
      }
      return output;
    };

    return (
      <div
        className="roleAvatar"
        style={{ width: '100%', inlineSize: 'max-content' }}
      >
        <ul style={{ paddingLeft: '0px' }}>{returnImg()}</ul>
      </div>
    );
  };

  return (
    <tr className="border-bottom user-table">
      {/* <td>
        <FormCheck type="checkbox" className="dashboard-check">
          <FormCheck.Input
            id={`user-${id}`}
            checked={isSelected}
            onChange={() => selectUser(id)}
          />
          <FormCheck.Label htmlFor={`user-${id}`} />
        </FormCheck>
      </td> */}
      <td>
        <span className="fw-normal">{applicationId}</span>
      </td>
      <td>
        <ToggleAction status={status} onTogglePress={onToggle} />
      </td>
      <td>
        <span className="fw-normal">{type}</span>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <div className="d-block">
            <span className="fw-normal">
              {applicantInfo.lastName}, {applicantInfo.middleName}{' '}
              {applicantInfo.firstName}
            </span>
          </div>
        </Card.Link>
      </td>

      <td>
        <span className="fw-normal">{formatCreatedDate(updatedAt)}</span>
      </td>

      <td>
        <span className={`fw-normal `}>
          {status && capitalizeFirstLetter(status)}
        </span>
      </td>
      <td>
        <span className={`fw-normal `}>{data.comment}</span>
      </td>
    </tr>
  );
};

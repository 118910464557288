//@ts-nocheck
import { SalesValueWidget } from '../SalesValueWidget';
import React, { useEffect, useRef, useState } from 'react';
export type IAnimatedWidgetCompProps = SalesValueWidget;
export const AnimatedSalesValueWidget = ({
  ...compProps
}: IAnimatedWidgetCompProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const widgetRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const widgetElement = widgetRef.current;
      if (widgetElement && !hasAnimated) {
        const { top, bottom } = widgetElement.getBoundingClientRect();
        const isFullyVisible = top >= 0 && bottom <= window.innerHeight;
        if (isFullyVisible) {
          setIsVisible(true);
          setHasAnimated(true);
          window.removeEventListener('scroll', handleScroll); // Remove scroll event listener once animation is triggered
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasAnimated]);
  return (
    <div ref={widgetRef}>
      {isVisible && <SalesValueWidget {...compProps} />}
    </div>
  );
};
AnimatedSalesValueWidget.defaultProps = {};

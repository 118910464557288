//@ts-nocheck
import { rightEstablishmentConfig } from '../../../config/rightEstablishment.config';

export const getWidgetData = (fields: any[], _initialData: any) => {
  const formData: any[] = [];
  const fileData: any[] = [];
  const _specialData: any[] = [];
  const _specialNavItems: any[] = [];
  let initialData = _initialData;

  let hiddenItems = {};
  const special: any = {
    qualification: 'Qualification',
    dependentInfo: 'Dependents',
  };

  for (let step = 0; step < 2; step++) {
    fields?.[step]?.forEach((_item: any) => {
      let ans = initialData?.[_item?.compProps?.id];
      const dynamicLayoutItem =
        rightEstablishmentConfig?.dynamicLayout?.[_item?.compProps?.id];
      if (dynamicLayoutItem) {
        Object.entries(dynamicLayoutItem)
          ?.filter((item) => item?.[0] !== ans)
          ?.map((subItem) => {
            subItem[1] &&
              subItem[1]?.map((_subItem) => {
                hiddenItems[_subItem] = true;
                delete initialData[_subItem];
              });
          });
      }
      if (
        special[_item?.compProps?.id] &&
        Array.isArray(ans) &&
        ans.length > 0
      ) {
        _specialNavItems.push({
          key: _item?.compProps?.id,
          title: special[_item?.compProps?.id],
          link: `/${_item?.compProps?.id}`,
          type: 'navbar',
        });
        const reqEle = _item?.compProps?.attributeForm?.fields;
        const totalData: any[] = [];
        console.log('liu->', ans);
        ans?.forEach((val) => {
          const currData: any[] = [];
          const currDocData: any[] = [];
          Object.entries(val)?.forEach((_item) => {
            const ans = reqEle?.filter(
              (element) => _item[0] === element?.compProps?.id
            )?.[0];
            console.log('suspect->', ans);
            if (_item[0] !== 'id' && ans?.compProps?.label && _item[1]) {
              console.log('liop->', _item[1]);
              if (typeof _item[1] === 'string') {
                currData.push({
                  title: ans?.compProps?.label,
                  subTitle: _item[1],
                  icon: 'https://i.ibb.co/4JfT8MY/image.png',
                });
              } else if (
                (_item?.[1]?.[0]?.type === 'image/png' ||
                  _item?.[1]?.[0]?.type === 'image/jpg' ||
                  _item?.[1]?.[0]?.type === 'image/jpeg') &&
                !_item?.[1]?.[0]?.operation
              ) {
                currDocData.push({
                  type: 'img',
                  src: _item?.[1]?.[0]?.base64 ?? _item?.[1]?.[0]?.src,
                  meta: [_item?.[1]?.[0]?.name, _item?.[1]?.[0]?.size],
                  title: [ans?.compProps?.label],
                });
              } else if (
                _item?.[1]?.[0]?.type === 'application/pdf' &&
                !_item?.[1]?.[0]?.operation
              ) {
                console.log('pdfcoming...');
                currDocData.push({
                  type: 'pdf',
                  src: _item?.[1]?.[0]?.base64 ?? _item?.[1]?.[0]?.src,
                  meta: [_item?.[1]?.[0]?.name, _item?.[1]?.[0]?.size],
                  title: [ans?.compProps?.label],
                });
              }
            }
          });

          console.log('liu->', currData);
          if (currDocData?.length === 0) {
            totalData.push(currData);
          } else {
            totalData.push({ doc: currDocData, data: currData });
          }
        });

        _specialData.push({
          type: 'accordion',
          key: _item?.compProps?.id,
          data: totalData?.map((data, key: number) => {
            if (data?.doc) {
              return [
                {
                  type: 'multi-col-list',
                  title: `${special[_item?.compProps?.id]} ${key + 1}`,
                  numberOfColumns: '3',
                  data: data?.data,
                },
                {
                  type: 'media-carousel',
                  key: `org-documents ${key + 1}`,
                  data: data?.doc,
                },
              ];
            }
            return {
              type: 'multi-col-list',
              title: `${special[_item?.compProps?.id]} ${key + 1}`,
              numberOfColumns: '3',
              data: data,
            };
          }),
        });
      } else if (Array.isArray(ans)) {
        if (ans?.length > 0 && !hiddenItems[_item?.compProps?.id]) {
          if (
            (ans[0]?.type === 'image/png' ||
              ans[0]?.type === 'image/jpg' ||
              ans[0]?.type === 'image/jpeg') &&
            !ans[0]?.operation
          ) {
            console.log('hi0->', _item?.compProps?.id);
            fileData.push({
              type: 'img',
              src: ans[0]?.base64 ?? ans[0]?.src,
              meta: [ans[0]?.name, ans[0]?.size],
              title: [_item?.compProps?.label],
            });
          } else if (ans[0]?.type === 'application/pdf' && !ans[0]?.operation) {
            fileData.push({
              type: 'pdf',
              src: ans[0]?.base64 ?? ans[0]?.src,
              meta: [ans[0]?.name, ans[0]?.size],
              title: [_item?.compProps?.label],
            });
          }
        }
      } else {
        if (
          _item?.compProps?.label &&
          ans &&
          _item?.compProps?.id &&
          !_item?.compProps?.id?.startsWith('is')
        ) {
          console.log('hidden->', hiddenItems);
          if (!hiddenItems[_item?.compProps?.id]) {
            formData.push({
              title: _item?.compProps?.label,
              subTitle: ans,
              icon: 'https://i.ibb.co/4JfT8MY/image.png',
            });
          }
        }
      }
    });
    console.log(initialData, 'chao->');
    const userData = initialData?.firstName
      ? {
          profileImage: initialData?.photograph?.[0]?.base64,
          name: `${initialData?.lastName ?? ''} ${
            initialData?.firstName ?? ''
          }`,
          listItems: [
            {
              key: 'name',
              title: 'Name',
              subTitle: initialData?.lastName ?? initialData?.firstName,
              icon: 'https://w7.pngwing.com/pngs/930/532/png-transparent-computer-icons-personal-web-page-user-name-icon-monochrome-user-tag.png',
            },
            // {
            //   key: 'trn',
            //   title: 'TRN',
            //   subTitle: initialData?.businessTrn,
            //   icon: 'https://w7.pngwing.com/pngs/930/532/png-transparent-computer-icons-personal-web-page-user-name-icon-monochrome-user-tag.png',
            // },
          ],
        }
      : {};
    const widgetData = {
      profileData: {
        ...userData,
        activeKey: 'accordion',

        submitBtnText: 'Submit',
        checkBoxText:
          'I certify to the best of my knowledge and belief, that the above information is correct.',
        navItems: [
          {
            key: 'accordion',
            title: 'Details',
            // whoami === 'owner'
            //   ? 'Organization Details'
            //   : 'Personal Details',
            link: '/personal',
            type: 'navbar',
          },
          ..._specialNavItems,
          {
            key: 'media-carousel',
            title: 'Documents',
            link: '/documents',
            type: 'navbar',
          },
        ],
      },
      itemSpecData: [
        {
          type: 'accordion',
          key: 'accordion',
          data: [
            {
              type: 'multi-col-list',
              title: 'Details',
              numberOfColumns: '3',
              data: formData,
            },
          ],
        },
        ..._specialData,
        {
          type: 'media-carousel',
          key: 'media-carousel',
          data: fileData,
        },
      ],
      paginationProps: {
        totalCount: 2,
        totalPages: 1,
        forcePage: 0,
        totalItems: 2,
      },
    };
    console.log('lo->', widgetData);
    return widgetData;
  }
  return {};
};

import { paymentSummaryConfig } from './paymentSummary.config';

export const acknowledgementConfig = {
  title: 'Acknowledgement',
  subTitle: 'Application is Complete!',
  //paraTitle: 'Your application has been submitted!',
  appNoTitle: 'Application No: ',
  para1: 'Check your',
  link: '/application-summary',
  linkText: 'APPLICATION STATUS',
  para2: ' here.',
  submitBtnText: 'Proceed to pay',
  paymentLink: '/payment',
  confirmModalContent: {
    title: 'Confirm Your Choice',
    desc: 'Are you sure you want to proceed with the payment or would you like to add the application to your cart and continue?',
    buttonVariant: 'secondary',
    buttonLabel: 'Proceed with Payment',
  },
  paymentSummary: paymentSummaryConfig,
};

export const settingsConfig = {
  searchStatusFilter: [
    {
      label: 'Select status',
      value: '',
    },
    {
      value: 'INVITED',
      label: 'Invited',
    },
    {
      value: 'ACTIVE',
      label: 'Active',
    },
    {
      value: 'TEMPORARILY_UNAVAILABLE',
      label: 'Inactive',
    },
    {
      value: 'SUSPENDED',
      label: 'Suspended',
    },
    {
      value: 'DEACTIVATED',
      label: 'Deactivated',
    },
  ],
};

export const userListConfig = {
  url: 'https://kali-dev-dc.vlinder.io/digicert/user/find',
  bulkOptions: [
    {
      value: 'pay',
      label: 'Pay',
    },
  ],
  ultTableHeader: [
    'Application ID',
    'Action',
    'Application Type',
    'Name',
    'Nationality',
    'Applied On',
    'Status',
  ],
  ultUsers: [],
  currentPage: 1,
  pageSize: 10,
  totalCount: 2,
  totalPages: 1,
  activePage: 1,
  role: 'admin',
};

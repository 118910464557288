export const paymentSummaryConfig = {
  title: 'Payment',
  desc: 'Complete the payment',
  summaryTitle: 'Payment Summary',
  prices: [
    {
      label: 'New Work Permit',
      price: '',
    },
  ],
  subTotalLabel: 'SubTotal',
  taxLabel: 'Tax',
  taxPrice: '0',
  totalLabel: 'Total',
  totalDesc: '(Including Tax)',
  buttonLabel: 'Proceed to Payment',
  buttonVariant: 'secondary',
};

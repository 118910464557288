import { vappContainer } from '@/application';
import { ForgotPasswordPageService } from '../../../services';
import { StorageModule } from '@vlinder-web/storage-module-react';

const MODULE_NAME = 'profile.machines.states.forgotPassword';

const getSrv = () => {
  return vappContainer.get<ForgotPasswordPageService>(
    ForgotPasswordPageService.displayName
  );
};

export const forgotPassword = async (context: any, event: any) => {
  const currentState = 'FORGOT_PASSWORD';
  const method = 'forgotPassword';
  const storageSrv = StorageModule.getInstance();

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const content = await srv.agent.forgotPassword(event?.payload);
      if (content) {
        return resolve({
          key: 'FORGOT_PASSWORD_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = content?.error;
        // log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'FORGOT_PASSWORD_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      let message = err?.message ?? 'Something went wrong please try again!';
      return reject({
        key: 'FORGOT_PASSWORD_FAILED',
        value: message,
      });
    }
  });
};

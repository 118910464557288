export const missingInfoFormData = {
  FIELDS: [
    {
      type: 'textarea',
      compProps: {
        id: 'comments',
        label: 'Comments',
        required: true,
        placeholder: 'Enter your comments',
      },
    },
    {
      type: 'select',
      compProps: {
        label: 'Missing Information Type',
        id: 'evidencetype',
        required: true,
        options: [
          {
            label: 'Birth Certificate Proof',
            id: 'BIRTH_CERTIFICATE_PROOF',
          },
          {
            label: 'Nationality Proof',
            id: 'NAIONALITY_PROOF',
          },
          {
            label: 'Occupation Proof',
            id: 'OCCUPATION_PROOF',
          },
          {
            label: 'Passport Proof',
            id: 'PASSPORT_PROOF',
          },
          {
            label: 'Qualification Proof',
            id: 'QUALIFICATION_PROOF',
          },
          {
            label: 'Work Experience Proof',
            id: 'WORK_EXPERIENCE_PROOF',
          },
          {
            label: 'Photograph',
            id: 'PHOTOGRAPH',
          },
          {
            label: 'Police Clearance Certificate',
            id: 'POLICE_CLEARANCE_CERTIFICATE',
          },
          {
            label: 'Resume Proof',
            id: 'RESUME_PROOF',
          },
          {
            label: 'Covering Letter Proof',
            id: 'COVERING_LETTER_PROOF',
          },
          {
            label: 'Previous Employer Proof',
            id: 'PREVIOUS_EMPLOYER_PROOF',
          },
          {
            label: 'Previous Employer Proof Dep',
            id: 'PREVIOUS_EMPLOYER_PROOF_DEP',
          },
          {
            label: 'Job Title Proof',
            id: 'JOB_TITLE_PROOF',
          },
          {
            label: 'Tax Compilance',
            id: 'TAX_COMPILANCE',
          },
          {
            label: 'By Advertisement File',
            id: 'BY_ADVERTISEMENT_FILE',
          },
          {
            label: 'Step Taken Proof',
            id: 'STEP_TAKEN_PROOF',
          },
          {
            label: 'Applicant Signature',
            id: 'APPLICANT_SIGNATURE',
          },
          {
            label: 'Employer Signature',
            id: 'EMPLOYER_SIGNATURE',
          },
          {
            label: 'Candidate Resume',
            id: 'CANDIDATE_RESUME',
          },
          {
            label: 'Academic Certificate Proof',
            id: 'ACADEMIC_CERTIFICATE_PROOF',
          },
          {
            label: 'Marriage Certificate Proof',
            id: 'MARRIAGE_CERTIFICATE_PROOF',
          },
          {
            label: 'Divorce Certificate Proof',
            id: 'DIVORCE_CERTIFICATE_PROOF',
          },
          {
            label: 'Adoption Certificate Proof',
            id: 'ADOPTION_CERTIFICATE_PROOF',
          },
          {
            label: 'Name Change Proof',
            id: 'NAME_CHANGE_PROOF',
          },
          {
            label: 'Certified Copy Of Birth Certificate',
            id: 'CERTIFIED_COPY_OF_BIRTH_CERTIFICATE',
          },
          {
            label: 'Biodata Page Copy',
            id: 'BIODATA_PAGE_COPY',
          },
          {
            label: 'Deed Poll Copy',
            id: 'DEED_POLL_COPY',
          },
        ],
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        id: 'evidences',
        label: 'Missing Information Copy',
        required: true,
        supportedFormat: [
          'image/png',
          'application/pdf',
          'application/doc',
          'application/msword',
          'image/jpeg',
          'image/jpg',
        ],
        maxFiles: 5,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Missing Information Copy',
        id: 'photo',

        maxFiles: 1,
        supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
        required: true,
      },
    },
  ],

  layout: [['comments'], ['evidencetype']],
  dynamicLayout: {
    evidencetype: {
      BIRTH_CERTIFICATE_PROOF: [['evidences']],
      NAIONALITY_PROOF: [['evidences']],
      OCCUPATION_PROOF: [['evidences']],
      PASSPORT_PROOF: [['evidences']],
      QUALIFICATION_PROOF: [['evidences']],
      WORK_EXPERIENCE_PROOF: [['evidences']],
      PHOTOGRAPH: [['photo']],
      POLICE_CLEARANCE_CERTIFICATE: [['evidences']],
      RESUME_PROOF: [['evidences']],
      COVERING_LETTER_PROOF: [['evidences']],
      PREVIOUS_EMPLOYER_PROOF: [['evidences']],
      PREVIOUS_EMPLOYER_PROOF_DEP: [['evidences']],
      JOB_TITLE_PROOF: [['evidences']],
      TAX_COMPILANCE: [['evidences']],
      BY_ADVERTISEMENT_FILE: [['evidences']],
      STEP_TAKEN_PROOF: [['evidences']],
      APPLICANT_SIGNATURE: [['evidences']],
      EMPLOYER_SIGNATURE: [['evidences']],
      CANDIDATE_RESUME: [['evidences']],
      ACADEMIC_CERTIFICATE_PROOF: [['evidences']],
      MARRIAGE_CERTIFICATE_PROOF: [['evidences']],
      DIVORCE_CERTIFICATE_PROOF: [['evidences']],
      ADOPTION_CERTIFICATE_PROOF: [['evidences']],
      NAME_CHANGE_PROOF: [['evidences']],
      CERTIFIED_COPY_OF_BIRTH_CERTIFICATE: [['evidences']],
      BIODATA_PAGE_COPY: [['evidences']],
      DEED_POLL_COPY: [['evidences']],
    },
  },
};

import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
  Image,
} from 'react-bootstrap';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  UserRemoveIcon,
  XCircleIcon,
  BellIcon,
} from '@heroicons/react/solid';
import { StorageModule } from '@vlinder-web/storage-module-react';
import { toast } from 'react-hot-toast';
import { USER_LEVEL } from '@/config/userManagement.config';
import * as S from './styles';
export interface ToggleActionProps {
  status: string;
  onTogglePress?(key: string): void;
}

export const ToggleAction = (props: ToggleActionProps) => {
  const { status, onTogglePress } = props;
  const storageSrv = StorageModule?.getInstance();
  const userProfile = storageSrv.get('myProfile', 'session');
  const userRole = userProfile?.role?.find((item: any) =>
    USER_LEVEL.owner.includes(item?.name)
  );

  const onToggleClick = (key: string) => {
    onTogglePress && onTogglePress(key);
  };

  return (
    <S.ButtonComp
      className={`btn rectangle-pill btn-sm`}
      color={'#0000FF'}
      onClick={() => onToggleClick('view')}
    >
      View
    </S.ButtonComp>
  );
};

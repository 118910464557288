//@ts-nocheck
import { rightEstablishmentConfig } from '../../../config/rightEstablishment.config';

export const getWidgetData = (fields: any[], _initialData: any) => {
  const formData: any[] = [];
  const expatirateInfo: any[] = [];
  const jamaicanInfo: any[] = [];
  const marriageInfo: any[] = [];
  const fileData: any[] = [];
  const _specialData: any[] = [];
  const _specialNavItems: any[] = [];
  let initialData = _initialData;

  let hiddenItems = {};
  const special: any = {
    refusedvisadetail: 'Refused Visa Details',
  };

  const renderIf = (data: any, ele: any) => {
    console.log(data, 'lo->');
    if (Array.isArray(data) && data.length > 0) {
      return [ele];
    }
    return [];
  };

  for (let step = 0; step < 3; step++) {
    console.log(step, fields, 'lp->', initialData);
    fields?.[step]?.forEach((_item: any) => {
      let ans = initialData?.[_item?.compProps?.id];
      if (
        special[_item?.compProps?.id] &&
        Array.isArray(ans) &&
        ans.length > 0
      ) {
        console.log('suspect->', ans);
        _specialNavItems.push({
          key: _item?.compProps?.id,
          title: special[_item?.compProps?.id],
          link: `/${_item?.compProps?.id}`,
          type: 'navbar',
        });
        const reqEle = _item?.compProps?.attributeForm?.fields;
        const totalData: any[] = [];
        console.log('liu->', ans);
        ans?.forEach((val) => {
          const currData: any[] = [];
          const currDocData: any[] = [];

          Object.entries(val)?.forEach((_item) => {
            const ans1 = reqEle?.filter(
              (element) => _item[0] === element?.compProps?.id
            )?.[0];
            console.log('suspect->->', ans1);
            if (_item[0] !== 'id' && ans1?.compProps?.label && _item[1]) {
              console.log('liop->', _item[1]);
              if (typeof _item[1] === 'string') {
                currData.push({
                  title: ans1?.compProps?.label,
                  subTitle: _item[1],
                  icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
                });
              } else if (
                (_item?.[1]?.[0]?.type === 'image/png' ||
                  _item?.[1]?.[0]?.type === 'image/jpg' ||
                  _item?.[1]?.[0]?.type === 'image/jpeg') &&
                !_item?.[1]?.[0]?.operation
              ) {
                currDocData.push({
                  type: 'img',
                  src: _item?.[1]?.[0]?.src,
                  meta: [_item?.[1]?.[0]?.name, _item?.[1]?.[0]?.size],
                  title: [ans1?.compProps?.label],
                });
              } else if (
                _item?.[1]?.[0]?.type === 'application/pdf' &&
                !_item?.[1]?.[0]?.operation
              ) {
                console.log('pdfcoming...');
                currDocData.push({
                  type: 'pdf',
                  src: _item?.[1]?.[0]?.src,
                  meta: [_item?.[1]?.[0]?.name, _item?.[1]?.[0]?.size],
                  title: [ans1?.compProps?.label],
                });
              }
            }
          });
          console.log('liop->', currData, currDocData);
          if (currDocData?.length === 0) {
            totalData.push(currData);
          } else {
            totalData.push({ doc: currDocData, data: currData });
          }
        });

        _specialData.push({
          type: 'accordion',
          key: _item?.compProps?.id,
          data: totalData?.map((data, key: number) => {
            if (data?.doc) {
              return [
                {
                  type: 'multi-col-list',
                  title: `${special[_item?.compProps?.id]} ${key + 1}`,
                  numberOfColumns: '3',
                  data: data?.data,
                },
                {
                  type: 'media-carousel',
                  key: `org-documents ${key + 1}`,
                  data: data?.doc,
                },
              ];
            }
            return {
              type: 'multi-col-list',
              title: `${special[_item?.compProps?.id]} ${key + 1}`,
              numberOfColumns: '3',
              data: data,
            };
          }),
        });
        console.log('firstans', ans);
      }
      if (step === 0) console.log('jramfinallog', _item, ans);
      console.log('asns', ans);
      const dynamicLayoutItem =
        rightEstablishmentConfig?.dynamicLayout?.[_item?.compProps?.id];
      console.log('dynamicLayoutItem', dynamicLayoutItem);
      if (dynamicLayoutItem) {
        Object.entries(dynamicLayoutItem)
          ?.filter((item) => item?.[0] !== ans)
          ?.map((subItem) => {
            subItem[1] &&
              subItem[1]?.map((_subItem) => {
                let cpy = initialData[_subItem[0]] || [];
                console.log('doubtforjram->', cpy);
                if (!(cpy && Array.isArray(cpy) && cpy?.length > 0)) {
                  hiddenItems[_subItem] = true;
                  delete initialData[_subItem];
                }
              });
          });
      }
      if (Array.isArray(ans)) {
        if (ans?.length > 0 && !hiddenItems[_item?.compProps?.id]) {
          if (
            (ans[0]?.type === 'image/png' ||
              ans[0]?.type === 'image/jpg' ||
              ans[0]?.type === 'image/jpeg') &&
            !ans[0]?.operation
          ) {
            console.log('hi0->', _item?.compProps?.id);
            fileData.push({
              type: 'img',
              src: ans[0]?.base64 ?? ans[0]?.src,
              meta: [ans[0]?.name, ans[0]?.size],
              title: [_item?.compProps?.label],
            });
          } else if (ans[0]?.type === 'application/pdf' && !ans[0]?.operation) {
            fileData.push({
              type: 'pdf',
              src: ans[0]?.base64 ?? ans[0]?.src,
              meta: [ans[0]?.name, ans[0]?.size],
              title: [_item?.compProps?.label],
            });
          }
        }
      } else {
        if (step === 0) {
          console.log('jramexpinfo-->', _item);
          if (
            _item?.compProps?.label &&
            ans &&
            _item?.compProps?.id &&
            !_item?.compProps?.id?.startsWith('is')
          ) {
            expatirateInfo.push({
              title: _item?.compProps?.label,
              subTitle: ans + '',
              icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
            });
          }
        }
        if (step === 1) {
          if (
            _item?.compProps?.label &&
            ans &&
            _item?.compProps?.id &&
            !_item?.compProps?.id?.startsWith('is')
          ) {
            jamaicanInfo.push({
              title: _item?.compProps?.label,
              subTitle: ans + '',
              icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
            });
          }
        }
        if (step === 2) {
          if (
            _item?.compProps?.label &&
            ans &&
            _item?.compProps?.id &&
            !_item?.compProps?.id?.startsWith('is')
          ) {
            marriageInfo.push({
              title: _item?.compProps?.label,
              subTitle: ans + '',
              icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
            });
          }
        }
      }
    });
  }
  console.log(initialData, 'chao->');
  const userData = initialData?.firstName
    ? {
        profileImage: initialData?.photograph?.[0]?.base64,
        name: `${initialData?.lastName ?? ''} ${initialData?.firstName ?? ''}`,
        listItems: [
          {
            key: 'name',
            title: 'Name',
            subTitle: initialData?.lastName ?? initialData?.firstName,
            icon: 'https://w7.pngwing.com/pngs/930/532/png-transparent-computer-icons-personal-web-page-user-name-icon-monochrome-user-tag.png',
          },
          // {
          //   key: 'trn',
          //   title: 'TRN',
          //   subTitle: initialData?.businessTrn,
          //   icon: 'https://w7.pngwing.com/pngs/930/532/png-transparent-computer-icons-personal-web-page-user-name-icon-monochrome-user-tag.png',
          // },
        ],
      }
    : {};
  const widgetData = {
    profileData: {
      ...userData,
      activeKey: 'employee-info',

      submitBtnText: 'Submit',
      checkBoxText:
        'I certify to the best of my knowledge and belief, that the above information is correct.',
      navItems: [
        {
          key: 'employee-info',
          title: 'Employee Information',
          link: '/employee-info',
          type: 'navbar',
        },
        {
          key: 'employment-info',
          title: 'Employment Information',
          link: '/employment-info',
          type: 'navbar',
        },
        ..._specialNavItems,
        {
          key: 'media-carousel',
          title: 'Documents',
          link: '/documents',
          type: 'navbar',
        },
      ],
    },
    itemSpecData: [
      {
        type: 'multi-col-list',
        key: 'employee-info',
        numberOfColumns: '3',
        data: expatirateInfo,
      },
      {
        type: 'multi-col-list',
        key: 'employment-info',
        numberOfColumns: '3',
        data: jamaicanInfo,
      },

      ..._specialData,
      {
        type: 'media-carousel',
        key: 'media-carousel',
        data: fileData,
      },
    ],
    paginationProps: {
      totalCount: 2,
      totalPages: 1,
      forcePage: 0,
      totalItems: 2,
    },
  };
  console.log('lo->', widgetData);

  return widgetData;
};

export const roeFormData = {
  label: 'Enter Company Details',
  previewLabel: 'Confirm Company Details',
  progressBarSteps: ['Enter Company Details', 'Confirm Company Details'],
  successIcon: 'https://i.ibb.co/RNvQN2X/success.png',
  errorIcon: 'https://i.ibb.co/GM7nsgp/icons8-error-48.png',
  selectFormLayout: [['companyType']],
  selectFormFields: [
    //   {
    //     type: 'select',
    //     compProps: {
    //       label: 'Select Company Incorporation Status',
    //       placeholder: 'Choose Incorporation Status',
    //       id: 'companyType',
    //       required: true,
    //       options: [
    //         {
    //           label: 'New',
    //           id: 'new',
    //         },
    //         {
    //           label: 'Existing',
    //           id: 'existing',
    //         },
    //       ],
    //     },
    //   },
  ],
  selectvalidatorConfig: {
    //   ruleSets: [
    //     {
    //       fields: {
    //         companyType: [
    //           {
    //             type: 'REQUIRED',
    //             errorMessage: 'Company Incorporation Status is required',
    //           },
    //         ],
    //       },
    //     },
    //   ],
  },
  fieldss: [
    {
      type: 'text',
      compProps: {
        label: 'Business Name',
        id: 'name',
        placeholder: 'Enter Business Name',
        required: true,
        disabled: true,
        readOnly: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Trading Name',
        id: 'tradingName',
        placeholder: 'Enter Trading Name',
        // required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Company Registration No',
        id: 'registrationNo',
        placeholder: 'Enter Company Registration No',
        required: true,
      },
    },
    {
      type: 'datepicker',
      compProps: {
        label: 'Incorporation Date',
        id: 'incorporationDate',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Taxpayer Registration Nos',
        id: 'trn',
        placeholder: 'Enter Taxpayer Registration No',
        required: true,
        disabled: true,
        readOnly: true,
      },
    },
    {
      type: 'datepicker',
      compProps: {
        label: 'Expiry Date for TCC',
        id: 'tccExpiryDate',
        required: false,
      },
    },
    {
      type: 'select',
      compProps: {
        label: 'Company Type',
        id: 'type',
        required: true,
        options: [
          {
            label: 'Public/Government',
            id: 'GOVERNMENT',
          },

          {
            label: 'Private Organisation',
            id: 'PRIVATE_COMPANY',
          },
          {
            label: 'Diplomatic Mission',
            id: 'DIPLOMAT',
          },
          {
            label: 'Non-government organization',
            id: 'NGO',
          },
          {
            label: 'Charity/Voluntary Organisation',
            id: 'NON_GOVERNMENTAL',
          },
          {
            label: 'Religious Organisation',
            id: 'PRIVATE_INDIVIDUAL',
          },
        ],
      },
    },

    {
      type: 'multiSelect',
      compProps: {
        label: 'Nature of Business',
        id: 'natureOfBusiness',
        lookUpField: 'industryTypeList',
        placeholder: 'Enter Nature of Business',
        errorText1: 'Please select appropriate Nature of Business',
        required: true,
      },
    },
    {
      type: 'textarea',

      compProps: {
        label: 'Registered Address (Street 1)',
        rows: 1,
        id: 'addressLine1',
        placeholder: 'Enter Registered Address (Street 1)',
        required: true,
      },
    },
    {
      type: 'textarea',

      compProps: {
        label: 'Registered Address (Street 2)',
        rows: 1,
        id: 'addressLine2',
        placeholder: 'Enter Registered Address (Street 2)',
      },
    },
    {
      type: 'select',
      compProps: {
        label: 'Parish',
        id: 'parish',
        placeholder: 'Enter Parish',
        required: true,
        options: [
          {
            label: 'Clarendon',
            id: 'Clarendon',
          },
          {
            label: 'Hanover',
            id: 'Hanover',
          },
          {
            label: 'Kingston',
            id: 'Kingston',
          },
          {
            label: 'Manchester',
            id: 'Manchester',
          },
          {
            label: 'Portland',
            id: 'Portland',
          },
          {
            label: 'Saint Andrew',
            id: 'Saint Andrew',
          },
          {
            label: 'Saint Ann',
            id: 'Saint Ann',
          },
          {
            label: 'Saint Catherine',
            id: 'Saint Catherine',
          },
          {
            label: 'Saint Elizabeth',
            id: 'Saint Elizabeth',
          },
          {
            label: 'Saint James',
            id: 'Saint James',
          },
          {
            label: 'Saint Mary',
            id: 'Saint Mary',
          },
          {
            label: 'Saint Thomas',
            id: 'Saint Thomas',
          },
          {
            label: 'Trelawny',
            id: 'Trelawny',
          },
          {
            label: 'Westmoreland',
            id: 'Westmoreland',
          },
        ],
      },
    },
    {
      type: 'select',
      compProps: {
        label: 'City',
        id: 'city',
        placeholder: 'Enter City',
        required: true,
        options: [],
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Postal Code',
        id: 'zipcode',
        placeholder: 'Enter Postal Code',
        // required: true,
      },
    },
    {
      type: 'textarea',

      compProps: {
        label: 'Operating Address (Street 1)',
        rows: 1,
        id: 'operatingaddressLine1',
        placeholder: 'Enter Operating Address (Street 1)',
        required: true,
      },
    },
    {
      type: 'textarea',

      compProps: {
        label: 'Operating Address (Street 2)',
        rows: 1,
        id: 'operatingaddressLine2',
        placeholder: 'Enter Operating Address (Street 2)',
        required: false,
      },
    },
    {
      type: 'select',
      compProps: {
        label: 'Parish',
        id: 'operatingParish',
        placeholder: 'Enter Parish',
        required: true,
        options: [
          {
            label: 'Clarendon',
            id: 'Clarendon',
          },
          {
            label: 'Hanover',
            id: 'Hanover',
          },
          {
            label: 'Kingston',
            id: 'Kingston',
          },
          {
            label: 'Manchester',
            id: 'Manchester',
          },
          {
            label: 'Portland',
            id: 'Portland',
          },
          {
            label: 'Saint Andrew',
            id: 'Saint Andrew',
          },
          {
            label: 'Saint Ann',
            id: 'Saint Ann',
          },
          {
            label: 'Saint Catherine',
            id: 'Saint Catherine',
          },
          {
            label: 'Saint Elizabeth',
            id: 'Saint Elizabeth',
          },
          {
            label: 'Saint James',
            id: 'Saint James',
          },
          {
            label: 'Saint Mary',
            id: 'Saint Mary',
          },
          {
            label: 'Saint Thomas',
            id: 'Saint Thomas',
          },
          {
            label: 'Trelawny',
            id: 'Trelawny',
          },
          {
            label: 'Westmoreland',
            id: 'Westmoreland',
          },
        ],
      },
    },
    {
      type: 'select',
      compProps: {
        label: 'City',
        id: 'operatingCity',
        placeholder: 'Enter City',
        required: true,
        options: [],
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Postal Code',
        id: 'operatingzipcode',
        placeholder: 'Enter Postal Code',
        required: true,
      },
    },
    {
      type: 'radio',
      compProps: {
        label: 'Company Status',
        id: 'companyStatus',
        direction: 'horizontal',
        required: true,
        options: [
          {
            label: 'Active',
            id: 'active',
          },
          {
            label: 'Closed',
            id: 'closed',
          },
        ],
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Website',
        id: 'website',
        placeholder: 'Enter Website',
        required: false,
      },
    },
    {
      type: 'email',
      compProps: {
        label: 'Company Email',
        id: 'companyEmail',
        placeholder: 'Enter Email Address',
        required: true,
      },
    },
    {
      type: 'phoneNumber',
      compProps: {
        label: 'Phone Number (Mobile)',
        id: 'primaryContactNo',
        placeholder: 'Enter Phone Number',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Phone Number (Landline)',
        id: 'landlineContactNo',
        placeholder: 'Enter Landline Number',
        required: false,
      },
    },
    // {
    //   type: 'radio',
    //   compProps: {
    //     label: 'Status',
    //     id: 'status',    required:true,
    //     direction: 'horizontal',
    //     options: [
    //       {
    //         label: 'Active',
    //         id: 'active',
    //       },
    //       {
    //         label: 'InActive',
    //         id: 'inActive',
    //       },
    //     ],
    //   },
    // },
    {
      type: 'sectionTitle',
      compProps: {
        id: 'companyDirectors',
        label: 'Company Directors',
      },
    },
    {
      type: 'sectionTitle',
      compProps: {
        id: 'subsidiaryTitle',
        label: 'Subsidiary',
      },
    },
    {
      type: 'sectionTitle',
      compProps: {
        id: 'branch',
        label: 'Branches',
      },
    },

    {
      type: 'sectionTitle',
      compProps: {
        id: 'requiredDocuments',
        label: 'Required Documents',
      },
    },

    {
      type: 'checkBox',
      compProps: {
        id: 'confirmDetails',
        label: '',
        required: true,
        direction: 'vertical',
        options: [
          {
            id: 'confirmDetailsId',
            label:
              'I confirm that the above entered details are accurate and correct.',
          },
        ],
      },
    },

    {
      type: 'radio',
      compProps: {
        direction: 'horizontal',
        required: true,
        label: 'Is Operating Address same as Registered Address?',
        id: 'isAddressSame',
        options: [
          {
            label: 'Yes',
            id: 'yes',
          },
          {
            label: 'No',
            id: 'no',
          },
        ],
      },
    },
    {
      type: 'radio',
      compProps: {
        direction: 'horizontal',
        label: 'Do you want to add director',
        id: 'isAddDirector',
        required: true,
        options: [
          {
            label: 'Yes',
            id: 'yes',
          },
          {
            label: 'No',
            id: 'no',
          },
        ],
      },
    },
    {
      type: 'dynamicFields',
      compProps: {
        id: 'directors',
        cardType: 'Director',
        label: 'Directors',
        cardTitleField: 'firstName',
        required: true,
        hiddenFields: ['isLastNameExist'],
        attributeForm: {
          modalTitle: 'Add a Director',
          editTitle: 'Edit a Director',

          fields: [
            {
              type: 'radio',
              compProps: {
                label: 'Do you have a last name or Surname?',
                id: 'isLastNameExist',
                direction: 'horizontal',
                required: true,
                options: [
                  {
                    label: 'Yes',
                    id: 'yes',
                  },
                  {
                    label: 'No',
                    id: 'no',
                  },
                ],
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Last Name',
                id: 'lastName',
                placeholder: 'Enter Last Name',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'First Name (Given Name)',
                id: 'firstName',
                placeholder: 'Enter First Name',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Middle Name',
                id: 'middleName',
                placeholder: 'Enter Middle Name',
                required: false,
              },
            },

            {
              type: 'countrySelect',
              compProps: {
                label: 'Nationality',
                id: 'nationality',
                placeholder: 'Enter Nationality',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Occupation',
                id: 'occupation',
                placeholder: 'Enter Occupation',
                required: true,
              },
            },
            {
              type: 'email',
              compProps: {
                label: 'Email Address',
                id: 'email',
                placeholder: 'Enter Email address',
                required: true,
              },
            },
            {
              type: 'phoneNumber',
              compProps: {
                label: 'Contact Number',
                id: 'primaryContactNo',
                placeholder: 'Enter Contact Number',
                required: true,
              },
            },
          ],
          layout: [
            ['isLastNameExist'],
            ['firstName'],
            ['middleName'],
            ['nationality'],
            ['occupation'],
            ['email'],
            ['primaryContactNo'],
          ],
          dynamicLayout: {
            isLastNameExist: {
              yes: [['lastName']],
            },
          },
          validatorConfig: {
            ruleSets: [
              {
                fields: {
                  firstName: [
                    {
                      type: 'matches',
                      pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
                      errorMessage:
                        'First Name should contain atleast 3 characters.',
                    },
                  ],
                  middleName: [
                    {
                      type: 'matches',
                      pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
                      errorMessage: 'Middle Name contain atleast 3 characters.',
                    },
                  ],
                  lastName: [
                    {
                      type: 'matches',
                      pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
                      errorMessage: 'Last Name contain atleast 3 characters.',
                    },
                  ],
                  nationality: [
                    {
                      type: 'matches',
                      pattern: '^[A-Za-z ]+$',
                      errorMessage: 'Nationality should contain alphabets.',
                    },
                  ],
                  occupation: [
                    {
                      type: 'matches',
                      pattern: '^[A-Za-z ]+$',
                      errorMessage: 'Occupation should contain alphabets.',
                    },
                  ],
                  email: [
                    {
                      type: 'isEmail',
                      errorMessage: 'Please enter a valid email address',
                    },
                  ],
                },
              },
            ],
          },
        },
      },
    },
    {
      type: 'radio',
      compProps: {
        direction: 'horizontal',
        label: 'Do you want to add subsidiaries ',
        id: 'isAddSubsidiary',
        required: true,
        options: [
          {
            label: 'Yes',
            id: 'yes',
          },
          {
            label: 'No',
            id: 'no',
          },
        ],
      },
    },
    {
      type: 'dynamicFields',
      compProps: {
        id: 'subsidiaries',
        label: 'Subsidiaries',
        cardType: 'Subsidiary',
        required: true,
        cardTitleField: 'name',

        attributeForm: {
          modalTitle: 'Add a Subsidiary',
          editTitle: 'Edit a Subsidiary',

          fields: [
            {
              type: 'text',
              compProps: {
                label: 'Subsidiary Name',
                id: 'name',
                placeholder: 'Enter Subsidiary Name',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Trading Name',
                id: 'tradingName',
                placeholder: 'Enter Trading Name',
                // required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Contact Person',
                id: 'contactPerson',
                placeholder: 'Enter Contact Person',
                required: true,
              },
            },
            {
              type: 'textarea',

              compProps: {
                label: 'Registered Address (Street 1)',
                rows: 1,
                id: 'addressLine1',
                placeholder: 'Enter Registered Address (Street 1)',
                required: true,
              },
            },
            {
              type: 'textarea',

              compProps: {
                label: 'Registered Address (Street 2)',
                rows: 1,
                id: 'addressLine2',
                placeholder: 'Enter Registered Address (Street 2)',
              },
            },
            {
              type: 'select',
              compProps: {
                label: 'Parish',
                id: 'parish',
                placeholder: 'Enter Parish',
                required: true,
                options: [
                  {
                    label: 'Clarendon',
                    id: 'Clarendon',
                  },
                  {
                    label: 'Hanover',
                    id: 'Hanover',
                  },
                  {
                    label: 'Kingston',
                    id: 'Kingston',
                  },
                  {
                    label: 'Manchester',
                    id: 'Manchester',
                  },
                  {
                    label: 'Portland',
                    id: 'Portland',
                  },
                  {
                    label: 'Saint Andrew',
                    id: 'Saint Andrew',
                  },
                  {
                    label: 'Saint Ann',
                    id: 'Saint Ann',
                  },
                  {
                    label: 'Saint Catherine',
                    id: 'Saint Catherine',
                  },
                  {
                    label: 'Saint Elizabeth',
                    id: 'Saint Elizabeth',
                  },
                  {
                    label: 'Saint James',
                    id: 'Saint James',
                  },
                  {
                    label: 'Saint Mary',
                    id: 'Saint Mary',
                  },
                  {
                    label: 'Saint Thomas',
                    id: 'Saint Thomas',
                  },
                  {
                    label: 'Trelawny',
                    id: 'Trelawny',
                  },
                  {
                    label: 'Westmoreland',
                    id: 'Westmoreland',
                  },
                ],
              },
            },
            {
              type: 'select',
              compProps: {
                label: 'City',
                id: 'city',
                placeholder: 'Enter City',
                required: true,
                options: [],
              },
            },
            {
              type: 'countrySelect',
              compProps: {
                label: 'Country',
                id: 'country',
                placeholder: 'Enter Country',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Postal Code',
                id: 'zipcode',
                placeholder: 'Enter Postal Code',
                // required: true,
              },
            },
            {
              type: 'phoneNumber',
              compProps: {
                label: 'Office Number',
                id: 'primaryContactNo',
                placeholder: 'Enter Office Number',
                required: true,
              },
            },

            {
              type: 'email',
              compProps: {
                label: 'Email Address',
                id: 'email',
                placeholder: 'Enter Email address',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Website',
                id: 'website',
                placeholder: 'Enter Website',
                // required: true,
              },
            },
          ],
          layout: [
            ['name'],
            ['tradingName'],

            ['addressLine1'],
            ['addressLine2'],
            ['parish', 'city'],
            ['country'],
            ['zipcode'],
            ['contactPerson'],
            ['primaryContactNo'],
            ['email'],
            ['website'],
          ],
          validatorConfig: {
            ruleSets: [
              {
                fields: {
                  // name: [
                  //   {
                  //     type: 'matches',
                  //     pattern: '^[A-Za-z ]+$',
                  //     errorMessage: 'Name is invalid.',
                  //   },
                  // ],
                  tradingName: [
                    {
                      type: 'matches',
                      pattern: '^[A-Za-z ]+$',
                      errorMessage: 'Name is invalid.',
                    },
                  ],
                  // city: [
                  //   {
                  //     type: 'matches',
                  //     pattern: '^[A-Za-z ]+$',
                  //     errorMessage: 'City should contain alphabets.',
                  //   },
                  // ],
                  // state: [
                  //   {
                  //     type: 'matches',
                  //     pattern: '^[A-Za-z ]+$',
                  //     errorMessage: 'Parish should contain alphabets.',
                  //   },
                  // ],
                  // country: [
                  //   {
                  //     type: 'matches',
                  //     pattern: '^[A-Za-z ]+$',
                  //     errorMessage: 'Country should contain alphabets.',
                  //   },
                  // ],
                  zipcode: [
                    {
                      type: 'matches',
                      pattern: '^[0-9]+$',
                      errorMessage:
                        'Postal Code should contain numerical values.',
                    },
                  ],
                  email: [
                    {
                      type: 'isEmail',
                      errorMessage: 'Please enter a valid email address',
                    },
                  ],
                  // website: [
                  //   {
                  //     type: 'matches',
                  //     pattern:
                  //       '^(https?://)?([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]+.[a-zA-Z]{2,}([/?#].*)?$',
                  //     errorMessage: 'Invalid Website',
                  //   },
                  // ],
                },
              },
            ],
          },
        },
      },
    },
    {
      type: 'radio',
      compProps: {
        direction: 'horizontal',
        required: true,
        label: 'Do you want to add branch ',
        id: 'isAddBranch',
        options: [
          {
            label: 'Yes',
            id: 'yes',
          },
          {
            label: 'No',
            id: 'no',
          },
        ],
      },
    },
    {
      type: 'dynamicFields',
      compProps: {
        id: 'branches',
        label: 'Branches',
        required: true,
        cardType: 'Branch',
        cardTitleField: 'name',

        attributeForm: {
          modalTitle: 'Add a Branch',
          editTitle: 'Edit a Branch',

          fields: [
            {
              type: 'text',
              compProps: {
                label: 'Branch Name',
                id: 'name',
                placeholder: 'Enter Branch Name',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Contact Person',
                id: 'contactPerson',
                placeholder: 'Enter Contact Person',
                required: true,
              },
            },
            {
              type: 'textarea',

              compProps: {
                label: 'Registered Address (Street 1)',
                rows: 1,
                id: 'addressLine1',
                placeholder: 'Enter Registered Address (Street 1)',
                required: true,
              },
            },
            {
              type: 'textarea',

              compProps: {
                label: 'Registered Address (Street 2)',
                rows: 1,
                id: 'addressLine2',
                placeholder: 'Enter Registered Address (Street 2)',
              },
            },
            {
              type: 'select',
              compProps: {
                label: 'Parish',
                id: 'parish',
                placeholder: 'Enter Parish',
                required: true,
                options: [
                  {
                    label: 'Clarendon',
                    id: 'Clarendon',
                  },
                  {
                    label: 'Hanover',
                    id: 'Hanover',
                  },
                  {
                    label: 'Kingston',
                    id: 'Kingston',
                  },
                  {
                    label: 'Manchester',
                    id: 'Manchester',
                  },
                  {
                    label: 'Portland',
                    id: 'Portland',
                  },
                  {
                    label: 'Saint Andrew',
                    id: 'Saint Andrew',
                  },
                  {
                    label: 'Saint Ann',
                    id: 'Saint Ann',
                  },
                  {
                    label: 'Saint Catherine',
                    id: 'Saint Catherine',
                  },
                  {
                    label: 'Saint Elizabeth',
                    id: 'Saint Elizabeth',
                  },
                  {
                    label: 'Saint James',
                    id: 'Saint James',
                  },
                  {
                    label: 'Saint Mary',
                    id: 'Saint Mary',
                  },
                  {
                    label: 'Saint Thomas',
                    id: 'Saint Thomas',
                  },
                  {
                    label: 'Trelawny',
                    id: 'Trelawny',
                  },
                  {
                    label: 'Westmoreland',
                    id: 'Westmoreland',
                  },
                ],
              },
            },
            {
              type: 'select',
              compProps: {
                label: 'City',
                id: 'city',
                placeholder: 'Enter City',
                required: true,
                options: [],
              },
            },
            {
              type: 'countrySelect',
              compProps: {
                label: 'Country',
                id: 'country',
                placeholder: 'Enter Country',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Postal Code',
                id: 'zipcode',
                placeholder: 'Enter Postal Code',
                // required: true,
              },
            },
            {
              type: 'phoneNumber',
              compProps: {
                label: 'Telephone Number',
                id: 'primaryContactNo',
                placeholder: 'Enter Telephone Number',
                required: true,
              },
            },
            {
              type: 'email',
              compProps: {
                label: 'Email Address',
                id: 'email',
                placeholder: 'Enter Email address',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Website',
                id: 'website',
                placeholder: 'Enter Website',
                // required: true,
              },
            },
          ],
          layout: [
            ['name'],
            ['addressLine1'],
            ['addressLine2'],
            ['parish', 'city'],
            ['country'],
            ['zipcode'],
            ['primaryContactNo'],
            ['contactPerson'],
            ['email'],
            ['website'],
          ],
          validatorConfig: {
            ruleSets: [
              {
                fields: {
                  // name: [
                  //   {
                  //     type: 'matches',
                  //     pattern: '^[A-Za-z ]+$',
                  //     errorMessage: 'Name is invalid.',
                  //   },
                  // ],
                  // city: [
                  //   {
                  //     type: 'matches',
                  //     pattern: '^[A-Za-z ]+$',
                  //     errorMessage: 'City should contain alphabets.',
                  //   },
                  // ],
                  // parish: [
                  //   {
                  //     type: 'matches',
                  //     pattern: '^[A-Za-z ]+$',
                  //     errorMessage: 'Parish should contain alphabets.',
                  //   },
                  // ],
                  // country: [
                  //   {
                  //     type: 'matches',
                  //     pattern: '^[A-Za-z ]+$',
                  //     errorMessage: 'Country should contain alphabets.',
                  //   },
                  // ],
                  zipcode: [
                    {
                      type: 'matches',
                      pattern: '^[0-9]+$',
                      errorMessage:
                        'Postal Code should contain numerical values.',
                    },
                  ],
                  email: [
                    {
                      type: 'isEmail',
                      errorMessage: 'Please enter a valid email address',
                    },
                  ],
                  // website: [
                  //   {
                  //     type: 'matches',
                  //     pattern:
                  //       '^(https?://)?([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]+.[a-zA-Z]{2,}([/?#].*)?$',
                  //     errorMessage: 'Invalid Website',
                  //   },
                  // ],
                },
              },
            ],
          },
        },
      },
    },
  ],
  fields: [
    {
      type: 'text',
      compProps: {
        label: 'Last Name',
        id: 'lastName',
        placeholder: 'Enter Last Name',
        required: true,
      },
    },

    {
      type: 'text',
      compProps: {
        label: 'First Name (Given Name)',
        id: 'firstName',
        placeholder: 'Enter First Name',
        required: true,
      },
    },

    {
      type: 'text',
      compProps: {
        label: 'Middle Name',
        id: 'middleInitial',
        placeholder: 'Enter Middle Name',
        required: false,
      },
    },
    {
      type: 'textarea',

      compProps: {
        label: 'Address Line 1',
        rows: 1,
        id: 'address1',
        placeholder: 'Enter Address Line 1',
        required: true,
      },
    },
    {
      type: 'textarea',

      compProps: {
        label: 'Address Line 2',
        rows: 1,
        id: 'address2',
        placeholder: 'Enter Address Line 2',
        required: false,
      },
    },

    {
      type: 'text',
      compProps: {
        label: 'City',
        id: 'city',
        placeholder: 'Enter City',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Place of Birth',
        id: 'placeOfBirth',
        placeholder: 'Enter Place of Birth',
        required: true,
      },
    },

    {
      type: 'countrySelect',
      compProps: {
        label: 'Country of Birth',
        id: 'countryOfBirth',
        placeholder: 'Enter Country of Birth',
        required: true,
      },
    },
    {
      type: 'phoneNumber',
      compProps: {
        label: 'Telephone Number',
        id: 'telephoneNumber',
        placeholder: 'Enter Telephone Number',
        required: true,
      },
    },

    {
      type: 'email',
      compProps: {
        label: 'Email Address',
        id: 'email',
        placeholder: 'Enter Email address',
        required: true,
        readOnly: true,
        disabled: true,
      },
    },
    {
      type: 'radio',
      compProps: {
        label: 'Sex',
        id: 'sex',
        direction: 'horizontal',
        required: true,
        options: [
          {
            label: 'Male',
            id: 'male',
          },
          {
            label: 'Female',
            id: 'female',
          },
        ],
      },
    },

    {
      type: 'datepicker',
      compProps: {
        id: 'dateOfBirth',
        label: 'Date of Birth',
        required: true,
        isAdult: true,
      },
    },
    {
      type: 'countrySelect',
      compProps: {
        label: 'Nationality',
        id: 'nationality',
        placeholder: 'Enter Nationality',
        required: true,
      },
    },
    {
      type: 'radio',
      compProps: {
        label: 'Marital Status',
        id: 'maritalStatus',
        direction: 'horizontal',
        options: [
          {
            label: 'Single',
            id: 'single',
          },
          {
            label: 'Divorced',
            id: 'divorced',
          },
          {
            label: 'Widowed',
            id: 'widowed',
          },
          {
            label: 'Married',
            id: 'married',
          },
          {
            label: 'Separated',
            id: 'separated',
          },
        ],
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Qualification/Certificate',
        id: 'qualificationName',
        placeholder: 'Qualification/Certificate',
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        id: 'qualificationProof',
        label: 'Proof of Qualification/Certicate',
        maxFiles: 5,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Occupational Skill',
        id: 'occupationalSkill',
        placeholder: 'Enter Occupational Skill',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Passport Number',
        id: 'passportno',
        placeholder: 'Enter Passport Number',
        required: true,
      },
    },
    {
      type: 'datepicker',
      compProps: {
        label: 'Expiry Date',
        id: 'expiryDate',
        isFuture: true,
        required: true,
      },
    },
    {
      type: 'multiSelect',
      compProps: {
        label: 'Nature of Business to be undertaken & Service to be provided',
        id: 'natureOfBusiness',
        lookUpField: 'industryTypeList',
        placeholder: 'Enter Nature of Business',
        errorText1: 'Please select appropriate Nature of Business',
        required: true,
      },
    },
    {
      type: 'textarea',

      compProps: {
        label: 'Intended Business address in Jamaica',
        rows: 1,
        id: 'operatingaddress1',
        placeholder: 'Enter Address Line 1',
        required: true,
      },
    },
    {
      type: 'radio',
      compProps: {
        id: 'isbusinessregistered',
        direction: 'horizontal',
        label: 'Has the business registered?',
        value: 'yes',
        options: [
          {
            id: 'yes',
            label: 'Yes',
          },
          {
            id: 'no',
            label: 'No',
          },
        ],
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Proof of Business Registration',
        id: 'businessRegistrationproof',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Police Report from Country',
        id: 'policeClearanceCertificate',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Photograph',
        id: 'photograph',
        supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
        maxFiles: 1,
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Birth Certificate Copy',
        id: 'birthCertificateProof',
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,

        maxFiles: 1,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Bio-Data Page Copy',
        id: 'biodata',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },

    {
      type: 'radio',
      compProps: {
        id: 'isNameChange',
        direction: 'horizontal',
        label: 'If there is a name change',
        value: 'yes',
        options: [
          {
            id: 'yes',
            label: 'Yes',
          },
          {
            id: 'no',
            label: 'No',
          },
        ],
        required: true,
      },
    },
    {
      type: 'select',
      compProps: {
        id: 'namechangedoc',
        direction: 'horizontal',
        label: 'Choose a document for name change',
        // value: 'yes',
        options: [
          {
            id: 'deedPollDoc',
            label: 'Deed poll',
          },
          {
            id: 'marriageCertificateDoc',
            label: 'Marriage Certificate ',
          },
          {
            id: 'divorceCertificateDoc',
            label: 'Divorse Certificate ',
          },
          {
            id: 'other',
            label: 'other ',
          },
        ],
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'A deed poll',
        id: 'deedPoll',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Marriage Certificate',
        id: 'marriageForNameChange',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },

    {
      type: 'fileDropZone',
      compProps: {
        label: 'Decree Absolute (Divorce)',
        id: 'divorseForNameChange',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Other document to support name change',
        id: 'nameChange',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Marriage Certificate',
        id: 'marriage',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Decree Absolute (Divorce)',
        id: 'divorce',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },

    {
      type: 'fileDropZone',
      compProps: {
        label: 'Evidence of Financial Resources',
        id: 'financialresourceevidence',

        maxFiles: 1,
        supportedFormat: [
          'image/png',
          'application/pdf',

          'image/jpeg',
          'image/jpg',
        ],
        required: true,
      },
    },
  ],

  newLayout2: [
    {
      first: {
        label: 'Employer Details',
        data: [
          ['lastName', 'firstName'],
          ['middleInitial'],
          ['address1'],
          ['address2'],
          ['city'],
          ['placeOfBirth', 'countryOfBirth'],
          ['telephoneNumber', 'email'],
          ['sex'],
          ['dateOfBirth', 'nationality'],
          ['maritalStatus'],
          // ['userDetails'],
          // ['firstName', 'middleName'],
          // ['lastName', 'email'],
          // ['telephoneNumber', 'position'],
          // ['status'],
        ],
      },
      second: {
        label: 'Employer Business Information',
        data: [
          ['qualificationName'],
          ['qualificationProof'],
          ['occupationalSkill'],
          ['passportno'],
          ['expiryDate'],
          ['natureOfBusiness'],
          ['operatingaddress1'],
          ['isbusinessregistered'],
        ],
      },
      third: {
        label: 'Documents',
        data: [
          ['policeClearanceCertificate'],
          ['photograph'],
          ['birthCertificateProof'],
          ['biodata'],
          ['isNameChange'],
          ['financialresourceevidence'],
        ],
      },
    },
  ],

  editLayout: [
    ['addressLine1'],
    ['addressLine2'],
    ['parish', 'city'],
    ['zipcode'],
    ['isAddDirector'],
    ['primaryContactNo'],
  ],

  confirmField: ['confirmDetails'],
  dynamicLayout: {
    isbusinessregistered: {
      yes: [['businessRegistrationproof']],
    },
    maritalStatus: {
      divorced: [['divorce']],
      married: [['marriage']],
    },

    isAddressSame: {
      no: [
        ['operatingaddressLine1'],
        ['operatingaddressLine2'],
        ['operatingParish', 'operatingCity'],
        ['operatingzipcode'],
      ],
    },
    isNameChange: {
      yes: [['namechangedoc']],
    },
    namechangedoc: {
      deedPollDoc: [['deedPoll']],
      marriageCertificateDoc: [['marriageForNameChange']],
      divorceCertificateDoc: [['divorseForNameChange']],
      other: [['nameChange']],
    },
    type: {
      // GOVERNMENT: [['immigrationStatus']],
      // PUBLIC: [['immigrationStatus']],
      PRIVATE_INDIVIDUAL: [
        // ['immigrationStatus'],
        ['businessRegistrationExpiryDate'],
        ['annualReturn'],
        ['businessRegistration'],
      ],
      NON_GOVERNMENTAL: [
        // ['immigrationStatus'],
        ['businessRegistrationExpiryDate'],
        ['annualReturn'],
        ['certificateOfGoodStading'],
        ['businessRegistration'],
      ],
      NGO: [
        // ['immigrationStatus'],
        ['businessRegistrationExpiryDate'],
        ['annualReturn'],
        ['businessRegistration'],
      ],
      // DIPLOMAT: [['immigrationStatus']],
      PRIVATE_COMPANY: [
        // ['immigrationStatus'],
        ['businessRegistrationExpiryDate'],
        ['annualReturn'],
        ['bankStatement'],
        ['existingInvoices'],
        ['leaseOrRentalAgreement'],
        ['businessRegistration'],
      ],
    },
    isAddDirector: {
      yes: [['directors']],
    },
    isAddBranch: {
      yes: [['branches']],
    },
    isAddSubsidiary: {
      yes: [['subsidiaries']],
    },
  },
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          // name: [
          //   {
          //     type: 'matches',
          //     pattern: '^[A-Za-z ]+$',
          //     errorMessage: 'Name is invalid.',
          //   },
          // ],
          firstName: [
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'First Name is invalid.',
            },
          ],
          passportno: [
            {
              type: 'matches',
              pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
              errorMessage: 'Passport is invalid',
            },
          ],
          middleName: [
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'Middle Name is invalid.',
            },
          ],
          lastName: [
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'Last Name is invalid.',
            },
          ],
          landlineContactNo: [
            {
              type: 'matches',
              pattern: '^[0-9]{6,8}$',
              errorMessage: 'Landline is invalid',
            },
          ],
          tradingName: [
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'Trading Name is invalid.',
            },
          ],
          incorporationDate: [
            {
              type: 'matches',
              pattern: '',
              errorMessage: 'Incorporation Date should not be future date.',
            },
          ],
          email: [
            {
              type: 'isEmail',
              errorMessage: 'Please enter a valid email address',
            },
          ],
          registrationNo: [
            {
              type: 'matches',
              pattern: '^[0-9]+$',
              errorMessage:
                'Company Registration No should contain numerical values.',
            },
          ],
          // trn: [
          //   {
          //     type: 'matches',
          //     pattern: '^[a-zA-Z0-9]+$',
          //     errorMessage:
          //       'Taxpayer Registration No should contain alphanumerical values.',
          //   },
          // ],
          zipcode: [
            {
              type: 'matches',
              pattern: '^[0-9]+$',
              errorMessage: 'Postal Code should contain numerical values.',
            },
          ],
          operatingzipcode: [
            {
              type: 'matches',
              pattern: '^[0-9]+$',
              errorMessage: 'Postal Code should contain numerical values.',
            },
          ],

          // city: [
          //   {
          //     type: 'matches',
          //     pattern: '^[A-Za-z ]+$',
          //     errorMessage: 'City should contain alphabets.',
          //   },
          // ],
          // parish: [
          //   {
          //     type: 'matches',
          //     pattern: '^[A-Za-z ]+$',
          //     errorMessage: 'Parish should contain alphabets.',
          //   },
          // ],
          // operatingCity: [
          //   {
          //     type: 'matches',
          //     pattern: '^[A-Za-z ]+$',
          //     errorMessage: 'City should contain alphabets.',
          //   },
          // ],
          // operatingParish: [
          //   {
          //     type: 'matches',
          //     pattern: '^[A-Za-z ]+$',
          //     errorMessage: 'Parish should contain alphabets.',
          //   },
          // ],
          // website: [
          //   {
          //     type: 'matches',
          //     pattern:
          //       '^(https?://)?([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]+.[a-zA-Z]{2,}([/?#].*)?$',
          //     errorMessage: 'Invalid Website',
          //   },
          // ],
        },
      },
    ],
  },
  availableDocuments: {
    policeClearanceCertificate: 'POLICE_CLEARANCE_CERTIFICATE',
    photograph: 'PHOTOGRAPH',
    birthCertificateProof: 'BIRTH_CERTIFICATE_PROOF',
    biodata: 'BIODATA_PAGE_COPY',
    deedPoll: 'DEED_POLL_COPY',
    marriageForNameChange: 'MARRIAGE_CERTIFICATE_FOR_NAME_CHANGE',
    divorseForNameChange: 'DIVORCE_CERTIFICATE_FOR_NAME_CHANGE',
    nameChange: 'NAME_CHANGE_PROOF',
    marriage: 'MARRIAGE_CERTIFICATE_PROOF',
    divorce: 'DIVORCE_CERTIFICATE_PROOF',
    financialresourceevidence: 'FINANCIAL_STATEMENT_PROOF',
    qualificationProof: 'QUALIFICATION_PROOF',
    businessRegistrationproof: 'PROOF_OF_BUSINESS_REGISTRATION',
  },
  // businessRegistration: 'BUSSINESS_REGISTRATION',
  //     taxClearanceCertificate: 'TAX_CLEARANCE_CERTIFICATE',
  //     articleOfIncorporation: 'ARTICAL_OF_INCORPORATION',
  //     certificateOfIncorporation: 'CERTIFICATE_OF_INCORPORATION',
  //     annualReturn: 'ANNUAL_RETURN',
  //     immigrationStatus: 'IMMIGRATION_STATUS',
  //     certificateOfGoodStading: 'CERTIFICATE_OF_GOOD_STANDING',
  //     bankStatement: 'BANK_STATMENT',
  //     existingInvoices: 'EXISTING_INVOICES',
  //     leaseOrRentalAgreement: 'LEASE_AGREEMENT',
  //     companyReceipt: 'COMPAY_OFFICE_OF_JAMAICA_RECEIPT',
  relatedDocuments: {
    GOVERNMENT: [
      // 'IMMIGRATION_STATUS'
    ],
    PUBLIC: [
      // 'IMMIGRATION_STATUS'
    ],
    PRIVATE_INDIVIDUAL: [
      // 'IMMIGRATION_STATUS',
      'ANNUAL_RETURN',
      'BUSSINESS_REGISTRATION',
    ],
    NON_GOVERNMENTAL: [
      // 'IMMIGRATION_STATUS',
      'ANNUAL_RETURN',
      'CERTIFICATE_OF_GOOD_STANDING',
      'BUSSINESS_REGISTRATION',
    ],
    NGO: [
      // 'IMMIGRATION_STATUS',
      'ANNUAL_RETURN',
      'BUSSINESS_REGISTRATION',
    ],
    DIPLOMAT: [
      // 'IMMIGRATION_STATUS'
    ],
    PRIVATE_COMPANY: [
      // 'IMMIGRATION_STATUS',
      'ANNUAL_RETURN',
      'BANK_STATMENT',
      'EXISTING_INVOICES',
      'LEASE_AGREEMENT',
      'BUSSINESS_REGISTRATION',
    ],
  },
};

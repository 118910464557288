import { vappContainer } from '@/application';
import { ResetPasswordPageService } from '../../../services';

const MODULE_NAME = 'Register.machines.states.getPage';

const getSrv = () => {
  return vappContainer.get<ResetPasswordPageService>(
    ResetPasswordPageService.displayName
  );
};
export const getPageContent = async (context: any, event: any) => {
  const currentState = 'GET_PAGE_CONTENT';
  const method = 'getPageContent';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      console.log(`${method} content #1`);
      const content = await srv.getPageContent();
      console.log(`${method} content #2`, content);
      if (content) {
        return resolve({
          key: 'RENDER_PAGE',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        return reject({
          key: 'RENDER_PAGE_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      return reject({
        key: 'RENDER_PAGE_ERROR',
        value: err?.message,
      });
    }
  });
};

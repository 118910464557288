import React, { useEffect } from 'react';
import { logger as log } from '@common/logger';

import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';

import { actions } from './actions';
import { MACHINE_CONFIG } from './config';
import { services } from './services';

export const notifyUserUserContext = React.createContext({});
export let notifyUserUserMachine: any;

const MODULE_NAME = 'machines.notifyUser';

export const useSMNotifyUserUser = (_context: any) => {
  let _machineConfig: any = MACHINE_CONFIG;
  if (_machineConfig && _machineConfig?.context) {
    _machineConfig.context = {
      ..._machineConfig.context,
      ...(_context || {}),
    };
  }
  notifyUserUserMachine = createMachine(_machineConfig, {
    services,
    actions,
  });
  const [state, send] = useMachine(notifyUserUserMachine);

  return {
    notifyUserUserMachine,
    notifyUserUserState: state,
    notifyUserUserSend: send,
  };
};

export const draftDataNotPresent = (data: any) => {
  console.log('notpresentdata', data);
  let mailingParish = data?.org?.operatingAddress?.parish
    ? {
        mailingParish: data?.org?.operatingAddress?.parish,
      }
    : {};
  return {
    ...mailingParish,
    acknowledgementCheck: 'no',
    businessAddress1: data?.org?.address?.addressLine1 ?? undefined,
    businessAddress2: data?.org?.address?.addressLine2 ?? undefined,
    businessCity: data?.org?.address?.city ?? undefined,
    businessCountry: data?.org?.address?.country ?? undefined,

    businessParish: data?.org?.address?.parish ?? undefined,

    businessPostalCode: data?.org?.address?.zipcode ?? undefined,
    dateOfReg: data?.org?.incorporationDate ?? undefined,
    email: data?.org?.email ?? undefined,
    faxNumber: data?.org?.fax ?? undefined,
    businessName: data?.org?.name ?? undefined,
    natureOfBusiness: data?.org?.natureOfBusiness?.includes(':')
      ? data?.org?.natureOfBusiness?.split(':')?.[1]
      : data?.org?.natureOfBusiness ?? undefined,

    mailingAddress1: data?.org?.operatingAddress?.addressLine1 ?? undefined,
    mailingAddress2: data?.org?.operatingAddress?.addressLine2 ?? undefined,
    mailingCity: data?.org?.operatingAddress?.city ?? undefined,
    mailingCountry: data?.org?.operatingAddress?.country ?? undefined,
    mailingPostalCode: data?.org?.operatingAddress?.zipcode ?? undefined,
    telephoneNumber: data?.primaryContactNo ?? undefined,
    // totalStaff: data?.org?.totalStaff
    //   ? parseInt(data?.org?.totalStaff)
    //   : 0,
    tccExpiryDate: data?.org?.tccExpiryDate ?? undefined,
    tccNumber: data?.org?.tccNumber ?? undefined,
    businessTrn: data?.org?.trn ?? undefined,
    website: data?.org?.website ?? undefined,
  };
};

export const draftDataIsPresent = (data: any) => {
  let mailingParish = data?.org?.operatingAddress?.parish
    ? {
        mailingParish: data?.org?.operatingAddress?.parish,
      }
    : {};
  return {
    ...mailingParish,
    acknowledgementCheck: 'no',

    businessAddress1: data?.org?.address?.addressLine1 ?? undefined,
    businessAddress2: data?.org?.address?.addressLine2 ?? undefined,
    businessCity: data?.org?.address?.city ?? undefined,
    businessCountry: data?.org?.address?.country ?? undefined,
    businessParish: data?.org?.address?.parish ?? undefined,
    businessPostalCode: data?.org?.address?.zipcode ?? undefined,
    dateOfReg: data?.org?.incorporationDate ?? undefined,
    email: data?.org?.email ?? undefined,
    faxNumber: data?.org?.fax ?? undefined,
    businessName: data?.org?.name ?? undefined,
    natureOfBusiness: data?.org?.natureOfBusiness?.includes(':')
      ? data?.org?.natureOfBusiness?.split(':')?.[1]
      : data?.org?.natureOfBusiness ?? undefined,
    mailingAddress1: data?.org?.operatingAddress?.addressLine1 ?? undefined,
    mailingAddress2: data?.org?.operatingAddress?.addressLine2 ?? undefined,
    mailingCity: data?.org?.operatingAddress?.city ?? undefined,
    mailingCountry: data?.org?.operatingAddress?.country ?? undefined,
    mailingPostalCode: data?.org?.operatingAddress?.zipcode ?? undefined,
    telephoneNumber: data?.primaryContactNo ?? undefined,
    // totalStaff: data?.org?.totalStaff
    //   ? parseInt(data?.org?.totalStaff)
    //   : 0,
    tccExpiryDate: data?.org?.tccExpiryDate ?? undefined,
    tccNumber: data?.org?.tccNumber ?? undefined,
    businessTrn: data?.org?.trn ?? undefined,
    website: data?.org?.website ?? undefined,
  };
};

export const notificationConfig = {
  userInviteSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['User has been invited successfully'],
    acknowledgementButtonText: 'Close',
  },
  deleteUserSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['User has been deleted successfully'],
    acknowledgementButtonText: 'Close',
  },
  appealSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['Application processed successfully'],
    acknowledgementButtonText: 'Close',
  },
  suspendUserSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['User has been suspended successfully'],
    acknowledgementButtonText: 'Close',
  },
  reInviteUser: {
    descriptionTitle: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: [
      'User has been re-invited successfully',
      'An invite email has been sent to user email address',
    ],
    acknowledgementButtonText: 'Close',
  },
  activateUserSuccess: {
    descriptionTitle: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['User has been activated back successfully'],
    acknowledgementButtonText: 'Close',
  },
};

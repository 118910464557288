import { vappContainer, VApplication } from '../../../../../application';
import { logger as log } from '@common/logger';
import { ApplicationFormPageService } from '@/pages/ApplicationForm/services';

const MODULE_NAME = 'profile.machines.states.getPage';

const getSrv = () => {
  return vappContainer.get<ApplicationFormPageService>(
    ApplicationFormPageService.displayName
  );
};
export const canCreateNewApp = async (context: any, event: any) => {
  const currentState = 'GET_PAGE_CONTENT';
  const method = 'canCreateNewApp';

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);

      const srv = getSrv();
      const content = await srv?.agent?.canCreateNewApp(event?.payload);

      if (content?.canCreate) {
        return resolve({
          key: 'CAN_CREATE_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'CAN_CREATE_ERROR',
          value: { ...(content || {}) },
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'CAN_CREATE_ERROR',
        value: err,
      });
    }
  });
};

import toast from 'react-hot-toast';
import { vappContainer, VApplication } from '../../../../../application';
import { ApplicationFormPageService } from '@/pages/ApplicationForm/services';
import { useNavigate } from 'react-router-dom';

const MODULE_NAME = 'applications.machines.states.getDraftedApp';

const getSrv = () => {
  return vappContainer.get<ApplicationFormPageService>(
    ApplicationFormPageService.displayName
  );
};

export const getDraftedApp = async (context: any, event: any) => {
  const currentState = 'GET_DRAFTED_APP';
  const method = 'getDraftedApp';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      console.log(`getDraftedApp context`, context['draftedApp']);
      const content = await srv.agent.formatAppDraft({});
      console.log(`page content privacy`, content);
      if (content) {
        return resolve({
          key: 'RENDER_DRAFTED_DOC',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Application status is not in draft!';

        return reject({
          key: 'RENDER_DRAFTED_APP_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      return reject({
        key: 'RENDER_DRAFTED_APP_ERROR',
        value: err?.message,
      });
    }
  });
};

export const notifications = {
  updateProfileSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['Profile has been updated Successfully'],
    acknowledgementButtonText: 'Close',
  },
  createProfileSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['Submitted for review succesfully.'],
    acknowledgementButtonText: 'Close',
  },
  suspendUserSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',

    description: ['User has been suspended successfully'],
    acknowledgementButtonText: 'Close',
  },
  revokeAdminSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',

    description: ['Admin role has been revoked successfully'],
    acknowledgementButtonText: 'Close',
  },
  removeAccessSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',

    description: ['User access has been removed successfully'],
    acknowledgementButtonText: 'Close',
  },
  reInviteUser: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',

    description: ['An invite email has been sent to user email address'],
    acknowledgementButtonText: 'Close',
  },
  activateUserSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',

    description: ['User has been activated back successfully'],
    acknowledgementButtonText: 'Close',
  },
};

export const settingsConfig = {
  searchStatusFilter: [
    {
      label: 'Select Status',
      value: '',
    },
    {
      value: "SUCCESS",
      label: "Success"
    },
    {
      value: "PENDING",
      label: "Pending"
    },
    {
      value: "FAILED",
      label: "Failed"
    }
  ],
  tableHeaders: [
    {
      key: 'Email',
      filterTypes: [
        {
          label: 'Contains',
          type: 'text',
          id: 'email',
        },
        {
          label: 'Equal',
          type: 'text',
          id: 'email',
        },
      ],
    },
    {
      key: 'First Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'firstName',
        },
      ],
    },
    {
      key: 'Last Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'lastName',
        },
      ],
    },
  ],
};

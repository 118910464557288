export const headerConfig = {
  default: {
    headerTitle: 'Application',
    headerDescription: 'Choose application type to start',
  },
  nwp: {
    headerTitle: 'New Work Permit Application',
    headerDescription: 'Enter details for New Work Permit Application',
  },
  ncsme: {
    headerTitle: 'CSME Application',
    headerDescription: 'Enter details for CSME Application',
  },
  rwp: {
    headerTitle: 'Renew Work Permit Application',
    headerDescription: 'Enter details for Renew Work Permit Application',
  },
  rcsme: {
    headerTitle: 'Renew CSME Application',
    headerDescription: 'Enter details for Renew CSME Application',
  },
  marriageExemption: {
    headerTitle: 'Marriage Exemption Application',
    headerDescription: 'Enter details for Marriage Exemption Application',
  },
  csmev: {
    headerTitle: 'CSME Verification',
    headerDescription: 'Enter details for CSME Verification',
  },
  rightsOfEst: {
    headerTitle: 'Rights Of Establishment',
    headerDescription: 'Enter details for Rights Of Establishment',
  },
};

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TweetyWidget } from '@/widgets/tweety-widget-react-main';
export const DocRejectedForm = ({
  showModal,
  formConfig,
  onSubmit,
  onHideClick,
}: any) => {
  console.log("asdvasfd--->sdfsdf",formConfig)
  const { FIELDS, layout, dynamicLayout } = formConfig;
  console.log('staticApplicationContentConfig', FIELDS, layout);

  return (
    <>
      <Modal size="lg" show={showModal} onHide={onHideClick}>
        <Modal.Header closeButton>
          <Modal.Title>Update Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TweetyWidget
            labelColor={'#000000'}
            fields={FIELDS}
            layout={layout}
            dynamicLayout={dynamicLayout}
            onFormSubmit={onSubmit}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

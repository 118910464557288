import { vappContainer, VApplication } from '../../../../../application';
import { logger as log } from '@common/logger';
import { MasterAdminInvitePageService } from '@/pages/MasterAdminInvite/services';

const MODULE_NAME = 'profile.machines.states.inviteUser';

const getSrv = () => {
  return vappContainer.get<MasterAdminInvitePageService>(
    MasterAdminInvitePageService.displayName
  );
};

export const inviteUser = async (context: any, event: any) => {
  const currentState = 'INVITE_USER';
  const method = 'revokeCred';

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.agent.inviteUser(event?.payload);

      if (content) {
        return resolve({
          key: 'INVITE_USER_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'INVITE_USER_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      let _msg = err?.message?.replace('GraphQL error:', '');
      _msg?.replace('UnprocessableEntityError:', '');
      return reject({
        key: 'INVITE_USER_FAILED',
        value: _msg,
      });
    }
  });
};

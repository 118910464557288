import rawCountries from './rawCountries';

function createCountryObject(countries: any, value: any) {
  const countryObject: any = {};
  countries.forEach((country: any) => {
    countryObject[country] = value;
  });
  return countryObject;
}

// Filter out Jamaica from rawCountries
const countriesOtherThanJamaica = rawCountries
  .filter((country:any) => country[0] !== 'Jamaica')
  .map((country:any) => country[0]);


export const csmeVerificationConfig = {
  nextStepLabel: 'Next',
  submitButtonText: 'Submit',
  previewLabel: '',
  userFormTitle: 'Enter Applicant Information',
  csmeLabel: 'CSME Application - New',
  csmeVerificationLabel: 'CSME Application - Verification',
  csmeRenewalLabel: 'CSME Application - Renewal',
  csmeAppealLabel: 'CSME Application - Appeal',
  successIcon: 'https://i.ibb.co/RNvQN2X/success.png',
  errorIcon: 'https://i.ibb.co/GM7nsgp/icons8-error-48.png',
  // csmeSubLabel1: 'Enter Prospective Employee Details',
  // csmeSubLabel2: 'Enter Employer Details',
  acknowledgementText:
    'I certify to the best of my knowledge and belief, that the above information is correct and accept the responsibility for the support and repatriation expenses of the applicant and his family should the need arise.',
  progressBarSteps: [
    'CSME Application - Verification',
    'CSME Application - Verification',
    'CSME Application - Verification',
    'CSME Application - Verification',
    'CSME Application - Verification',
    'Acknowledgement',
  ],
  fields: [
    [
      {
        type: 'text',
        compProps: {
          label: 'Last Name',
          id: 'lastName',
          placeholder: 'Enter Last Name(no special characters,no spaces )',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'First Name',
          id: 'firstName',
          placeholder: 'Enter First Name(no special characters,no spaces )',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Middle Name',
          id: 'middleInitial',
          placeholder: 'Enter Middle Name',
        },
      },

      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Street Address 1',
          id: 'address1',
          placeholder: 'Enter Address 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Street Address 2',
          rows: 1,
          id: 'address2',
          placeholder: 'Enter Address 2',
          required: false,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'parish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City',
          id: 'city',
          placeholder: 'Enter City',
          required: true,
          options: [],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Country',
          id: 'country',
          placeholder: 'Select Country',
          required: true,
          options: [
            {
              id: 'Antigua and Barbuda',
              label: 'Antigua and Barbuda',
            },
            {
              id: 'Barbados',
              label: 'Barbados',
            },
            {
              id: 'Belize',
              label: 'Belize',
            },
            {
              id: 'Dominica',
              label: 'Dominica',
            },
            {
              id: 'Grenada',
              label: 'Grenada',
            },
            {
              id: 'Jamaica',
              label: 'Jamaica',
            },
            {
              id: 'St. Kitts-Nevis-Anguilla',
              label: 'St. Kitts-Nevis-Anguilla',
            },
            {
              id: 'St. Lucia',
              label: 'St. Lucia',
            },
            {
              id: 'St. Vincent and the Grenadines',
              label: 'St. Vincent and the Grenadines',
            },
            {
              id: 'Suriname',
              label: 'Suriname',
            },
            {
              id: 'Trinidad and Tobago',
              label: 'Trinidad and Tobago',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Postal Code',
          id: 'postalCode',
          placeholder: 'Enter Postal Code',
        },
      },

      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Application',
          id: 'dateOfApplication',
          isPast: true,
          required: true,
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone Contact',
          id: 'userTelephoneNumber',
          placeholder: 'Enter Telephone Number',
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'userEmail',
          placeholder: 'Enter Email address',
          required: true,
          readOnly: true,
          disabled: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          id: 'gender',
          direction: 'horizontal',
          label: 'Sex',
          value: 'Male',
          options: [
            {
              id: 'Male',
              label: 'Male',
            },
            {
              id: 'Female',
              label: 'Female',
            },
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          id: 'isNameChange',
          direction: 'horizontal',
          label: 'If there is a name change',
          value: 'yes',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Birth',
          id: 'dateOfBirth',
          isAdult: true,
          required: true,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Nationality',
          id: 'nationality',
          placeholder: 'Enter Nationality',
          required: true,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country of Birth',
          id: 'placeOfBirth',
          placeholder: 'Enter Country of Birth',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Naturalization Certificate',
          id: 'countryNaturalizationCertificate',
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
          maxFiles: 1,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passport',
          placeholder: 'Enter Passport Number(no special characters, no spaces)',
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date Of Entry into Jamaica',
          id: 'dateOfEntry',
          isFuture: true,
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Port Of Entry',
          id: 'portOfEntry',
          placeholder: 'Enter Port Of Entry',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Certificate Number',
          id: 'credentialNo',
          placeholder: 'Enter Certificate Number',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label:
            'Member state and issuing authority for qualifying certificate',
          id: 'issuingAutority',
          placeholder: 'Enter member state and issuing authority',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Destination Caribbean State',
          id: 'destinationCarribbeanState',
          placeholder: 'Enter Destination Caribbean State',
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date Issue',
          id: 'issuanceDate',
          required: true,
          isPast: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Passport picture',
          id: 'passportSizePhoto',

          maxFiles: 1,
          supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Marital Status',
          id: 'maritalStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Single',
              id: 'single',
            },
            {
              label: 'Divorced',
              id: 'divorced',
            },
            {
              label: 'Widowed',
              id: 'widowed',
            },
            {
              label: 'Married',
              id: 'married',
            },
            {
              label: 'Separated',
              id: 'separated',
            },
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Purpose of Relocation',
          id: 'purposeOfRelocation',
          direction: 'horizontal',
          options: [
            {
              label: 'Self-Employment',
              id: 'SELF_EMPLOYMENT',
            },
            {
              label: 'Employment',
              id: 'EMPLOYMENT',
            },
            {
              label: 'Study',
              id: 'STUDY',
            },
            {
              label: 'Family/Personal Relationship',
              id: 'PERSONAL_RELATIONSHIP',
            },
          ],
          required: true,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Category',
          id: 'employmentOccupation',
          placeholder: 'Enter Category',
          options: [
            {
              label: 'Graduates',
              id: 'Graduates',
            },
            {
              label: 'Artiste',
              id: 'Artiste',
            },
            {
              label: 'Musicians',
              id: 'Musicians',
            },
            {
              label: 'Sport Persons',
              id: 'Sport Persons',
            },
            {
              label: 'Media Workers',
              id: 'Media Workers',
            },
            {
              label: 'Nurses',
              id: 'Nurses',
            },
            {
              label: 'Teachers',
              id: 'Teachers',
            },
            {
              label: 'Artisans',
              id: 'Artisans',
            },
            {
              label: 'Holders of Associate Degree',
              id: 'Holders of Associate Degree',
            },
            {
              label: 'Household Domestic',
              id: 'Household Domestic',
            },
            {
              label: 'Agriculture',
              id: 'Agriculture',
            },
            {
              label: 'Security Guards',
              id: 'Security Guards',
            },
          ],
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nature of Business',
          id: 'userNatureOfBusiness',
          placeholder: 'Enter Nature of Business',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label:
            'Specify the nature of professional or business activity you intend to undertake in Jamaica',
          id: 'natureOfProfessionalActivity',
          placeholder: 'Enter Nature of Professional Activity',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Do you have any dependents',
          id: 'isDependentExist',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'In case of Dependents, How many dependents?',
          id: 'noOfChildren',
          placeholder: 'Enter number of Dependents',
          required: true,
        },
      },
      {
        type: 'dynamicFields',
        compProps: {
          id: 'dependentInfo',
          cardType: 'Dependent',
          label: 'Dependents Info',
          lookUpField: 'noOfChildren',
          required: false,
          errorText: 'Please enter all Children / Dependent info',
          attributeForm: {
            modalTitle: 'Add Dependent Info',
            editTitle: 'Edit Dependent Info',
            fields: [
              {
                type: 'text',
                compProps: {
                  label: 'Full Name',
                  id: 'dependentName',
                  placeholder: 'Enter Full Name',
                  required: true,
                },
              },
              {
                type: 'number',

                compProps: {
                  id: 'dependentAge',
                  placeholder: 'Age',
                  label: 'Age',
                  required: true,
                },
              },
              {
                type: 'radio',
                compProps: {
                  id: 'dependentSex',
                  direction: 'horizontal',
                  label: 'Sex',
                  value: 'Male',
                  options: [
                    {
                      id: 'Male',
                      label: 'Male',
                    },
                    {
                      id: 'Female',
                      label: 'Female',
                    },
                  ],
                  required: true,
                },
              },
              {
                type: 'countrySelect',
                compProps: {
                  label: 'Nationality',
                  id: 'dependentNationality',
                  placeholder: 'Enter Nationality',
                  required: true,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Passport Number',
                  id: 'dependentPassport',
                  placeholder: 'Enter Passport Number',
                  required: true,
                },
              },
              {
                type: 'select',
                addOnValidation: [
                  {
                    currField: 'MINOR_CHILD',
                    lookUpField: 'dependentAge',
                    maxAllowed: 17,
                  },
                  {
                    currField: 'ADOPTED_CHILD',
                    lookUpField: 'dependentAge',
                    maxAllowed: 17,
                  },
                  {
                    currField: 'STEP_CHILD',
                    lookUpField: 'dependentAge',
                    maxAllowed: 17,
                  },
                  {
                    currField: 'COMMON_LAW_CHILD',
                    lookUpField: 'dependentAge',
                    maxAllowed: 17,
                  },
                ],
                compProps: {
                  label: 'Relationship to Caribbean skilled person',
                  id: 'dependentRelationship',
                  errorText: 'Please enter correct Age to select this option!',
                  options: [
                    {
                      label: 'Husband',
                      id: 'HUSBAND',
                    },
                    {
                      label: 'Wife ',
                      id: 'WIFE',
                    },
                    {
                      label: 'Common law wife ',
                      id: 'COMMON_LAW_WIFE',
                    },
                    {
                      label: 'Common law Husband',
                      id: 'COMMON_LAW_HUSBAND',
                    },
                    {
                      label: 'Child of applicant',
                      id: 'MINOR_CHILD',
                    },
                    {
                      label: 'Adopted child',
                      id: 'ADOPTED_CHILD',
                    },
                    {
                      label: 'Step child',
                      id: 'STEP_CHILD',
                    },
                    {
                      label: 'Child of common law wife or husband',
                      id: 'COMMON_LAW_CHILD',
                    },
                  ],
                  required: true,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Certified Copy of Birth Certificate',
                  id: 'certifyBirth',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  required: true,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Bio-Data Page of Valid Passport',
                  id: 'passportProof',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  required: true,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Proof of Adoption (Where necessary)',
                  id: 'adoption',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  // required: true,
                },
              },
            ],
            layout: [
              ['dependentName'],
              ['dependentAge', 'dependentSex'],
              ['dependentNationality'],
              ['dependentPassport'],
              ['dependentRelationship'],
            ],

            validatorConfig: {
              ruleSets: [
                {
                  fields: {
                    dependentName: [
                      {
                        type: 'matches',
                        pattern: '^[A-Za-z ]+$',
                        errorMessage: 'Name is invalid.',
                      },
                    ],
                    dependentPassport: [
                      {
                        type: 'matches',
                        pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
                        errorMessage: 'Passport is not valid',
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Others (Please specify):',
          id: 'highEducationQualificationOthers',
          placeholder: 'Enter Qualification',
          required: true,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Highest Education Qualification',
          id: 'highEducationQualification',
          options: [
            {
              label: 'Diploma (secondary level)',
              id: 'diplomaSecondary',
            },
            {
              label: 'Caribbean Vocational Qualification',
              id: 'caribbeanVocationalQualification',
            },
            {
              label: 'Diploma (tertiary level)',
              id: 'diplomaTertiary',
            },
            {
              label: 'Associate degree',
              id: 'associateDegree',
            },
            {
              label: 'University/ College degree',
              id: 'universityDegree',
            },
            {
              label: "Master's degree",
              id: 'masterDegree',
            },
            {
              label: 'Master of philosophy (M.Phil.)',
              id: 'masterOfPhilosophy',
            },
            {
              label: 'Doctor of philosophy (ph.D)',
              id: 'doctorOfPhilosophy',
            },
            {
              label: 'Others..',
              id: 'others',
            },
          ],
          placeholder: 'Enter Highest Education Qualification',
          errorText1: 'Please select appropriate Qualification',
          required: true,
        },
      },

      {
        type: 'dynamicFields',
        compProps: {
          id: 'qualification',
          cardType: 'Qualification',
          label: 'Qualification',
          required: true,
          attributeForm: {
            modalTitle: 'Add Qualification',
            editTitle: 'Edit Qualification',
            fields: [
              {
                type: 'multiSelect',
                compProps: {
                  label: 'Qualification',
                  id: 'qualificationName',
                  initialItems: [
                    {
                      label: 'Jamaica Federation of Musicians',
                      value: 'jamaicaFederationofMusicians',
                    },
                    {
                      label: 'Culture and Sports Ministry',
                      value: 'cultureAndSportsMinistry',
                    },
                    {
                      label: 'JAAA',
                      value: 'JAAA',
                    },
                    {
                      label: 'Jamaica Football Federation',
                      value: 'jamaicaFootballFederation',
                    },
                    {
                      label: 'Media Association of Jamaica.',
                      value: 'mediaAssociationOfJamaica',
                    },
                    {
                      label: 'Security Guard Certificate',
                      value: 'securityGuardCertificate',
                    },
                    {
                      label: 'Certificates',
                      value: '_Certificates',
                      children: [
                        {
                          label: 'HEART NSTA',
                          value: 'certificates_HEART_NSTA',
                        },
                        {
                          label: 'CVQ',
                          value: 'CVQ',
                        },
                        {
                          label: 'NVQ',
                          value: 'NVQ',
                        },
                      ],
                    },
                    {
                      label: 'Diplomas',
                      value: '_Diplomas',
                      children: [
                        {
                          label: 'HEART NSTA',
                          value: 'diplomas_HEART_NSTA',
                        },
                        {
                          label: 'Teaching Diploma',
                          value: 'teachingDiploma',
                        },
                      ],
                    },
                    {
                      label: 'Associate Degree',
                      value: 'associateDegree',
                    },
                    {
                      label: 'Bachelor’s Degree',
                      value: 'bachelorDegree',
                    },
                    {
                      label: 'Master’s Degree',
                      value: 'masterDegree',
                    },
                    {
                      label: 'Doctorate’s Degree',
                      value: 'doctorateDegree',
                    },
                  ],
                  placeholder: 'Select Qualification',
                  errorText1: 'Please select appropriate Qualification',
                  required: true,
                },
              },
              {
                type: 'datepicker',
                compProps: {
                  label: 'Date Awarded',
                  id: 'dateOfQualification',
                  required: true,
                  isPast: true,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Name of Institution',
                  id: 'name',
                  placeholder: 'Enter Institution Name',
                  required: true,
                },
              },
              {
                type: 'textarea',

                compProps: {
                  rows: 1,
                  label: 'Institution Address Line 1',
                  id: 'address1',
                  placeholder: 'Enter Address Line 1',
                  required: true,
                },
              },
              {
                type: 'textarea',

                compProps: {
                  label: 'Institution Address Line 2',
                  rows: 1,
                  id: 'address2',
                  placeholder: 'Enter Address Line 2',
                  required: false,
                },
              },

              {
                type: 'select',
                compProps: {
                  label: 'Parish',
                  id: 'parish',
                  placeholder: 'Select Parish',
                  required: true,
                  options: [
                    {
                      label: 'Clarendon',
                      id: 'Clarendon',
                    },
                    {
                      label: 'Hanover',
                      id: 'Hanover',
                    },
                    {
                      label: 'Kingston',
                      id: 'Kingston',
                    },
                    {
                      label: 'Manchester',
                      id: 'Manchester',
                    },
                    {
                      label: 'Portland',
                      id: 'Portland',
                    },
                    {
                      label: 'Saint Andrew',
                      id: 'Saint Andrew',
                    },
                    {
                      label: 'Saint Ann',
                      id: 'Saint Ann',
                    },
                    {
                      label: 'Saint Catherine',
                      id: 'Saint Catherine',
                    },
                    {
                      label: 'Saint Elizabeth',
                      id: 'Saint Elizabeth',
                    },
                    {
                      label: 'Saint James',
                      id: 'Saint James',
                    },
                    {
                      label: 'Saint Mary',
                      id: 'Saint Mary',
                    },
                    {
                      label: 'Saint Thomas',
                      id: 'Saint Thomas',
                    },
                    {
                      label: 'Trelawny',
                      id: 'Trelawny',
                    },
                    {
                      label: 'Westmoreland',
                      id: 'Westmoreland',
                    },
                  ],
                },
              },
              {
                type: 'select',
                compProps: {
                  label: 'City',
                  id: 'city',
                  placeholder: 'Select City',
                  required: true,
                  options: [],
                },
              },
              {
                type: 'countrySelect',
                compProps: {
                  label: 'Country',
                  id: 'country',
                  placeholder: 'Enter Country',
                  required: true,
                },
              },
            ],
            layout: [
              ['qualificationName'],
              ['dateOfQualification'],
              ['name'],
              ['address1', 'address2'],
              // ['parish', 'city'],
              // ['country'],
            ],
          },
        },
      },

      {
        type: 'radio',
        compProps: {
          label: 'Employment Status',
          id: 'employmentStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Self-Employed',
              id: 'SELF_EMPLOYED',
            },
            {
              label: 'Employed',
              id: 'EMPLOYED',
            },
            {
              label: 'Student',
              id: 'STUDENT',
            },
          ],
          required: true,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'Current Occupation',
          id: 'selfOccupation',
          placeholder: 'Enter Occupation',
          options: [
            {
              label: 'Graduates',
              id: 'Graduates',
            },
            {
              label: 'Artiste',
              id: 'Artiste',
            },
            {
              label: 'Musicians',
              id: 'Musicians',
            },
            {
              label: 'Sport Persons',
              id: 'Sport Persons',
            },
            {
              label: 'Media Workers',
              id: 'Media Workers',
            },
            {
              label: 'Nurses',
              id: 'Nurses',
            },
            {
              label: 'Teachers',
              id: 'Teachers',
            },
            {
              label: 'Artisans',
              id: 'Artisans',
            },
            {
              label: 'Holders of Associate Degree',
              id: 'Holders of Associate Degree',
            },
            {
              label: 'Household Domestic',
              id: 'Household Domestic',
            },
            {
              label: 'Agriculture',
              id: 'Agriculture',
            },
            {
              label: 'Security Guards',
              id: 'Security Guards',
            },
          ],
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Business Address 1',
          id: 'selfAddress1',
          placeholder: 'Enter Address 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Business Address 2',
          rows: 1,
          id: 'selfAddress2',
          placeholder: 'Enter Address 2',
          required: false,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'City',
          id: 'selfCity',
          placeholder: 'Enter City',
          required: true,
          options: [],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'selfParish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country',
          id: 'selfCountry',
          placeholder: 'Enter Country',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Employer Name',
          id: 'employerName',
          placeholder: 'Enter Employer Name',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Employer Address Line 1',
          id: 'employedAddress1',
          placeholder: 'Enter Address Line 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Employer Address Line 2',
          rows: 1,
          id: 'employedAddress2',
          placeholder: 'Enter Address Line 2',
          required: false,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'employedParish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City',
          id: 'employedCity',
          placeholder: 'Enter City',
          required: true,
          options: [],
        },
      },

      {
        type: 'countrySelect',
        compProps: {
          label: 'Country',
          id: 'employedCountry',
          placeholder: 'Enter Country',
          required: true,
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone',
          id: 'employedTelephone',
          placeholder: 'Enter Telephone Number',
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'employedEmailAddress',
          placeholder: 'Enter Email address',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Describe Nature of Business',
          id: 'employedNatureOfBusiness',
          placeholder: 'Describe Nature of Business',
          required: true,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Type of Education Program',
          id: 'educationType',
          options: [
            {
              label: 'Academic degree programme',
              id: 'Academic degree programme',
            },
            {
              label: 'Vocational training',
              id: 'Vocational training',
            },
            {
              label: 'Others',
              id: 'others',
            },
          ],
          placeholder: 'Enter Type of Education Program',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'If Others, please specify',
          id: 'educationTypeOthers',
          placeholder: 'Specify Type of Education Program',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Specify Degree or Certification to be awarded on completion',
          id: 'degree',
          placeholder: 'Specify degree of certification',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name of Institution',
          id: 'institutionName',
          placeholder: 'Enter Institution Name',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Address of Institution',
          rows: 1,
          id: 'institutionAddress',
          placeholder: 'Enter Address',
          required: true,
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone',
          id: 'studentTelephone',
          placeholder: 'Enter Telephone Number',
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email',
          id: 'studentEmailAddress',
          placeholder: 'Enter Email address',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Police Report from Country',
          id: 'policeClearanceCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'CSME Copy',
          id: 'csmeProof',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Bio-Data Page Copy',
          id: 'biodata',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'Select document type to support name change',
          id: 'nameChangeDocType',
          required: true,
          options: [
            {
              label: 'A deed poll',
              id: '1',
            },

            {
              label: 'Other documents',
              id: '4',
            },
          ],
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'A deed poll',
          id: 'deadPoll',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Marriage Certificate',
          id: 'marriageForNameChange',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Decree Absolute (Divorce)',
          id: 'divorseForNameChange',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Other document to support name change',
          id: 'nameChange',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Certified Copy of Academic Certificate',
          id: 'academic',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Marriage Certificate',
          id: 'marriage',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Decree Absolute (Divorce)',
          id: 'divorce',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
    ],
  ],
  layout: [
    {
      first: {
        label: 'Applicant Information',
        data: [
          ['lastName', 'firstName', 'middleInitial'],
          ['address1', 'address2'],
          ['parish', 'city'],
          ['postalCode'],
          ['dateOfApplication'],
          ['userTelephoneNumber'],
          ['userEmail', 'passport'],
          ['gender', 'dateOfBirth'],
          ['nationality', 'placeOfBirth'],
          ['maritalStatus'],
          ['dateOfEntry', 'portOfEntry'],
          ['credentialNo'],
          ['issuingAutority'],
          ['issuanceDate'],
          // ['destinationCarribbeanState'],
          ['purposeOfRelocation'],
          ['isDependentExist'],
        ],
      },
      second: {
        label: 'Education & Employment',
        data: [
          ['highEducationQualification'],
          ['qualification'],
          ['employmentStatus'],
        ],
      },
      third: {
        label: 'Documents',
        data: [
          // ['policeClearanceCertificate'],
          ['csmeProof'],
          ['biodata'],
          ['passportSizePhoto'],
          // ['isNameChange'],
          // ['academic'],
        ],
      },
    },
  ],
  dynamicLayout: {
    placeOfBirth:createCountryObject(countriesOtherThanJamaica, [['countryNaturalizationCertificate']]),
    educationType: {
      others: [['educationTypeOthers']],
    },
    highEducationQualification: {
      others: [['highEducationQualificationOthers']],
    },
    purposeOfRelocation: {
      SELF_EMPLOYMENT: [['natureOfProfessionalActivity']],
      EMPLOYMENT: [['employmentOccupation'], ['userNatureOfBusiness']],
    },
    isDependentExist: {
      yes: [['noOfChildren'], ['dependentInfo']],
    },
    employmentStatus: {
      STUDENT: [
        ['educationType'],
        ['degree'],
        ['institutionName'],
        ['institutionAddress'],
        ['studentTelephone'],
        ['studentEmailAddress'],
      ],
      EMPLOYED: [
        ['employerName'],
        ['employedAddress1'],
        ['employedAddress2'],
        ['employedParish', 'employedCity'],
        ['employedCountry', 'employedTelephone'],
        ['employedEmailAddress'],
        ['employedNatureOfBusiness'],
      ],
      SELF_EMPLOYED: [
        ['selfOccupation'],
        ['selfAddress1'],
        ['selfAddress2'],
        // ['selfParish'],
        // ['selfCity'],
        // ['selfCountry'],
      ],
    },
    isNameChange: {
      yes: [['nameChangeDocType']],
    },
    nameChangeDocType: {
      1: [['deadPoll']],
      4: [['nameChange']],
    },
  },
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          firstName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'Name is invalid.',
            },
          ],
          lastName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'lastname is not valid',
            },
          ],
          passport: [
            {
              type: 'matches',
              pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
              errorMessage: 'Passport is not valid',
            },
          ],
        },
      },
    ],
  },
  relatedDocuments: {
    1: ['deadPoll'],
    4: ['nameChange'],
  },
};

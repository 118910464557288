export const notificationConfig = {
  updateProfileSuccess: {
    title: 'Success!',
    icon: '',
    description: ['Profile has been updated Successfully'],
    acknowledgementButtonText: 'Close',
  },
  userInviteSuccess: {
    title: 'Success!',
    icon: '',
    description: ['Employee has been added successfully'],
    acknowledgementButtonText: 'Close',
  },
  resubmittedSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['Application has been resubmitted successfully'],
    acknowledgementButtonText: 'Close',
  },
};

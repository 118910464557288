export const downloadFormData = {
    FIELDS: [
      {
        type: 'datepicker',
        compProps: {
          id: 'startdate',
          label: 'Start Date',
          required: true,
          placeholder: 'Enter start date',
          disableFutureDate:true,
          before:"enddate",

        },
      },
      {
        type: 'datepicker',
        compProps: {
          id: 'enddate',
          label: 'End Date',
          required: true,
          placeholder: 'Enter end date',
          disableFutureDate:true,
          after:'startdate'
          
        },
      }
    ],
    layout: [['startdate'], ['enddate']],
  };
export const AnalyticsWidgetConfig = {
  layout: [
    {
      colProps: [
        {
          type: 'card-widget',
          col: {
            xs: 1,
            md: 2,
            lg: 4,
          },
          compProps: {
            total: '100',
            title: 'Total e - Credentials',
            value: '150',
            noProgress: true,
            id: 'total',
            country: null,
            icon: 'AnnotationIcon',
            color: 'white',
            bgColor: '#3498DB',
          },
        },
        {
          type: 'card-widget',
          col: {
            xs: 1,
            md: 2,
            lg: 4,
          },
          compProps: {
            total: '100',
            title: 'Work Permit',
            id: 'wp',
            value: '25,000',
            country: null,
            icon: 'AnnotationIcon',
            color: 'rgb(31, 41, 55)',
          },
        },

        {
          type: 'card-widget',
          col: {
            xs: 1,
            md: 2,
            lg: 4,
          },
          compProps: {
            total: '100',
            title: 'CSME Certificate',
            id: 'csme',
            value: '2',
            country: null,
            icon: 'AnnotationIcon',
            color: 'rgb(31, 41, 55)',
          },
        },
        // {
        //   type: 'card-widget',
        //   col: {
        //     xs: 1,
        //     md: 2,
        //     lg: 4,
        //   },
        //   compProps: {
        //     total: '100',
        //     title: 'CSME Verification',
        //     id: 'csmev',
        //     value: '25,000',
        //     country: null,
        //     icon: 'AnnotationIcon',
        //     color: 'rgb(31, 41, 55)',
        //   },
        // },
        {
          type: 'card-widget',
          col: {
            xs: 1,
            md: 2,
            lg: 4,
          },
          compProps: {
            total: '100',
            title: 'Marriage Exemption',
            value: '150',
            id: 'mec',
            country: null,
            icon: 'AnnotationIcon',
            color: 'rgb(31, 41, 55)',
          },
        },
        // {
        //   type: 'card-widget',
        //   col: {
        //     xs: 1,
        //     md: 2,
        //     lg: 4,
        //   },
        //   compProps: {
        //     total: '100',
        //     title: 'General Exemption',
        //     value: '150',
        //     id: 'exem',
        //     country: null,
        //     icon: 'AnnotationIcon',
        //     color: 'rgb(31, 41, 55)',
        //   },
        // },
        // {
        //   type: 'card-widget',
        //   col: {
        //     xs: 1,
        //     md: 2,
        //     lg: 4,
        //   },
        //   compProps: {
        //     total: '100',
        //     title: 'Waiver',
        //     value: '150',
        //     id: 'waiver',
        //     country: null,
        //     icon: 'AnnotationIcon',
        //     color: 'rgb(31, 41, 55)',
        //   },
        // },
        {
          type: 'card-widget',
          col: {
            xs: 1,
            md: 2,
            lg: 4,
          },
          compProps: {
            total: '100',
            title: 'Right of Establishment',
            value: '150',
            id: 'roe',
            country: null,
            icon: 'AnnotationIcon',
            color: 'rgb(31, 41, 55)',
          },
        },
      ],
    },
  ],
};

export const error = {
  updateProfile: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  getProfile: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  suspendUser: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  noProfile: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: [
      'No profile data found',
      'Kindly create new profile by clicking Create Profile button.',
    ],
    acknowledgementButtonText: 'Close',
  },
};

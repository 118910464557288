export const header2Config = {
  headerTitle: ' Choose Prospective Applicant',
  refreshButton: {
    id: 'refresh',
    label: 'Refresh',
    icon: 'RefreshIcon',
  },
  headerAction1: {
    id: 'invite_employee',
    label: 'Add Employee',
    icon: 'PlusIcon',
  },
};

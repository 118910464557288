export const loadingConfig = {
  loadingUsers: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'Rejected applications list...',
    description: 'This might take few seconds, Please wait !',
  },

  usersEmpty: {
    titleNormalText: 'Rejected Applications',
    titleBoldText: 'Not found !',
    description: 'There is no rejected applications',
  },
};

export const marriageConfig = {
  fields: [
    [
      {
        type: 'text',
        compProps: {
          label: 'First Name',
          id: 'firstName',
          placeHolder: 'Enter First Name',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Last Name',
          id: 'lastName',
          placeHolder: 'Enter Last Name',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Middle Initial',
          id: 'middleInitial',
          placeHolder: 'Enter Middle Initial',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Maiden Name',
          id: 'maidenName',
          placeHolder: 'Enter Maiden Name',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Country of Birth',
          id: 'countryOfBirth',
          placeHolder: 'Enter Country of Birth',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nationality',
          id: 'nationality',
          placeHolder: 'Enter Nationality',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Nationality Copy',
          id: 'nationalityProof',

          maxFiles: 1,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passport',
          placeHolder: 'Enter Passport Number',
        },
      },
      {
        type: 'datepicker',
        compProps: { label: 'Passport Issue Date', id: 'passportIssue' },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Expiry',
          isPassport: true,
          id: 'passportExpiry',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Passport Copy',
          id: 'passportProof',

          maxFiles: 1,
        },
      },
      {
        type: 'datepicker',
        compProps: { id: 'dateOfBirth', isAdult: true, label: 'Date of Birth' },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Birth Certificate Copy',
          id: 'birthCertificateProof',

          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Photograph',
          id: 'photograph',

          maxFiles: 1,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Gender',
          id: 'gender',
          direction: 'horizontal',
          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'TRN',
          id: 'trn',
          placeHolder: 'Enter your TRN , should not contain any special characters ex: 123456789',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Occupation',
          id: 'occupation',
          placeHolder: 'Enter Occupation',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Occupation Copy',
          id: 'occupationProof',

          maxFiles: 1,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Previously Married',
          id: 'maritalStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Were you?',
          id: 'wereYou',
          direction: 'horizontal',
          options: [
            {
              label: 'Divorced',
              id: 'divorced',
            },
            {
              label: 'Widowed',
              id: 'widowed',
            },
          ],
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Employment Status',
          id: 'employmentStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Self Employed',
              id: 'selfEmployed',
            },
            {
              label: 'Employed',
              id: 'employed',
            },
            {
              label: 'Unemployed',
              id: 'unemployed',
            },
          ],
        },
      },
    ],
    [
      {
        type: 'sectionTitle',
        compProps: {
          id: 'staffComposition',
          label: "JAMAICAN'S INFORMATION",
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'First Name',
          id: 'jamFirstName',
          placeHolder: 'Enter First Name',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Last Name',
          id: 'jamLastName',
          placeHolder: 'Enter Last Name',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Middle Initial',
          id: 'jamMiddleInitial',
          placeHolder: 'Enter Middle Initial',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Maiden Name',
          id: 'jamMaidenName',
          placeHolder: 'Enter Maiden Name',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Country of Birth',
          id: 'jamCountryOfBirth',
          placeHolder: 'Enter Country of Birth',
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Type of Identification',
          id: 'typeOfIdentification',
          options: [
            {
              label: 'Select..',
              id: '',
            },
            {
              label: 'Passport',
              id: 'passport',
            },
            {
              label: "Driver's License",
              id: 'driversLicense',
            },
            {
              label: 'National ID',
              id: 'nationalId',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          id: 'typeOfIdentificationText',
          placeHolder: 'ID #',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Identification Copy',
          id: 'jamIdentificationProof',

          maxFiles: 1,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Birth',
          id: 'jamDateOfBirth',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Birth Certificate Copy',
          id: 'jamBirthCertificateProof',

          maxFiles: 1,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Gender',
          id: 'jamGender',
          direction: 'horizontal',
          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Previously Married',
          id: 'jamMaritalStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Were you?',
          id: 'jamWereYou',
          direction: 'horizontal',
          options: [
            {
              label: 'Divorced',
              id: 'divorced',
            },
            {
              label: 'Widowed',
              id: 'widowed',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'TRN',
          id: 'jamTrn',
          placeHolder: 'Enter your TRN , should not contain any special characters ex: 123456789',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Occupation',
          id: 'jamOccupation',
          placeHolder: 'Enter Occupation',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Occupation Copy',
          id: 'jamOccupationProof',

          maxFiles: 1,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Birth Certificate',
          id: 'birthCertificate',
          options: [
            {
              label: 'Select..',
              id: '',
            },
            {
              label: 'Jamaican Birth Certificate',
              id: 'jamaican',
            },
            {
              label: 'Naturalization Birth Certificate',
              id: 'naturalization',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          id: 'birthCertificateText',
          placeHolder: 'ID #',
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Employment Status',
          id: 'jamEmploymentStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Self Employed',
              id: 'selfEmployed',
            },
            {
              label: 'Employed',
              id: 'employed',
            },
            {
              label: 'Unemployed',
              id: 'unemployed',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name of the Employer (if you are employed)',
          id: 'jamNameOfEmployer',
          placeHolder: 'Enter Name of the Employer',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: ' Business Address Line 1',
          rows: 1,
          id: 'jamBusinessAddress1',
          placeHolder: 'Enter Address Line 1',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: ' Business Address Line 2',
          rows: 1,
          id: 'jamBusinessAddress2',
          placeHolder: 'Enter Address Line 2',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Street',
          id: 'jamBusinessStreet',
          placeHolder: 'Enter Street',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'City',
          id: 'jamBusinessCity',
          placeHolder: 'Enter City',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Parish',
          id: 'jamBusinessParish',
          placeHolder: 'Enter Parish',
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone',
          id: 'telephone',
          placeHolder: 'Enter Telephone Number',
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'email',
          placeHolder: 'Enter Email address',
        },
      },
    ],
    [
      {
        type: 'sectionTitle',
        compProps: {
          id: 'marriageDetails',
          label: 'MARRIAGE DETAILS ( AS IT APPEAR ON MARRIAGE CERTIFICATE )',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Marriage Certificate #',
          id: 'marriageCertificate',
          placeHolder: 'Enter Marriage Certificate #',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name of the Marriage Officer',
          id: 'marriageOfficer',
          placeHolder: 'Enter Name of the Marriage Officer',
        },
      },
      {
        type: 'text',
        compProps: {
          label: "Groom's Name",
          id: 'groomName',
          placeHolder: "Enter Groom's Name",
        },
      },
      {
        type: 'text',
        compProps: {
          label: "Bride's Name",
          id: 'brideName',
          placeHolder: "Enter Bride's Name",
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Place of Marriage',
          id: 'placeOfMarriage',
          placeHolder: 'Enter Place of Marriage',
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Marriage',
          id: 'dateOfMarriage',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Address Of the Couple',
          rows: 1,
          id: 'addressOfCouple',
          placeHolder: 'Enter Address Of the Couple',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Parish',
          id: 'parish',
          placeHolder: 'Enter Parish',
        },
      },
    ],
    [
      {
        type: 'sectionTitle',
        compProps: {
          id: 'photographsAndSign',
          label: 'PHOTOGRAPHS & SIGNATURES',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Husband Photograph',
          id: 'husbandPhoto',
          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Husband Signature',
          id: 'husbandSignature',
          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Wife Photograph',
          id: 'wifePhoto',
          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Wife Signature',
          id: 'wifeSignature',
          maxFiles: 1,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Certified By',
          id: 'certifiedBy',
          placeHolder: 'Enter Name of Justice of the Peace (J.P)',
        },
      },
    ],
  ],
  layout: [
    [
      ['lastName', 'firstName'],
      ['middleInitial', 'maidenName'],
      ['countryOfBirth', 'nationality'],
      ['nationalityProof'],
      ['passport', 'passportIssue'],
      ['passportProof'],
      ['passportExpiry', 'dateOfBirth'],
      ['birthCertificateProof'],
      ['photograph'],
      ['gender', 'trn'],
      ['occupation'],
      ['occupationProof'],
      ['maritalStatus', 'wereYou'],
      ['employmentStatus'],
    ],
    [
      ['staffComposition'],
      ['jamLastName', 'jamFirstName'],
      ['jamMiddleInitial', 'jamMaidenName'],
      ['jamCountryOfBirth'],
      ['typeOfIdentification', 'typeOfIdentificationText'],
      ['jamIdentificationProof'],
      ['jamDateOfBirth'],
      ['jamBirthCertificateProof'],
      ['jamGender', 'jamMaritalStatus'],
      ['jamWereYou', 'jamTrn'],
      ['jamOccupation'],
      ['jamOccupationProof'],
      ['birthCertificate', 'birthCertificateText'],
      ['jamEmploymentStatus'],
      ['jamNameOfEmployer'],
      ['jamBusinessAddress1', 'jamBusinessAddress2'],
      ['jamBusinessStreet', 'jamBusinessCity'],
      ['jamBusinessParish', 'telephone'],
      ['email'],
    ],
    [
      ['marriageDetails'],
      ['marriageCertificate', 'marriageOfficer'],
      ['groomName', 'brideName'],
      ['placeOfMarriage', 'dateOfMarriage'],
      ['addressOfCouple'],
      ['parish'],
    ],
    [
      ['photographsAndSign'],
      ['husbandPhoto'],
      ['husbandSignature'],
      ['wifePhoto'],
      ['wifeSignature'],
      ['certifiedBy'],
    ],
  ],
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          trn: [
            {
              type: 'matches',
              pattern: '^[0-9a-zA-Z]+',
              errorMessage: 'TRN should contain alphabets and numbers.',
            },
          ],
          jamTrn: [
            {
              type: 'matches',
              pattern: '^[0-9a-zA-Z]+',
              errorMessage: 'TRN should contain alphabets and numbers.',
            },
          ],
          passport: [
            {
              type: 'matches',
              pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
              errorMessage: 'Passport is not valid',
            },
          ],
        },
      },
    ],
  },

  dynamicLayout: {},
};

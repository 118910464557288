import { APPLICATION_STATUS } from '@/config/applicationStatus.config';

const searchStatusFilter = Object.entries(APPLICATION_STATUS).map((item) => {
  return {
    label: item[1],
    value: item[0],
  };
});

export const settingsConfig = {
  searchStatusFilter,
  typeOfSearch: [
    {
      label: 'Application ID',
      value: 'applicationNo',
      op: 'Equal',
    },
  ],
  searchPlaceholderText: 'Search application by ID',
};

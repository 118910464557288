import React, { useEffect } from 'react';

import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';

import { actions } from './actions';
import { MACHINE_CONFIG } from './config';
import { services } from './services';

export const ResetPasswordPageGetContentContext = React.createContext({});
export let resetPasswordPageGetContentMachine: any;

const MODULE_NAME = 'machines.registerPage.GetPageContent';

export const useSMResetPasswordPageGetContent = (_context: any) => {
  let _machineConfig: any = MACHINE_CONFIG;
  if (_machineConfig && _machineConfig?.context) {
    _machineConfig.context = {
      ..._machineConfig.context,
      ...(_context || {}),
    };
  }
  resetPasswordPageGetContentMachine = createMachine(_machineConfig, {
    services,
    actions,
  });
  const [state, send] = useMachine(resetPasswordPageGetContentMachine);

  useEffect(() => {
    send({ type: 'START' });
  }, []);

  return {
    resetPasswordPageGetContentMachine,
    resetPasswordPageGetContentState: state,
    resetPasswordPageGetContentSend: send,
  };
};

import React, { useState } from 'react';
import { Offcanvas, Button, Modal } from 'react-bootstrap';
import { CommentCard } from '../CommentCard';
export const Comments = (props: {
  comment: any;
  show: any;
  handleClose: any;
}) => {
  const { comment, handleClose, show } = props;
  console.log('commentsinside-->', comment);
  // const comments =['Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries','passport invalid','photocopy blurr','docs not clear','passport invalid','photocopy blurr']
  let comments: any = [];
  comments.push(comment);
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: '#F2F4F6',
          maxHeight: '70vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {comments.map((cmt: any) => {
          return (
            <CommentCard comment={cmt} time={'November 22, 2023 12:41 PM'} />
          );
        })}
        {/* <RightContainer data={faqs} /> */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export const featuresSection = {
  image:
    'https://s3.ap-south-1.amazonaws.com/io.vlinder.public/vlinder-logo.png',
  title: 'Organization',
  subtitle: 'Your Organization Profile',
  buttons: [
    {
      key: 're-invite',
      title: 'Reinvite',
      bgColor: '#104382',
    },
    {
      key: 'activate',
      title: 'Activate',
      bgColor: '#104382',
    },
    {
      key: 'remove',
      title: 'Remove access',
      bgColor: 'rgb(172, 15, 15)',
    },
    {
      key: 'revoke',
      title: 'Revoke admin access',
      bgColor: 'rgb(172, 15, 15)',
    },
    {
      key: 'suspend',
      title: 'Suspend',
      bgColor: '#104382',
    },
  ],

  companyListItems: [
    {
      title: 'Nature of Business',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    },
    {
      title: 'Company Type',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    },
    {
      title: 'Taxpayer Registration No',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/country-icon.png',
    },
    {
      title: 'Incorporation Date',
      subTitle: '-',
      // icon: 'https://static.thenounproject.com/png/3437694-200.png',
    },
    {
      title: 'Website',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/country-icon.png',
    },
    {
      title: 'Street 1',
      subTitle: '',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    },
    {
      title: 'Street 2',
      subTitle: '',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    },
    {
      title: 'City',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/city-icon.png',
    },
    {
      title: 'Parish',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/country-icon.png',
    },

    {
      title: 'Postal Code',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
  ],

  listItems: [
    // {
    //   title: 'Last Name',
    //   subTitle: '',
    //   icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/name-icon.png',
    // },
    // {
    //   title: 'First Name',
    //   subTitle: '',
    //   icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/name-icon.png',
    // },
    // {
    //   title: 'Middle Name',
    //   subTitle: '',
    //   icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/name-icon.png',
    // },

    {
      title: 'Email',
      subTitle: '-',
      icon: 'https://i.ibb.co/Wg4zMsq/email-2.png',
    },
    {
      title: 'Role',
      subTitle: '-',
      icon: 'https://i.ibb.co/Hd6rTr2/office-building.png',
    },
    {
      title: 'Nationality',
      subTitle: '',
      icon: 'https://i.ibb.co/dmBptwx/nation.png',
    },
    {
      title: 'Telephone',
      subTitle: '',
      icon: 'https://i.ibb.co/yFL94cS/telephone.png',
    },
    {
      title: 'TRN',
      subTitle: '',
      icon: 'https://i.ibb.co/J7LRK9g/tax-1.png',
    },
  ],
  orgListItems: [
    {
      title: 'Email',
      subTitle: '-',
      icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/email-icon.png',
    },
    {
      title: 'Phone Number',
      subTitle: '-',
      icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
    {
      title: 'Address',
      subTitle: '-',
      icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    },
    {
      title: 'City',
      subTitle: '-',
      icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/city-icon.png',
    },
    {
      title: 'Country',
      subTitle: '-',
      icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/country-icon.png',
    },
    {
      title: 'Website',
      subTitle: '-',
      icon: 'https://static.thenounproject.com/png/3437694-200.png',
    },
  ],
};

export const settingsConfig = {
  searchStatusFilter: [
    {
      label: 'Select Type',
      value: '',
    },
    {
      label:'e - Work Permit',
      value:'WORK_PERMIT'
    },
   
    {
      label:'e - CSME Certificate',
      value:'CSME_CERTIFICATE'
    },
    // {
    //   label:'e - CSME Verification',
    //   value:'csmev'
    // },
    {
      label:'e - Marriage Exemption',
      value:'MARRIAGE_EXEMPTION_CERTIFICATE'
    },
    // {
    //   label:'e - General Exemption',
    //   value:'ngex'
    // },
    // {
    //   label:'e - Waiver',
    //   value:'waiver'
    // },
    {
      label:'e - Right of Establishment',
      value:'RIGHTS_OF_ESTABLISHMENT'
    },
  ],
  tableHeaders: [
    {
      key: 'Email',
      filterTypes: [
        {
          label: 'Contains',
          type: 'text',
          id: 'email',
        },
        {
          label: 'Equal',
          type: 'text',
          id: 'email',
        },
      ],
    },
    {
      key: 'First Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'firstName',
        },
      ],
    },
    {
      key: 'Last Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'lastName',
        },
      ],
    },
  ],
};

//@ts-noscheck
import Card from 'react-credit-cards';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from './utlis';
import 'react-credit-cards/es/styles-compiled.css';
import { useState } from 'react';
import { PaymentSummary } from '../PaymentSummary';
import { Col, Row } from 'react-bootstrap';

interface IPaymentCompProps {
  onSubmit?(data: any): void;
  paymentSummaryConfig?: any;
}

export const PaymentComp = (props: IPaymentCompProps) => {
  const { onSubmit, paymentSummaryConfig } = props;
  const [state, setState] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    // issuer: "",
    focused: '',
    formData: null,
  });
  const handleCallback = ({ issuer }: any, isValid: boolean) => {
    if (isValid) {
      // setState({...state, issuer });
    }
  };

  const handleInputFocus = ({ target }: any) => {
    setState({ ...state, focused: target.name });
  };

  const handleInputChange = ({ target }: any) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    setState({ ...state, [target.name]: target.value });
  };

  const formatExpiry = (date: string) => {
    return `${date.slice(3, 5)}${date.slice(0, 2)}`;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    setState({ ...state, formData });
    console.log({ ...state, formData }, 'ki->');
    onSubmit &&
      onSubmit({ ...formData, expiry: formatExpiry(formData?.expiry) });
  };
  return (
    <Row className="justify-content-around">
      <Col md={5} lg={4} xs={10}>
        <PaymentSummary {...paymentSummaryConfig} />
      </Col>
      <Col md={5} lg={4} xs={10} className="ms-4">
        <div key="Payment">
          <div className="App-payment">
            <Card
              number={state.number}
              name={state.name}
              expiry={state.expiry}
              cvc={state.cvc}
              //@ts-ignore}
              focused={state.focused}
              callback={handleCallback}
            />
            <form onSubmit={handleSubmit} className=" mx-auto mt-3">
              <div className="form-group my-2">
                <input
                  type="tel"
                  name="number"
                  className="form-control"
                  placeholder="Card Number"
                  //  pattern="\d{13,16}"
                  required
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="form-group my-2">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  required
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <input
                    type="tel"
                    name="expiry"
                    className="form-control"
                    placeholder="Valid Thru"
                    // pattern="\d\d/\d\d"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="tel"
                    name="cvc"
                    className="form-control"
                    placeholder="CVC"
                    // pattern="\d{3,3}"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </div>
              </div>
              <div className="form-actions d-flex justify-content-center mt-4">
                <button className="btn btn-secondary btn-block">
                  Proceed to PAY
                </button>
              </div>
            </form>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export const notificationConfig = {
  userInviteSuccess: {
    title: 'Success!',
    icon: '',
    description: ['Organization has been invited successfully'],
    acknowledgementButtonText: 'Close',
  },
  deleteUserSuccess: {
    descriptionTitle: 'Success!',
    icon: '',
    description: ['Organization has been deleted successfully'],
    acknowledgementButtonText: 'Close',
  },
  suspendUserSuccess: {
    title: 'Success!',
    icon: '',
    description: ['Organization has been suspended successfully'],
    acknowledgementButtonText: 'Close',
  },
  reInviteUser: {
    descriptionTitle: 'Success!',
    icon: '',
    description: [
      'Organization has been re-invited successfully',
      'An invite email has been sent to Organization email address',
    ],
    acknowledgementButtonText: 'Close',
  },
  activateUserSuccess: {
    descriptionTitle: 'Success!',
    icon: '',
    description: ['Organization has been activated back successfully'],
    acknowledgementButtonText: 'Close',
  },
};

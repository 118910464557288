import React, { memo, Key, useState } from 'react';

import { useSelect, useClassNames, UseSelectProps, ClassName } from './utils';
import './styles.css';
type Category = {
    id: string;
    name: string;
    children?: Category[];
};
export function MultipleLevelSelectionCompComponent<Category>({
    classes,
    getItemKey,
    getItemLabel,
    hasNestedItems,
    ...rest
}: MultipleLevelSelectionCompProps<Category>) {
    const {
        open,
        label,
        renderingItems,
        toggle,
        handleClickItem,
        isSelectedItem,
    } = useSelect({
        ...rest,
        getItemLabel,
        hasNestedItems,
    });

    const classesNames = useClassNames(classes);
    const [val, setval] = useState('');
    console.log('fg->', renderingItems);

    return (
        <div className={classesNames.root()}>
            <div className={classesNames.overlay(open)} onClick={toggle} />
            <div className={classesNames.selectionHeader()} onClick={toggle}>
                <span>{label}</span>
            </div>
            {renderingItems && (
                <div className={classesNames.selectionEntries(open)}>
                    <div className="flex flex-row">
                        {Object.keys(renderingItems).map((level: string) => (
                            <ul
                                key={`entry-level-${level}`}
                                className={classesNames.levelEntry()}
                            >
                                {renderingItems[+level].map(
                                    (item: Category) => {
                                        const selected = isSelectedItem(
                                            item,
                                            +level
                                        );
                                        const nestable = hasNestedItems(
                                            item,
                                            +level
                                        );
                                        console.log(item);
                                        return (
                                            <li
                                                key={getItemKey(item)}
                                                className={classesNames.levelItem(
                                                    {
                                                        nestable,
                                                        selected,
                                                    }
                                                )}
                                                //@ts-ignore
                                                // style={val=== item?.id ? {width:'200px'}: {width:'fit-content'}}
                                                title={getItemLabel(item)}
                                                onClick={handleClickItem(
                                                    item,
                                                    +level
                                                )}
                                            >
                                                {getItemLabel(item)}
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

const MultipleLevelSelectionComp = memo(
    MultipleLevelSelectionCompComponent
) as typeof MultipleLevelSelectionCompComponent & React.ComponentType<any>;
MultipleLevelSelectionComp.displayName = 'MultipleLevelSelectionComp';

export default MultipleLevelSelectionComp;

export interface MultipleLevelSelectionCompProps<Category>
    extends UseSelectProps<Category> {
    getItemKey: (item: Category) => Key; // React key generator based on item
    classes?: Partial<Record<ClassName, string>>; // Custom classe names
}

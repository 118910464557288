export const headerConfig = {
  headerTitle: 'Admins',
  headerDescription: 'List of Admins',
  refreshButton: {
    id: 'refresh',
    label: 'Refresh',
    icon: 'RefreshIcon',
  },
  headerAction1: {
    id: 'invite_admin',
    label: 'Invite Admin',
    icon: 'PlusIcon',
  },
};

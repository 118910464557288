import React, { useState } from 'react';
import './index.css';
import MultipleLevelSelectionComp from './components/MultipleLevelSelection';

interface IMultipleLevelSelectionProps {
    initialItems: Category[];
    placeholder?: string;
    id: string;
    label?: string;
    value?: string;
    required?: boolean;
    labelColor?: string;
    errorText?: string;
    disabled?: boolean;
    hasError?: boolean;
    onCompChange?(id: string, payload: any): void;
}

export function MultipleLevelSelection(props: IMultipleLevelSelectionProps) {
    const { initialItems, placeholder, label } = props;
    const [category, setCategory] = useState<Category>();
    return (
        <div className="flex flex-col flex-align-start">
            <p>
                {label ? 'Selected role:' : ''} {category?.name}
            </p>
            <MultipleLevelSelectionComp
                // initialItems={getCategoriesByParentId(0)}
                initialItems={initialItems}
                getItemKey={(item: Category) => item?.id}
                getItemLabel={(item: Category) => item?.name}
                getNestedItems={(item: Category) => {
                    console.log(item, 'jio->');
                    return item?.children ?? [];
                }}
                hasNestedItems={(_, level) => !!_?.children}
                isEqual={(item, item2) => item?.id === item2?.id}
                placeholder={placeholder ?? 'Choose role'}
                onChange={setCategory}
            />
        </div>
    );
}

export const applicationCardConfig = {
  employer: {
    appCardList: [
      {
        appCardId: 'NEW_WORK_PERMIT',
        appCardTitle: 'New Work Permit',
        appCardDescription: 'Create a New Work Permit Application',
      },
      {
        appCardId: 'RENEW_WORK_PERMIT',
        appCardTitle: 'Renew Work Permit',
        appCardDescription: 'Renew an expired Work Permit',
      },
    ],
  },
  applicant: {
    appCardList: [
      {
        appCardId: 'NEW_CSME_CERTIFICATE',
        appCardTitle: 'CSME Application',
        appCardDescription: 'Create a CSME Application',
      },
      {
        appCardId: 'CSME_VERIFICATION',
        appCardTitle: 'CSME Verification Application',
        appCardDescription: 'Create a CSME Verification Application',
      },
      {
        appCardId: 'MARRIAGE_EXEMPTION',
        appCardTitle: 'Marriage Exemption',
        appCardDescription: 'Create a Marriage Exemption Application',
      },
    ],
  },
};

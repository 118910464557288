import { vappContainer, VApplication } from '../../../../../application';
import { logger as log } from '@common/logger';
import { ApplicationStatusPageService } from '../../../services';

const MODULE_NAME = 'profile.machines.states.getPage';

const getSrv = () => {
  return vappContainer.get<ApplicationStatusPageService>(
    ApplicationStatusPageService.displayName
  );
};
export const getPageContent = async (context: any, event: any) => {
  const currentState = 'GET_PAGE_CONTENT';
  const method = 'getPageContent';
  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      const _getArgs = () => {
        let _args: any = {};
        if (event?.type === 'START') {
          _args = context['GET_PAGE_CONTENT'];
        }
        if (event?.type === 'TRIGGER_REFRESH') {
          _args = {
            offset: event?.offset ?? 0,
            size: event?.size ?? 10,
            where: event?.where ?? [],
          };
        }
        console.log('getPageContent args =>', _args);
        return _args;
      };
      const srv = getSrv();
      const content = await srv.agent.getApplications(_getArgs());

      if (content) {
        return resolve({
          key: 'RENDER_PAGE',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'RENDER_PAGE_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'RENDER_PAGE_ERROR',
        value: _msg,
      });
    }
  });
};

export const errorConfig = {
  getUsers: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  activateUser: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  suspendUser: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  inviteUser: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  deleteUser: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  reinviteUser: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  appeal: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
};

export const docRejectedFormData = {
  FIELDS:{
    CARICOM_WP : [
      {
        type: 'dynamicFields',
        compProps: {
          id: 'evidentInfo',
          cardType: 'Document',
          label: 'Document Info',
          required: true,
          attributeForm: {
            modalTitle: 'Add Document Info',
            editTitle: 'Edit document Info',
            fields:  [
              {
                type: 'fileDropZone',
                compProps: {
                  id: 'evidences',
                  label: 'Documents',
                  required: true,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',
                    'image/jpeg',
                    'image/jpg',
                  ],
                  maxFiles: 1,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Documents',
                  id: 'photo',
  
                  maxFiles: 1,
                  supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
                  required: true,
                },
              },
              {
                type: 'select',
                compProps: {
                  label: 'Document Type',
                  id: 'evidencetype',
                  required: true,
                  options: [
                   
                    {
                      label: 'Birth Certificate Proof',
                      id: 'BIRTH_CERTIFICATE_PROOF',
                    },
                    {
                      label: 'Passport Proof',
                      id: 'PASSPORT_PROOF',
                    },
                    {
                      label: 'Qualification Proof',
                      id: 'QUALIFICATION_PROOF',
                    },
                    {
                      label: 'Photograph',
                      id: 'PHOTOGRAPH',
                    },
                    {
                      label: 'Police Clearance Certificate',
                      id: 'POLICE_CLEARANCE_CERTIFICATE',
                    },
                    
                    
                  ],
                },
              },
            ],
              
            
            layout: [['evidencetype']],
            dynamicLayout: {
              evidencetype: {
                BIRTH_CERTIFICATE_PROOF: [['evidences']],
                PASSPORT_PROOF: [['evidences']],
                QUALIFICATION_PROOF: [['evidences']],
                PHOTOGRAPH: [['photo']],
                POLICE_CLEARANCE_CERTIFICATE: [['evidences']],
                
              },
            },
          },
        },
      },
    ],
    WORK_PERMIT : [
    {
      type: 'dynamicFields',
      compProps: {
        id: 'evidentInfo',
        cardType: 'Document',
        label: 'Document Info',
        required: true,
        attributeForm: {
          modalTitle: 'Add Document Info',
          editTitle: 'Edit document Info',
          fields:  [
              {
                type: 'fileDropZone',
                compProps: {
                  id: 'evidences',
                  label: 'Documents',
                  required: true,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',
                    'image/jpeg',
                    'image/jpg',
                  ],
                  maxFiles: 1,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Documents',
                  id: 'photo',

                  maxFiles: 1,
                  supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
                  required: true,
                },
              },
              {
                type: 'select',
                compProps: {
                  label: 'Document Type',
                  id: 'evidencetype',
                  required: true,
                  options: [
                    {
                      label: 'Birth Certificate Proof',
                      id: 'BIRTH_CERTIFICATE_PROOF',
                    },
                    {
                      label: 'Nationality Proof',
                      id: 'NAIONALITY_PROOF',
                    },
                    {
                      label: 'Occupation Proof',
                      id: 'OCCUPATION_PROOF',
                    },
                    {
                      label: 'Passport Proof',
                      id: 'PASSPORT_PROOF',
                    },
                    {
                      label: 'Qualification Proof',
                      id: 'QUALIFICATION_PROOF',
                    },
                    {
                      label: 'Work Experience Proof',
                      id: 'WORK_EXPERIENCE_PROOF',
                    },
                    {
                      label: 'Photograph',
                      id: 'PHOTOGRAPH',
                    },
                    {
                      label: 'Police Clearance Certificate',
                      id: 'POLICE_CLEARANCE_CERTIFICATE',
                    },
                    {
                      label: 'Resume Proof',
                      id: 'RESUME_PROOF',
                    },
                    {
                      label: 'Covering Letter Proof',
                      id: 'COVERING_LETTER_PROOF',
                    },
                    {
                      label: 'Previous Employer Proof',
                      id: 'PREVIOUS_EMPLOYER_PROOF',
                    },
                    {
                      label: 'Previous Employer Proof Dep',
                      id: 'PREVIOUS_EMPLOYER_PROOF_DEP',
                    },
                    {
                      label: 'Job Title Proof',
                      id: 'JOB_TITLE_PROOF',
                    },
                    {
                      label: 'Tax Compilance',
                      id: 'TAX_COMPILANCE',
                    },
                    {
                      label: 'By Advertisement File',
                      id: 'BY_ADVERTISEMENT_FILE',
                    },
                    {
                      label: 'Step Taken Proof',
                      id: 'STEP_TAKEN_PROOF',
                    },
                    {
                      label: 'Applicant Signature',
                      id: 'APPLICANT_SIGNATURE',
                    },
                    {
                      label: 'Employer Signature',
                      id: 'EMPLOYER_SIGNATURE',
                    },
                    {
                      label: 'Candidate Resume',
                      id: 'CANDIDATE_RESUME',
                    },
                    {
                      label: 'Academic Certificate Proof',
                      id: 'ACADEMIC_CERTIFICATE_PROOF',
                    },
                    {
                      label: 'Marriage Certificate Proof',
                      id: 'MARRIAGE_CERTIFICATE_PROOF',
                    },
                    {
                      label: 'Divorce Certificate Proof',
                      id: 'DIVORCE_CERTIFICATE_PROOF',
                    },
                    {
                      label: 'Adoption Certificate Proof',
                      id: 'ADOPTION_CERTIFICATE_PROOF',
                    },
                    {
                      label: 'Name Change Proof',
                      id: 'NAME_CHANGE_PROOF',
                    },
                    {
                      label: 'Certified Copy Of Birth Certificate',
                      id: 'CERTIFIED_COPY_OF_BIRTH_CERTIFICATE',
                    },
                    {
                      label: 'Biodata Page Copy',
                      id: 'BIODATA_PAGE_COPY',
                    },
                    {
                      label: 'Deed Poll Copy',
                      id: 'DEED_POLL_COPY',
                    },
                  ],
                },
              },
            ],
            
          
          layout: [['evidencetype']],
          dynamicLayout: {
            evidencetype: {
              BIRTH_CERTIFICATE_PROOF: [['evidences']],
              NAIONALITY_PROOF: [['evidences']],
              OCCUPATION_PROOF: [['evidences']],
              PASSPORT_PROOF: [['evidences']],
              QUALIFICATION_PROOF: [['evidences']],
              WORK_EXPERIENCE_PROOF: [['evidences']],
              PHOTOGRAPH: [['photo']],
              POLICE_CLEARANCE_CERTIFICATE: [['evidences']],
              RESUME_PROOF: [['evidences']],
              COVERING_LETTER_PROOF: [['evidences']],
              PREVIOUS_EMPLOYER_PROOF: [['evidences']],
              PREVIOUS_EMPLOYER_PROOF_DEP: [['evidences']],
              JOB_TITLE_PROOF: [['evidences']],
              TAX_COMPILANCE: [['evidences']],
              BY_ADVERTISEMENT_FILE: [['evidences']],
              STEP_TAKEN_PROOF: [['evidences']],
              APPLICANT_SIGNATURE: [['evidences']],
              EMPLOYER_SIGNATURE: [['evidences']],
              CANDIDATE_RESUME: [['evidences']],
              ACADEMIC_CERTIFICATE_PROOF: [['evidences']],
              MARRIAGE_CERTIFICATE_PROOF: [['evidences']],
              DIVORCE_CERTIFICATE_PROOF: [['evidences']],
              ADOPTION_CERTIFICATE_PROOF: [['evidences']],
              NAME_CHANGE_PROOF: [['evidences']],
              CERTIFIED_COPY_OF_BIRTH_CERTIFICATE: [['evidences']],
              BIODATA_PAGE_COPY: [['evidences']],
              DEED_POLL_COPY: [['evidences']],
              FINANCIAL_STATEMENT_PROOF: [['evidences']],
              APPLICANT_PHOTOGRAPH: [['evidences']],
              PREVIOUS_SPOUSE_DEATH_CERTIFICATE: [['evidences']],
              JAMAICAN_DIVORCE_CERTIFICATE: [['evidences']],
              JAMAICAN_PREVIOUS_SPOUSE_DEATH_CERTIFICATE: [['evidences']],
              IDENTITY_COPY_PROOF: [['evidences']],
              JAMAICAN_SPOUSE_PHOTOGRAPH: [['evidences']],
              NATUARALIZATION_CERTIFICATE: [['evidences']],
              JAMAICAN_BIRTH_CERTIFICATE: [['evidences']],
            },
          },
        },
      },
    },
  ],MARRIAGE_EXEMPTION : [
    {
      type: 'dynamicFields',
      compProps: {
        id: 'evidentInfo',
        cardType: 'Document',
        label: 'Document Info',
        required: true,
        attributeForm: {
          modalTitle: 'Add Document Info',
          editTitle: 'Edit document Info',
          fields:  [
            {
              type: 'fileDropZone',
              compProps: {
                id: 'evidences',
                label: 'Documents',
                required: true,
                supportedFormat: [
                  'image/png',
                  'application/pdf',
                  'image/jpeg',
                  'image/jpg',
                ],
                maxFiles: 1,
              },
            },
            {
              type: 'fileDropZone',
              compProps: {
                label: 'Documents',
                id: 'photo',

                maxFiles: 1,
                supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
                required: true,
              },
            },
            {
              type: 'select',
              compProps: {
                label: 'Document Type',
                id: 'evidencetype',
                required: true,
                options: [
                  {
                    label: 'Financial Statement Proof',
                    id: 'FINANCIAL_STATEMENT_PROOF',
                  },
                  {
                    label: 'Passport Proof',
                    id: 'PASSPORT_PROOF',
                  },
                  {
                    label: 'Birth Certificate Proof',
                    id: 'BIRTH_CERTIFICATE_PROOF',
                  },
                  {
                    label: 'Divorce Certificate Proof',
                    id: 'DIVORCE_CERTIFICATE_PROOF',
                  },
                  {
                    label: 'Applicant Photograph',
                    id: 'APPLICANT_PHOTOGRAPH',
                  },
                  {
                    label: 'Previous Spouse Death Certificate',
                    id: 'PREVIOUS_SPOUSE_DEATH_CERTIFICATE',
                  },
                  {
                    label: 'Jamaican Divorce Certificate',
                    id: 'JAMAICAN_DIVORCE_CERTIFICATE',
                  },
                  {
                    label: 'Jamaican Previous Spouse Death Certificate',
                    id: 'JAMAICAN_PREVIOUS_SPOUSE_DEATH_CERTIFICATE',
                  },
                  {
                    label: 'Identity Copy Proof',
                    id: 'IDENTITY_COPY_PROOF',
                  },
                  {
                    label: 'Jamaican Spouse Photograph',
                    id: 'JAMAICAN_SPOUSE_PHOTOGRAPH',
                  },
                  {
                    label: 'Naturalization Certificate',
                    id: 'NATUARALIZATION_CERTIFICATE',
                  },
                  {
                    label: 'Jamaican Birth Certificate',
                    id: 'JAMAICAN_BIRTH_CERTIFICATE',
                  },
                  {
                    label: 'Marriage Certificate Proof',
                    id: 'MARRIAGE_CERTIFICATE_PROOF',
                  },
                ],
              },
            },
          ],
            
          
          layout: [['evidencetype']],
          dynamicLayout: {
            evidencetype: {
              BIRTH_CERTIFICATE_PROOF: [['evidences']],
              NAIONALITY_PROOF: [['evidences']],
              OCCUPATION_PROOF: [['evidences']],
              PASSPORT_PROOF: [['evidences']],
              QUALIFICATION_PROOF: [['evidences']],
              WORK_EXPERIENCE_PROOF: [['evidences']],
              PHOTOGRAPH: [['photo']],
              POLICE_CLEARANCE_CERTIFICATE: [['evidences']],
              RESUME_PROOF: [['evidences']],
              COVERING_LETTER_PROOF: [['evidences']],
              PREVIOUS_EMPLOYER_PROOF: [['evidences']],
              PREVIOUS_EMPLOYER_PROOF_DEP: [['evidences']],
              JOB_TITLE_PROOF: [['evidences']],
              TAX_COMPILANCE: [['evidences']],
              BY_ADVERTISEMENT_FILE: [['evidences']],
              STEP_TAKEN_PROOF: [['evidences']],
              APPLICANT_SIGNATURE: [['evidences']],
              EMPLOYER_SIGNATURE: [['evidences']],
              CANDIDATE_RESUME: [['evidences']],
              ACADEMIC_CERTIFICATE_PROOF: [['evidences']],
              MARRIAGE_CERTIFICATE_PROOF: [['evidences']],
              DIVORCE_CERTIFICATE_PROOF: [['evidences']],
              ADOPTION_CERTIFICATE_PROOF: [['evidences']],
              NAME_CHANGE_PROOF: [['evidences']],
              CERTIFIED_COPY_OF_BIRTH_CERTIFICATE: [['evidences']],
              BIODATA_PAGE_COPY: [['evidences']],
              DEED_POLL_COPY: [['evidences']],
              FINANCIAL_STATEMENT_PROOF: [['evidences']],
              APPLICANT_PHOTOGRAPH: [['evidences']],
              PREVIOUS_SPOUSE_DEATH_CERTIFICATE: [['evidences']],
              JAMAICAN_DIVORCE_CERTIFICATE: [['evidences']],
              JAMAICAN_PREVIOUS_SPOUSE_DEATH_CERTIFICATE: [['evidences']],
              IDENTITY_COPY_PROOF: [['evidences']],
              JAMAICAN_SPOUSE_PHOTOGRAPH: [['evidences']],
              NATUARALIZATION_CERTIFICATE: [['evidences']],
              JAMAICAN_BIRTH_CERTIFICATE: [['evidences']],
              CSME_COPY: [['evidences']],
              CSME_CERTIFICATE: [['evidences']],
              PASSPORT_SIZE_PHOTO: [['evidences']],
              EMPLOYMENT_NO_OBJECTION_CERTIFICATE: [['evidences']],
              GRADUATE_QUALIFICATION_CERTIFICATE: [['evidences']],
              NURSE_QUALIFICATION_CERTIFICATE: [['evidences']],
              ASSOCIATE_DEGREE_QUALIFICATION_CERTIFICATE: [['evidences']],
              TEACHER_QUALIFICATION_CERTIFICATE: [['evidences']],
            },
          },
        },
      },
    },
  ],CSME : [
    {
      type: 'dynamicFields',
      compProps: {
        id: 'evidentInfo',
        cardType: 'Document',
        label: 'Document Info',
        required: true,
        attributeForm: {
          modalTitle: 'Add Document Info',
          editTitle: 'Edit document Info',
          fields:  [
            {
              type: 'fileDropZone',
              compProps: {
                id: 'evidences',
                label: 'Documents',
                required: true,
                supportedFormat: [
                  'image/png',
                  'application/pdf',
                  'image/jpeg',
                  'image/jpg',
                ],
                maxFiles: 1,
              },
            },
            {
              type: 'fileDropZone',
              compProps: {
                label: 'Documents',
                id: 'photo',

                maxFiles: 1,
                supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
                required: true,
              },
            },
            {
              type: 'select',
              compProps: {
                label: 'Document Type',
                id: 'evidencetype',
                required: true,
                options: [
                  {
                    label: 'CSME Copy',
                    id: 'CSME_COPY',
                  },
                  {
                    label: 'Job Title Proof',
                    id: 'JOB_TITLE_PROOF',
                  },
                  {
                    label: 'Tax Compilance',
                    id: 'TAX_COMPILANCE',
                  },
                  {
                    label: 'By Advertisement File',
                    id: 'BY_ADVERTISEMENT_FILE',
                  },
                  {
                    label: 'Step Taken Proof',
                    id: 'STEP_TAKEN_PROOF',
                  },
                  {
                    label: 'Employer Signature',
                    id: 'EMPLOYER_SIGNATURE',
                  },
                  {
                    label: 'Birth Certificate Proof',
                    id: 'BIRTH_CERTIFICATE_PROOF',
                  },
                  {
                    label: 'Naionality Proof',
                    id: 'NAIONALITY_PROOF',
                  },
                  {
                    label: 'Occupation Proof',
                    id: 'OCCUPATION_PROOF',
                  },
                  {
                    label: 'Passport Proof',
                    id: 'PASSPORT_PROOF',
                  },
                  {
                    label: 'Qualification Proof',
                    id: 'QUALIFICATION_PROOF',
                  },
                  {
                    label: 'Work Experience Proof',
                    id: 'WORK_EXPERIENCE_PROOF',
                  },
                  {
                    label: 'Photograph',
                    id: 'PHOTOGRAPH',
                  },
                  {
                    label: 'Police Clearance Certificate',
                    id: 'POLICE_CLEARANCE_CERTIFICATE',
                  },
                  {
                    label: 'Resume Proof',
                    id: 'RESUME_PROOF',
                  },
                  {
                    label: 'Covering Letter Proof',
                    id: 'COVERING_LETTER_PROOF',
                  },
                  {
                    label: 'Previous Employer Proof',
                    id: 'PREVIOUS_EMPLOYER_PROOF',
                  },
                  {
                    label: 'Applicant Signature',
                    id: 'APPLICANT_SIGNATURE',
                  },
                  {
                    label: 'Previous Employer Proof Dep',
                    id: 'PREVIOUS_EMPLOYER_PROOF_DEP',
                  },
                  {
                    label: 'Candidate Resume',
                    id: 'CANDIDATE_RESUME',
                  },
                  {
                    label: 'Adoption Certificate Proof',
                    id: 'ADOPTION_CERTIFICATE_PROOF',
                  },
                  {
                    label: 'Divorce Certificate Proof',
                    id: 'DIVORCE_CERTIFICATE_PROOF',
                  },
                  {
                    label: 'Marriage Certificate Proof',
                    id: 'MARRIAGE_CERTIFICATE_PROOF',
                  },
                  {
                    label: 'Academic Certificate Proof',
                    id: 'ACADEMIC_CERTIFICATE_PROOF',
                  },
                  {
                    label: 'Name Change Proof',
                    id: 'NAME_CHANGE_PROOF',
                  },
                  {
                    label: 'Certified Copy of Birth Certificate',
                    id: 'CERTIFIED_COPY_OF_BIRTH_CERTIFICATE',
                  },
                  {
                    label: 'Biodata Page Copy',
                    id: 'BIODATA_PAGE_COPY',
                  },
                  {
                    label: 'Deed Poll Copy',
                    id: 'DEED_POLL_COPY',
                  },
                  {
                    label: 'CSME Certificate',
                    id: 'CSME_CERTIFICATE',
                  },
                  {
                    label: 'Passport Size Photo',
                    id: 'PASSPORT_SIZE_PHOTO',
                  },
                  {
                    label: 'Employment No Objection Certificate (Gender)',
                    id: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
                  },
                  {
                    label: 'Employment No Objection Certificate (Sports)',
                    id: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
                  },
                  {
                    label: 'Employment No Objection Certificate (Music)',
                    id: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
                  },
                  {
                    label: 'Employment No Objection Certificate (Media)',
                    id: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
                  },
                  {
                    label: 'Graduate Qualification Certificate',
                    id: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
                  },
                  {
                    label: 'Nurse Qualification Certificate',
                    id: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
                  },
                  {
                    label: 'Associate Degree Qualification Certificate',
                    id: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
                  },
                  {
                    label: 'Teacher Qualification Certificate',
                    id: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
                  },
                  
                ],
              },
            },
          ],
            
          
          layout: [['evidencetype']],
          dynamicLayout: {
            evidencetype: {
              BIRTH_CERTIFICATE_PROOF: [['evidences']],
              NAIONALITY_PROOF: [['evidences']],
              OCCUPATION_PROOF: [['evidences']],
              PASSPORT_PROOF: [['evidences']],
              QUALIFICATION_PROOF: [['evidences']],
              WORK_EXPERIENCE_PROOF: [['evidences']],
              PHOTOGRAPH: [['photo']],
              POLICE_CLEARANCE_CERTIFICATE: [['evidences']],
              RESUME_PROOF: [['evidences']],
              COVERING_LETTER_PROOF: [['evidences']],
              PREVIOUS_EMPLOYER_PROOF: [['evidences']],
              PREVIOUS_EMPLOYER_PROOF_DEP: [['evidences']],
              JOB_TITLE_PROOF: [['evidences']],
              TAX_COMPILANCE: [['evidences']],
              BY_ADVERTISEMENT_FILE: [['evidences']],
              STEP_TAKEN_PROOF: [['evidences']],
              APPLICANT_SIGNATURE: [['evidences']],
              EMPLOYER_SIGNATURE: [['evidences']],
              CANDIDATE_RESUME: [['evidences']],
              ACADEMIC_CERTIFICATE_PROOF: [['evidences']],
              MARRIAGE_CERTIFICATE_PROOF: [['evidences']],
              DIVORCE_CERTIFICATE_PROOF: [['evidences']],
              ADOPTION_CERTIFICATE_PROOF: [['evidences']],
              NAME_CHANGE_PROOF: [['evidences']],
              CERTIFIED_COPY_OF_BIRTH_CERTIFICATE: [['evidences']],
              BIODATA_PAGE_COPY: [['evidences']],
              DEED_POLL_COPY: [['evidences']],
              FINANCIAL_STATEMENT_PROOF: [['evidences']],
              APPLICANT_PHOTOGRAPH: [['evidences']],
              PREVIOUS_SPOUSE_DEATH_CERTIFICATE: [['evidences']],
              JAMAICAN_DIVORCE_CERTIFICATE: [['evidences']],
              JAMAICAN_PREVIOUS_SPOUSE_DEATH_CERTIFICATE: [['evidences']],
              IDENTITY_COPY_PROOF: [['evidences']],
              JAMAICAN_SPOUSE_PHOTOGRAPH: [['evidences']],
              NATUARALIZATION_CERTIFICATE: [['evidences']],
              JAMAICAN_BIRTH_CERTIFICATE: [['evidences']],
              CSME_COPY: [['evidences']],
              CSME_CERTIFICATE: [['evidences']],
              PASSPORT_SIZE_PHOTO: [['evidences']],
              EMPLOYMENT_NO_OBJECTION_CERTIFICATE: [['evidences']],
              GRADUATE_QUALIFICATION_CERTIFICATE: [['evidences']],
              NURSE_QUALIFICATION_CERTIFICATE: [['evidences']],
              ASSOCIATE_DEGREE_QUALIFICATION_CERTIFICATE: [['evidences']],
              TEACHER_QUALIFICATION_CERTIFICATE: [['evidences']],
              
            },
          },
        },
      },
    },
  ]},
  layout: [['comments'], ['evidentInfo']],
};

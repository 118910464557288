import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
  Image,
} from 'react-bootstrap';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  UserRemoveIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import { StorageModule } from '@vlinder-web/storage-module-react';
import { USER_LEVEL } from '@/config/userManagement.config';

export interface ToggleActionProps {
  status: string;
  onTogglePress?(key: string): void;
}

export const ToggleAction = (props: ToggleActionProps) => {
  const { status, onTogglePress } = props;
  const storageSrv = StorageModule?.getInstance();
  const userProfile = storageSrv.get('myProfile', 'session');
  const userRole = userProfile?.role?.find((item: any) =>
    USER_LEVEL.owner.includes(item.name)
  );

  const onToggleClick = (key: string) => {
    onTogglePress && onTogglePress(key);
  };
  const _getToggleItems = () => {
    if (status === 'invited' || status === 'User Invited') {
      return (
        <>
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('view')}
          >
            <EyeIcon className="dropdown-icon text-gray-400 me-2" />
            View Profile
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('re-invite')}
          >
            <CheckCircleIcon className="dropdown-icon text-gray-400 me-2" />
            Reinvite
          </Dropdown.Item>
        </>
      );
    } else if (
      status === 'active' &&
      USER_LEVEL.owner.includes(userRole?.name)
    ) {
      return (
        <>
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('view')}
          >
            <EyeIcon className="dropdown-icon text-gray-400 me-2" />
            View Profile
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('suspend')}
          >
            <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
            Suspend
          </Dropdown.Item>
          {/* <Dropdown.Item
          className="d-flex align-items-center"
          onClick={() => onToggleClick('remove_admin_access')}
        >
          <UserRemoveIcon className="dropdown-icon text-gray-400 me-2" />
          Remove admin access
        </Dropdown.Item> */}
        </>
      );
    } else if (
      status === 'suspended' &&
      USER_LEVEL.owner.includes(userRole?.name)
    ) {
      return (
        <>
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('view')}
          >
            <EyeIcon className="dropdown-icon text-gray-400 me-2" />
            View Profile
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('activate')}
          >
            <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
            Activate
          </Dropdown.Item>
        </>
      );
    } else {
      return (
        <>
          {/* <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('re-invite')}
          >
            <CheckCircleIcon className="dropdown-icon text-gray-400 me-2" />
            Reinvite
          </Dropdown.Item> */}
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('view')}
          >
            <EyeIcon className="dropdown-icon text-gray-400 me-2" />
            View Profile
          </Dropdown.Item>
          {/* <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('suspend')}
          >
            <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
            Suspend
          </Dropdown.Item> */}
        </>
      );
    }
  };

  return (
    <Dropdown as={ButtonGroup}>
      <Dropdown.Toggle
        as={Button}
        split
        variant="link"
        className="text-dark m-0 p-0"
      >
        <DotsHorizontalIcon className="icon icon-xs" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
        {_getToggleItems()}
      </Dropdown.Menu>
    </Dropdown>
  );
};

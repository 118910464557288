import { vappContainer } from '@/application';
import { ResetPasswordPageService } from '../../../services';
import { StorageModule } from '@vlinder-web/storage-module-react';

const MODULE_NAME = 'profile.machines.states.resetPassword';

const getSrv = () => {
  return vappContainer.get<ResetPasswordPageService>(
    ResetPasswordPageService.displayName
  );
};

export const resetPassword = async (context: any, event: any) => {
  const currentState = 'RESET_PASSWORD';
  const method = 'resetPassword';
  const storageSrv = StorageModule.getInstance();

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const content = await srv.agent.resetPassword(event?.payload);
      if (content) {
        return resolve({
          key: 'RESET_PASSWORD_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = content?.error;
        return reject({
          key: 'RESET_PASSWORD_FAILED',
          value: msg?.display_message,
        });
      }
    } catch (err: any) {
      return reject({
        key: 'RESET_PASSWORD_FAILED',
        value: err?.error?.display_message ?? err?.message,
      });
    }
  });
};

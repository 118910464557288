import gql from 'graphql-tag';
export const RESUBMIT_APPLICATION = gql`
  mutation ReSubmitApplication($input: SubmitAppInput) {
    reSubmitApplication(input: $input) {
      type
      updatedAt
      status
      applicationNo
      applicantInfo {
        email
        firstName
      }
    }
  }
`;

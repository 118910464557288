import { useState, useMemo, useCallback } from 'react';
import clsx from 'clsx';

import useToggle from '../../hooks/useToggle';

export const useSelect = <Category = string>({
    placeholder,
    initialItems,
    getNestedItems,
    hasNestedItems,
    onChange,
    getItemLabel,
    isEqual,
}: UseSelectProps<Category>) => {
    const [open, toggle] = useToggle();
    const [selectedItem, setSelectedItem] = useState<Category>();

    /**
     * Rendering items by level
     * {
     *    1: Category[],
     *    2: Category[],
     *    ....so on
     * }
     */
    const [renderingItems, setRenderingItems] = useState<
        Record<number, Category[]>
    >({
        1: initialItems,
    });

    /**
     * Selected item per level
     * {
     *   1: Category,
     *   2: Category,
     *   .... so on
     * }
     */
    const [selectedItems, setSelectedItems] =
        useState<Record<number, Category>>();

    const label = useMemo(() => {
        if (!selectedItem) return placeholder;
        return getItemLabel(selectedItem);
    }, [getItemLabel, placeholder, selectedItem]);

    const handleGetNestedItems = useCallback(
        async (item: Category, level: number) => {
            const nestedItems = await getNestedItems(item, level);

            // Update rendering items at $level
            if (nestedItems.length) {
                setRenderingItems((prev) => ({
                    ...prev,
                    [level]: nestedItems,
                }));
            }
        },
        [getNestedItems]
    );

    const isSelectedItem = useCallback(
        (item: Category, level: number) => {
            const selected =
                selectedItems?.[level] && isEqual(item, selectedItems[level]);
            return !!selected;
        },
        [isEqual, selectedItems]
    );

    const handleClickItem = useCallback(
        (item: Category, level: number) => () => {
            // Remove all items from $level++
            setRenderingItems((prev) =>
                Object.entries(prev || {}).reduce(
                    (
                        updatedrenderingItems,
                        [currentLevel, currentLevelItems]
                    ) => {
                        if (+currentLevel > level) return updatedrenderingItems;
                        return {
                            ...updatedrenderingItems,
                            [+currentLevel]: currentLevelItems,
                        };
                    },
                    {}
                )
            );

            // Update selected items
            setSelectedItems((prev) => ({ ...prev, [level]: item }));

            if (hasNestedItems(item, level)) {
                // Fetch $level + 1 items
                handleGetNestedItems(item, level + 1);
            } else {
                // Select $item and close dropdown
                setSelectedItem(item);
                onChange?.(item);
                toggle();
            }
        },
        [handleGetNestedItems, hasNestedItems, onChange, toggle]
    );

    return {
        open,
        label,
        renderingItems,
        toggle,
        handleClickItem,
        isSelectedItem,
    };
};

export const useClassNames = (
    classes?: Partial<Record<ClassName, string>>
) => ({
    root: () => clsx('selection-root', classes?.root),
    overlay: (open: boolean) =>
        clsx('overlay', classes?.overlay, { hidden: !open }),
    selectionHeader: () =>
        clsx(
            'select-wrapper',
            'selection-header',
            'me-2',
            classes?.selectionHeader
        ),
    selectionEntries: (open: boolean) =>
        clsx('selection-entries', classes?.selectionEntries, {
            hidden: !open,
        }),
    levelEntry: () => clsx('entry-level', classes?.levelEntry),
    levelItem: ({
        nestable,
        selected,
    }: {
        nestable: boolean;
        selected: boolean;
    }) =>
        clsx('entry-item', classes?.levelItem, {
            'entry-item__nestable': nestable,
            'entry-item__selected': selected,
            [classes?.levelSelectedItem ?? '']: selected,
        }),
});

const classNames = [
    'root',
    'overlay',
    'selectionHeader',
    'selectionEntries',
    'levelEntry',
    'levelItem',
    'levelSelectedItem',
] as const;

export type ClassName = (typeof classNames)[number];
export interface UseSelectProps<Category> {
    initialItems: Category[]; // Initial items (Level 1 items)
    placeholder: string; // Placeholder show on no item selected
    getItemLabel: (item: Category) => string;
    getNestedItems: (
        item: Category,
        level: number
    ) => Promise<Category[]> | Category[]; // Get the nested level of current item & level
    hasNestedItems: (item: Category, level: number) => boolean; // Check if the current item at level still has nested level items
    isEqual: (item?: Category, item2?: Category) => boolean;
    onChange?: (item: Category) => void;
}

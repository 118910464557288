export const newUserConfig = {
  FIELDS: [
    {
      type: 'text',
      compProps: {
        id: 'orgName',
        label: 'Organization Name',
        placeholder: 'Enter Organization name',
        required: true,
        errorText: 'Organization name is required',
      },
    },
    {
      type: 'email',
      compProps: {
        id: 'email',
        label: 'Email',
        required: true,

        placeholder: 'Enter email',
        errorText: 'Email is required',
      },
    },
  ],
  layout: [['orgName'], ['email']],
};

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TweetyWidget } from '@/widgets/tweety-widget-react-main';
export const DocRejectedForm = ({
  showModal,
  formConfig,
  onSubmit,
  onHideClick,
  ModalTitle,
  type
}: any) => {
  const { FIELDS, layout, dynamicLayout } = formConfig;

  console.log('staticApplicationContentConfig', FIELDS, layout);
    // const fields = FIELDS[type ? type : 'WORK_PERMIT']
    const fields = FIELDS[type ? type : 'WORK_PERMIT']
    console.log("fields inside doc rejeected comp",fields)
  return (
    <>
      <Modal size="lg" show={showModal} onHide={onHideClick}>
        <Modal.Header closeButton>
          <Modal.Title>{ModalTitle ?? ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TweetyWidget
            labelColor={'#000000'}
            fields={fields}
            layout={layout}
            dynamicLayout={dynamicLayout}
            onFormSubmit={onSubmit}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

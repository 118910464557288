//@ts-nocheck

import React from 'react';
import { Accordion, Image } from 'react-bootstrap';
import { IOfferProps, OfferComponent } from '../OfferComponent';
import * as S from './styles';

export interface IHeaderCompProps {
  rightBlockContent: {
    title?: string;
    description?: string;
    image?: string;
  }[];
  offer: boolean;
  offerData?: IOfferProps;
}

export const RightContainer = (props: IHeaderCompProps) => {
  const { rightBlockContent, offer, offerData } = props;

  const _renderImageIf = (image: string) => {
    if (image) {
      return <Image src={image} />;
    }
  };

  const _getOffer = () => {
    if (offer) {
      return <OfferComponent {...offerData} />;
    }
  };
  const _renderDescriptionIf = (description: string) => {
    if (
      description &&
      !Array.isArray(description) &&
      typeof (description === 'string')
    ) {
      return <p>{description}</p>;
    }
    if (Array.isArray(description)) {
      return (
        <>
          {description?.map((str, index) => {
            return <p key={`desc-${index}`}>{str}</p>;
          })}
        </>
      );
    }
  };
  const _getAccordionData = () => {
    let _tempData =
      Array.isArray(rightBlockContent) &&
      rightBlockContent?.map((item: any, index: number) => {
        return (
          <Accordion.Item eventKey={`acc-${index}`} key={`acc-${index}`}>
            <Accordion.Header>
              <S.AccHeader>{item?.title}</S.AccHeader>
            </Accordion.Header>
            <Accordion.Body>
              {_renderDescriptionIf(item?.description)}
              {_renderImageIf(item?.image)}
            </Accordion.Body>
          </Accordion.Item>
        );
      });
    return <Accordion defaultActiveKey="acc-0">{_tempData}</Accordion>;
  };

  return (
    <S.ColContainer>
      {_getOffer()}
      {_getAccordionData()}
    </S.ColContainer>
  );
};
RightContainer.defaultProps = {
  rightBlockContent: [],
  offer: false,
};

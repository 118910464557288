import styled from 'styled-components';

import sytled from 'styled-components';
export const Img = styled.img`
  width: 20px;
  margin-right: 5px;
`;

export const UserFormTitleStyle = {
  fontSize: '25px',
  fontWeight: 'bold',
};

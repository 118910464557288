export const rightEstablishmentConfig = {
  formLabel: 'Rights of Establishment',
  previewLabel: '',
  successIcon: 'https://i.ibb.co/RNvQN2X/success.png',
  errorIcon: 'https://i.ibb.co/GM7nsgp/icons8-error-48.png',
  fields: [
    [
      {
        type: 'radio',
        compProps: {
          label: 'Do you have a Last Name or Surname?',
          id: 'isLastNameExist',
          direction: 'horizontal',
          required: true,
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Last Name',
          id: 'lastName',
          placeholder: 'Enter Last Name(no special characters, no spaces)',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'First Name (Given Name)',
          id: 'firstName',
          placeholder: 'Enter First Name(no special characters, no spaces)',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Middle Name',
          id: 'middleInitial',
          placeholder: 'Enter Middle Name',
          required: false,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Street Address in Home Country 1',
          rows: 1,
          id: 'streetAddress1',
          placeholder: 'Street Address in Home Country 1',
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Street Address in Home Country 2',
          rows: 1,
          id: 'streetAddress2',
          placeholder: 'Street Address in Home Country 2',
          required: false,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City',
          id: 'city',
          placeholder: 'Select City',
          required: true,
          options: [
            {
              id: 'Antigua and Barbuda',
              label: 'Antigua and Barbuda',
            },
            {
              id: 'Barbados',
              label: 'Barbados',
            },
            {
              id: 'Belize',
              label: 'Belize',
            },
            {
              id: 'Dominica',
              label: 'Dominica',
            },
            {
              id: 'Grenada',
              label: 'Grenada',
            },
            {
              id: 'Jamaica',
              label: 'Jamaica',
            },
            {
              id: 'St. Kitts-Nevis-Anguilla',
              label: 'St. Kitts-Nevis-Anguilla',
            },
            {
              id: 'St. Lucia',
              label: 'St. Lucia',
            },
            {
              id: 'St. Vincent and the Grenadines',
              label: 'St. Vincent and the Grenadines',
            },
            {
              id: 'Suriname',
              label: 'Suriname',
            },
            {
              id: 'Trinidad and Tobago',
              label: 'Trinidad and Tobago',
            },
          ],
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Place of Birth',
          id: 'placeOfBirth',
          placeholder: 'Enter Place of Birth',
          required: true,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country of Birth',
          id: 'countryOfBirth',
          placeholder: 'Enter Country of Birth',
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Intended Street Address in Jamaica 1',
          rows: 1,
          id: 'intendedStreetAddress1',
          placeholder: 'Intended Street Address in Jamaica 1',
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Intended Street Address in Jamaica 2',
          rows: 1,
          id: 'intendedStreetAddress2',
          placeholder: 'Intended Street Address in Jamaica 2',
          required: false,
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone Contact',
          id: 'telephoneContact',
          placeholder: 'Enter Telephone Contact',
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'userEmail',
          placeholder: 'Enter Email address',
          required: true,
          // disabled: true,
          // readOnly: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Sex',
          id: 'gender',
          direction: 'horizontal',
          required: true,
          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'datepicker',
        compProps: {
          id: 'dateOfBirth',
          label: 'Date of Birth',
          required: true,
          isAdult: true,
        },
      },

      {
        type: 'countrySelect',
        compProps: {
          label: 'Nationality',
          id: 'nationality',
          placeholder: 'Enter Nationality',
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'TRN',
          id: 'trn',
          placeholder: 'Enter your TRN , should not contain any special characters ex: 123456789',
          required: false,
          // disabled:true
        },
      },

      {
        type: 'textarea',
        compProps: {
          label: 'Inteded address in Jamaica',
          rows: 3,
          id: 'jamaicanAddress',
          placeholder: 'Enter inteded address in Jamaica',
          required: false,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Occupation',
          id: 'occupation',
          options: [
            {
              label: 'Occupation 1',
              id: 'Occupation1',
            },
            {
              label: 'Occupation 2',
              id: 'Occupation2',
            },
            {
              label: 'Occupation 3',
              id: 'Occupation3',
            },
          ],
          required: false,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passportNumber',
          placeholder: 'Enter Passport Number(no special characters, no spaces)',
          required: false,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Issue Date',
          id: 'passportIssuedOn',
          required: false,
          isFuture: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Expiry Date',
          id: 'passportExpiryOn',
          required: false,
          isPassport: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Marital Status',
          id: 'maritalStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Single',
              id: 'single',
            },
            {
              label: 'Divorced',
              id: 'divorced',
            },
            {
              label: 'Widowed',
              id: 'widowed',
            },
            {
              label: 'Married',
              id: 'married',
            },
            {
              label: 'Separated',
              id: 'separated',
            },
          ],
          required: false,
        },
      },

      {
        type: 'textarea',
        compProps: {
          rows: 1,
          label: 'Job Title/Duties to be performed',
          id: 'jobTitle',
          placeholder: 'Job Title/Duties to be performed',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Number of Years with Company',
          id: 'noOfYearsWithCompany',
          placeholder: 'Number of Years with Company',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Qualification/Certificate',
          id: 'qualificationName',
          placeholder: 'Qualification/Certificate',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          id: 'qualificationProof',
          label: 'Proof of Qualification/Certicate',
          maxFiles: 5,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Occupational Skill',
          id: 'occupationalSkill',
          placeholder: 'Occupational Skill',
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passport',
          placeholder: 'Enter Passport Number',
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Expiry',
          id: 'passportExpiry',
          required: true,
          isPassport: true,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Place of Issue',
          id: 'typeOfPassport',
          placeholder: 'Enter Type of Passport (Country Issued)',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Biodata Page of Passport',
          id: 'passportProof',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Police Report from Country',
          id: 'policeClearanceCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Passport size photograph',
          id: 'photograph',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Birth Certificate Copy',
          id: 'birthCertificateCopy',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Husband/wife name',
          id: 'husbandOrWifeName',
          placeholder: 'Enter Husband/wife name',
          required: false,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Husband/wife nationality',
          id: 'husbandOrWifeNationality',
          placeholder: 'Enter Husband/wife nationality',
          required: false,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Do you have any dependents',
          id: 'isDependentExist',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: false,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'In case of Dependents, How many dependents?',
          id: 'noOfChildren',
          placeholder: 'Enter number of Dependents',
          required: false,
        },
      },
      {
        type: 'dynamicFields',
        compProps: {
          id: 'dependentInfo',
          cardType: 'Dependent',
          label: 'Dependents Info',
          lookUpField: 'noOfChildren',
          required: false,
          errorText: 'Please enter all Children / Dependent info',
          attributeForm: {
            modalTitle: 'Add Dependent Info',
            editTitle: 'Edit Dependent Info',
            fields: [
              {
                type: 'text',
                compProps: {
                  label: 'Full Name',
                  id: 'dependentName',
                  placeholder: 'Enter Full Name',
                  required: false,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Maiden Name',
                  id: 'maidenName',
                  placeholder: 'Enter Maiden Name',
                  required: false,
                },
              },
              {
                type: 'number',
                compProps: {
                  id: 'dependentAge',
                  placeholder: 'Age',
                  label: 'Age',
                  required: false,
                },
              },
              {
                type: 'radio',
                compProps: {
                  id: 'dependentSex',
                  direction: 'horizontal',
                  label: 'Sex',
                  value: 'Male',
                  options: [
                    {
                      id: 'Male',
                      label: 'Male',
                    },
                    {
                      id: 'Female',
                      label: 'Female',
                    },
                  ],
                  required: false,
                },
              },
              {
                type: 'countrySelect',
                compProps: {
                  label: 'Nationality',
                  id: 'dependentNationality',
                  placeholder: 'Enter Nationality',
                  required: false,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Passport Number',
                  id: 'dependentPassport',
                  placeholder: 'Enter Passport Number',
                  required: false,
                },
              },
              {
                type: 'select',
                compProps: {
                  label: 'Relationship to Applicant',
                  id: 'dependentRelationship',
                  options: [
                    {
                      label: 'Minor Child',
                      id: 'MINOR_CHILD',
                    },
                    {
                      label: 'Spouse ',
                      id: 'SPOUSE',
                    },
                    {
                      label: 'Other',
                      id: 'OTHER',
                    },
                  ],
                  required: false,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Certified Copy of Birth Certificate',
                  id: 'certifyBirth',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  required: false,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Bio-Data Page of Valid Passport',
                  id: 'passportProof',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  required: false,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Proof of Adoption (Where necessary)',
                  id: 'adoption',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  // required: false,
                },
              },
            ],
            layout: [
              ['dependentName'],
              ['dependentAge'],
              ['dependentSex'],
              ['dependentNationality'],
              ['dependentPassport'],
              ['dependentRelationship'],
            ],
            dynamicLayout: {
              dependentRelationship: {
                MINOR_CHILD: [
                  ['certifyBirth'],
                  ['passportProof'],
                  ['adoption'],
                ],
              },
            },
            validatorConfig: {
              ruleSets: [
                {
                  fields: {
                    dependentName: [
                      {
                        type: 'matches',
                        pattern: '^[A-Za-z ]+$',
                        errorMessage: 'Name is invalid.',
                      },
                    ],
                    passportNumber: [
                      {
                        type: 'matches',
                        pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
                        errorMessage: 'Passport is not valid',
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    ],
  ],
  layout: [
    {
      first: {
        label: 'Employee Information',
        data: [
          ['isLastNameExist'],
          ['firstName', 'middleInitial'],
          ['streetAddress1'],
          ['streetAddress2'],
          ['city', 'placeOfBirth'],
          ['intendedStreetAddress1'],
          ['intendedStreetAddress2'],
          ['telephoneContact', 'userEmail'],
          ['gender', 'dateOfBirth'],
          ['countryOfBirth', 'nationality'],
          ['maritalStatus'],
        ],
      },
      second: {
        label: 'Employment  Information',
        data: [
          ['jobTitle'],
          ['noOfYearsWithCompany'],
          ['qualificationName'],
          ['qualificationProof'],
          ['occupationalSkill', 'passport'],
          ['passportExpiry', 'typeOfPassport'],
        ],
      },
      third: {
        label: 'Documents',
        data: [
          ['policeClearanceCertificate'],
          ['photograph'],
          ['birthCertificateCopy'],
          ['passportProof'],
        ],
      },
    },
  ],
  // dynamicLayout: {
  //   isLastNameExist: {
  //     yes: [['lastName']],
  //   },
  //   isDependentExist: {
  //     yes: [['noOfChildren'], ['dependentInfo']],
  //   },
  //   maritalStatus: {
  //     divorced: [['husbandOrWifeName', 'husbandOrWifeNationality']],
  //     widowed: [['husbandOrWifeName', 'husbandOrWifeNationality']],
  //     married: [['husbandOrWifeName', 'husbandOrWifeNationality']],
  //     separated: [['husbandOrWifeName', 'husbandOrWifeNationality']],
  //   },
  // },
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          firstName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'Name is invalid.',
            },
          ],
          lastName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'lastname is not valid',
            },
          ],
          passportNumber: [
            {
              type: 'matches',
              pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
              errorMessage: 'Passport is not valid',
            },
          ],
        },
      },
    ],
  },
};

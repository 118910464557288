import gql from 'graphql-tag';
export const RESUBMIT_APPLICATION_BY_APPLICANT = gql`
  mutation ReSubmitApplicationByApplicant($input: SubmitAppInput) {
    reSubmitApplicationByApplicant(input: $input) {
      type
      updatedAt
      status
      applicationNo
      applicantInfo {
        email
        firstName
      }
    }
  }
`;

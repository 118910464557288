export const userListConfig = {
  bulkOptions: [
    {
      value: 'reset_password',
      label: 'Reset Password',
    },
    {
      value: 'delete_user',
      label: 'Delete User',
    },
    {
      value: 'suspend_user',
      label: 'Suspend User',
    },
    {
      value: 'invite_user',
      label: 'Invite User',
    },
  ],
  ultTableHeader: [
    'Marriage Certificate No.',
    'Action',
    'Name',
    'Email',
    // 'Employer',
    'Issued On',
    'Expiry Date',
    'Status',
  ],
  ultUsers: [
    {
      applicationId: 'CSME-32784389',
      name: 'Joe',
      email: 'jeo@gmail.com',
      validTill: '01/11/2023',
      validFrom: '27/10/23',
      status: 'Expiring Soon',
    },
  ],
  currentPage: 1,
  pageSize: 10,
  totalCount: 2,
  totalPages: 1,
  activePage: 1,
  role: 'admin',
};

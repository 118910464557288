export const editProfile = {
  FIELDS: [
    {
      type: 'text',
      compProps: {
        id: 'firstName',
        label: 'First Name',
        required: true,
        placeholder: 'Enter your First Name',
        errorText: 'First Name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'lastName',
        label: 'Last Name',
        required: true,
        placeholder: 'Enter your last Name',
        errorText: 'Last Name is required',
      },
    },
    {
      type: 'choosePhoto',
      compProps: {
        id: 'profileImage',
        label: 'Profile Image',
        required: true,
        errorText: 'Please choose Profile Image',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'address',
        label: 'Street Address',
        required: true,
        placeholder: 'Enter your Street Address',
        errorText: 'Street Address is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'city',
        label: 'City',
        required: true,
        placeholder: 'Enter your City',
        errorText: 'City is required',
      },
    },
    {
      type: 'countrySelect',
      compProps: {
        id: 'country',
        label: 'Country',
        required: true,
        errorText: 'Country is required',
        onlyCountries: [],
        placeholder: 'Select your Country',
      },
    },
    {
      type: 'phoneNumber',
      compProps: {
        id: 'telephone',
        label: 'Mobile Number',
        required: true,
        placeholder: 'Enter mobile Number',
        errorText: 'Mobile number is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'occupation',
        label: 'occupation',
        required: true,
        placeholder: 'Enter your Occupation',
        errorText: 'occupation is required',
      },
    },
  ],
  layout: [
    ['lastName', 'firstName'],
    ['profileImage'],
    ['address'],
    ['city'],
    ['country'],
    ['telephone'],
    ['occupation'],
  ],
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          telephone: [
            {
              type: 'REQUIRED',
              errorMessage: 'Phone Number is required',
            },
            {
              type: 'MINLENGTH',
              minlength: 8,
              errorMessage: 'Minimum length of Phone Number should be 8',
            },
          ],
        },
      },
    ],
  },
};

import rawCountries from './rawCountries';

function createCountryObject(countries: any, value: any) {
  const countryObject: any = {};
  countries.forEach((country: any) => {
    countryObject[country] = value;
  });
  return countryObject;
}

// Filter out Jamaica from rawCountries
const countriesOtherThanJamaica = rawCountries
  .filter((country:any) => country[0] !== 'Jamaica')
  .map((country:any) => country[0]);

const countryObject = createCountryObject(countriesOtherThanJamaica, [['countryNaturalizationCertificate']]);


export const roeworkPermitConfig = {
  nextStepLabel: 'Next',
  submitButtonText: 'Submit',
  previewLabel: '',
  userFormTitle: 'Enter Applicant Information',
  workPermitLabel: 'Work Permit Application - Rights of Establishment',
  workPermitRenewalLabel: 'Work Permit Renewal - Rights of Establishment',
  workPermitAppealLabel: 'Work Permit Application - Appeal',
  successIcon: 'https://i.ibb.co/RNvQN2X/success.png',
  errorIcon: 'https://i.ibb.co/GM7nsgp/icons8-error-48.png',
  workPermitSubLabel1: 'Enter Prospective Employee Details',
  workPermitSubLabel2: 'Enter Employer Details',
  acknowledgementText:
    'I certify to the best of my knowledge and belief, that the above information is correct and accept the responsibility for the support and repatriation expenses of the applicant and his family should the need arise.',
  progressBarSteps: [
    'Work Permit Application - Rights of Establishment',
    'Work Permit Application - Rights of Establishment',
    'Work Permit Application - New',
    'Work Permit Application - New',
    'Work Permit Application - New',
    'Acknowledgement',
  ],
  fields: [
    [
      {
        type: 'radio',
        compProps: {
          label: 'Do you have a last name or Surname?',
          id: 'isLastNameExist',
          direction: 'horizontal',
          required: true,
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Last Name',
          id: 'lastName',
          placeholder: 'Enter Last Name',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'First Name (Given Name)',
          id: 'firstName',
          placeholder: 'Enter First Name',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Middle Name',
          id: 'middleInitial',
          placeholder: 'Enter Middle Name',
          required: false,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Street Address in Home Country 1',
          rows: 1,
          id: 'streetAddress1',
          placeholder: 'Street Address in Home Country 1',
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Street Address in Home Country 2',
          rows: 1,
          id: 'streetAddress2',
          placeholder: 'Street Address in Home Country 2',
          required: false,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City',
          id: 'city',
          placeholder: 'Select City',
          required: true,
          options: [
            {
              id: 'Antigua and Barbuda',
              label: 'Antigua and Barbuda',
            },
            {
              id: 'Barbados',
              label: 'Barbados',
            },
            {
              id: 'Belize',
              label: 'Belize',
            },
            {
              id: 'Dominica',
              label: 'Dominica',
            },
            {
              id: 'Grenada',
              label: 'Grenada',
            },
            {
              id: 'Jamaica',
              label: 'Jamaica',
            },
            {
              id: 'St. Kitts-Nevis-Anguilla',
              label: 'St. Kitts-Nevis-Anguilla',
            },
            {
              id: 'St. Lucia',
              label: 'St. Lucia',
            },
            {
              id: 'St. Vincent and the Grenadines',
              label: 'St. Vincent and the Grenadines',
            },
            {
              id: 'Suriname',
              label: 'Suriname',
            },
            {
              id: 'Trinidad and Tobago',
              label: 'Trinidad and Tobago',
            },
          ],
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Place of Birth',
          id: 'placeOfBirth',
          placeholder: 'Enter Place of Birth',
          required: true,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country of Birth',
          id: 'countryOfBirth',
          placeholder: 'Enter Country of Birth',
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Intended Street Address in Jamaica 1',
          rows: 1,
          id: 'intendedStreetAddress1',
          placeholder: 'Intended Street Address in Jamaica 1',
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Intended Street Address in Jamaica 2',
          rows: 1,
          id: 'intendedStreetAddress2',
          placeholder: 'Intended Street Address in Jamaica 2',
          required: false,
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone Contact',
          id: 'telephoneContact',
          placeholder: 'Enter Telephone Contact',
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'userEmail',
          placeholder: 'Enter Email address',
          required: true,
          // disabled: true,
          // readOnly: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Sex',
          id: 'gender',
          direction: 'horizontal',
          required: true,
          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'datepicker',
        compProps: {
          id: 'dateOfBirth',
          label: 'Date of Birth',
          required: true,
          isAdult: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Naturalization Certificate',
          id: 'countryNaturalizationCertificate',
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
          maxFiles: 1,
        },
      },

      {
        type: 'countrySelect',
        compProps: {
          label: 'Nationality',
          id: 'nationality',
          placeholder: 'Enter Nationality',
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'TRN',
          id: 'trn',
          placeholder: 'Enter your TRN , should not contain any special characters ex: 123456789',
          required: false,
          // disabled:true
        },
      },

      {
        type: 'textarea',
        compProps: {
          label: 'Inteded address in Jamaica',
          rows: 3,
          id: 'jamaicanAddress',
          placeholder: 'Enter inteded address in Jamaica',
          required: true,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Occupation',
          id: 'occupation',
          options: [
            {
              label: 'Occupation 1',
              id: 'Occupation1',
            },
            {
              label: 'Occupation 2',
              id: 'Occupation2',
            },
            {
              label: 'Occupation 3',
              id: 'Occupation3',
            },
          ],
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passportNumber',
          placeholder: 'Enter Passport Number',
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Issue Date',
          id: 'passportIssuedOn',
          required: true,
          isFuture: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Expiry Date',
          id: 'passportExpiryOn',
          required: true,
          isPassport: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Marital Status',
          id: 'maritalStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Single',
              id: 'single',
            },
            {
              label: 'Divorced',
              id: 'divorced',
            },
            {
              label: 'Widowed',
              id: 'widowed',
            },
            {
              label: 'Married',
              id: 'married',
            },
            {
              label: 'Separated',
              id: 'separated',
            },
          ],
          required: true,
        },
      },

      {
        type: 'textarea',
        compProps: {
          rows: 1,
          label: 'Job Title/Duties to be performed',
          id: 'jobTitle',
          placeholder: 'Job Title/Duties to be performed',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Number of Years with Company',
          id: 'noOfYearsWithCompany',
          placeholder: 'Number of Years with Company',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Qualification/Certificate',
          id: 'qualificationName',
          placeholder: 'Qualification/Certificate',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          id: 'qualificationProof',
          label: 'Proof of Qualification/Certicate',
          maxFiles: 5,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Occupational Skill',
          id: 'occupationalSkill',
          placeholder: 'Occupational Skill',
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passport',
          placeholder: 'Enter Passport Number',
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Expiry',
          id: 'passportExpiry',
          required: true,
          isPassport: true,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Place of Issue',
          id: 'typeOfPassport',
          placeholder: 'Enter Type of Passport (Country Issued)',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Biodata Page of Passport',
          id: 'passportProof',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Police Report from Country',
          id: 'policeClearanceCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Passport size photograph',
          id: 'photograph',

          maxFiles: 1,
          supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Birth Certificate Copy',
          id: 'birthCertificateProof',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Husband/wife name',
          id: 'husbandOrWifeName',
          placeholder: 'Enter Husband/wife name',
          required: false,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Husband/wife nationality',
          id: 'husbandOrWifeNationality',
          placeholder: 'Enter Husband/wife nationality',
          required: false,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Do you have any dependents',
          id: 'isDependentExist',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: false,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'In case of Dependents, How many dependents?',
          id: 'noOfChildren',
          placeholder: 'Enter number of Dependents',
          required: false,
        },
      },
      {
        type: 'dynamicFields',
        compProps: {
          id: 'dependentInfo',
          cardType: 'Dependent',
          label: 'Dependents Info',
          lookUpField: 'noOfChildren',
          required: false,
          errorText: 'Please enter all Children / Dependent info',
          attributeForm: {
            modalTitle: 'Add Dependent Info',
            editTitle: 'Edit Dependent Info',
            fields: [
              {
                type: 'text',
                compProps: {
                  label: 'Full Name',
                  id: 'dependentName',
                  placeholder: 'Enter Full Name',
                  required: false,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Maiden Name',
                  id: 'maidenName',
                  placeholder: 'Enter Maiden Name',
                  required: false,
                },
              },
              {
                type: 'number',
                compProps: {
                  id: 'dependentAge',
                  placeholder: 'Age',
                  label: 'Age',
                  required: false,
                },
              },
              {
                type: 'radio',
                compProps: {
                  id: 'dependentSex',
                  direction: 'horizontal',
                  label: 'Sex',
                  value: 'Male',
                  options: [
                    {
                      id: 'Male',
                      label: 'Male',
                    },
                    {
                      id: 'Female',
                      label: 'Female',
                    },
                  ],
                  required: false,
                },
              },
              {
                type: 'countrySelect',
                compProps: {
                  label: 'Nationality',
                  id: 'dependentNationality',
                  placeholder: 'Enter Nationality',
                  required: false,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Passport Number',
                  id: 'dependentPassport',
                  placeholder: 'Enter Passport Number',
                  required: false,
                },
              },
              {
                type: 'select',
                compProps: {
                  label: 'Relationship to Applicant',
                  id: 'dependentRelationship',
                  options: [
                    {
                      label: 'Minor Child',
                      id: 'MINOR_CHILD',
                    },
                    {
                      label: 'Spouse ',
                      id: 'SPOUSE',
                    },
                    {
                      label: 'Other',
                      id: 'OTHER',
                    },
                  ],
                  required: false,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Certified Copy of Birth Certificate',
                  id: 'certifyBirth',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  required: false,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Bio-Data Page of Valid Passport',
                  id: 'passportProof',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  required: false,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Proof of Adoption (Where necessary)',
                  id: 'adoption',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  // required: false,
                },
              },
            ],
            layout: [
              ['dependentName'],
              ['dependentAge'],
              ['dependentSex'],
              ['dependentNationality'],
              ['dependentPassport'],
              ['dependentRelationship'],
            ],
            dynamicLayout: {
              dependentRelationship: {
                MINOR_CHILD: [
                  ['certifyBirth'],
                  ['passportProof'],
                  ['adoption'],
                ],
              },
            },
            validatorConfig: {
              ruleSets: [
                {
                  fields: {
                    dependentName: [
                      {
                        type: 'matches',
                        pattern: '^[A-Za-z ]+$',
                        errorMessage: 'Name is invalid.',
                      },
                    ],
                    passportNumber: [
                      {
                        type: 'matches',
                        pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
                        errorMessage: 'Passport is not valid',
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    ],
  ],

  lay: [
    {
      first: {
        label: 'Employee Information',
        data: [
          ['isLastNameExist'],
          ['firstName', 'middleInitial'],
          ['streetAddress1'],
          ['streetAddress2'],
          ['city', 'placeOfBirth'],
          ['intendedStreetAddress1'],
          ['intendedStreetAddress2'],
          ['telephoneContact', 'userEmail'],
          ['gender', 'dateOfBirth'],
          ['countryOfBirth', 'nationality'],
          ['maritalStatus'],
        ],
      },

      second: {
        label: 'Documents',
        data: [
          ['policeClearanceCertificate'],
          ['photograph'],
          ['birthCertificateProof'],
          ['passportProof'],
        ],
      },
    },
    {
      first: {
        label: 'Employment  Information',
        data: [
          ['jobTitle'],
          ['noOfYearsWithCompany'],
          ['qualificationName'],
          ['qualificationProof'],
          ['occupationalSkill', 'passport'],
          ['passportExpiry', 'typeOfPassport'],
        ],
      },
    },
  ],
  dynamicLayout: {
    countryOfBirth:createCountryObject(countriesOtherThanJamaica, [['countryNaturalizationCertificate']]),
    isLastNameExist: {
      yes: [['lastName']],
    },
    criminalOffence: {
      yes: [['countryConvicted', 'dateOfConviction'], ['natureOfOffence']],
    },
    gender: {
      female: [['maidenName']],
    },
    workedInJamaica: {
      yes: [
        ['detailsOnPrevious'],
        ['nameOfEmployer'],
        ['employerAddress1', 'employerAddress2'],
        ['employerParish', 'employerCity'],
        ['telephoneOfEmployer'],
        ['workPermitNumber', 'workPermitExpiry'],
        ['previousEmployerProof'],
      ],
    },
    maritalStatus: {
      married: [['isHusbandWifeWorkedInJamaica']],
    },
    isHusbandWifeWorkedInJamaica: {
      yes: [
        ['detailsOfHusbandWife'],
        ['nameOfEmployerDep'],

        ['employerAddress1Dep', 'employerAddress2Dep'],
        ['employerParishDep', 'employerCityDep'],
        ['workPermitNumberDep', 'workPermitExpiryDep'],
        ['previousEmployerProofDep'],
      ],
    },
    isMailingAddressSame: {
      no: [
        ['mailingAddress1', 'mailingAddress2'],
        ['mailingParish', 'mailingCity'],
        //['mailingParish', 'mailingPostalCode'],
      ],
    },

    workPermit: {
      others: [['workPermitOthers']],
    },
    byAdvertisement: {
      yes: [['byAdvertisementFile'], ['didJamaicanApply']],
    },
    didJamaicanApply: {
      yes: [['appliedCandidates', 'reasonNoneHired'], ['candidateResumes']],
    },
    registeredLMIS: {
      yes: [['jobId']],
    },
    benefitsReceived: {
      yes: [
        ['perquisites'],
        ['house', 'car'],
        ['entertainment', 'allowanceOther'],
      ],
    },
    // maritalStatus: {
    //   divorced: [['husbandOrWifeName', 'husbandOrWifeNationality']],
    //   widowed: [['husbandOrWifeName', 'husbandOrWifeNationality']],
    //   married: [['husbandOrWifeName', 'husbandOrWifeNationality']],
    //   separated: [['husbandOrWifeName', 'husbandOrWifeNationality']],
    // },
  },
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          lastName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'Last Name is Invalid',
            },
          ],
          firstName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'First Name is Invalid',
            },
          ],
          middleInitial: [
            {
              type: 'matches',
              pattern: '.{3,}',
              errorMessage: 'Middle Name should be atleast 3 characters',
            },
          ],
          alias: [
            {
              type: 'matches',
              pattern: '.{3,}',
              errorMessage: 'Alias should be atleast 3 characters',
            },
          ],

          jobId: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z0-9-]+$',
              errorMessage: 'Invalid Job Id',
            },
          ],
          dependentPassport: [
            {
              type: 'matches',
              pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
              errorMessage: 'Passport is not valid',
            },
          ],
          passport: [
            {
              type: 'matches',
              pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
              errorMessage: 'Passport is not valid',
            },
          ],
          // businessTrn: [
          //   {
          //     type: 'matches',
          //     pattern: '^[a-zA-Z0-9]+$',
          //     errorMessage: 'TRN should contain alphabets and numbers.',
          //   },
          // ],
        },
      },
    ],
  },

  availableDocuments: {
    countryNaturalizationCertificate:'NATUARALIZATION_CERTIFICATE',
    csmeProof: 'CSME_COPY',
    jobTitleProof: 'JOB_TITLE_PROOF',
    taxCompilance: 'TAX_COMPILANCE',
    byAdvertisementFile: 'BY_ADVERTISEMENT_FILE',
    stepTakenProof: 'STEP_TAKEN_PROOF',
    signatureProof: 'EMPLOYER_SIGNATURE',
    birthCertificateProof: 'BIRTH_CERTIFICATE_PROOF',
    nationalityProof: 'NAIONALITY_PROOF',
    occupationProof: 'OCCUPATION_PROOF',
    passportProof: 'PASSPORT_PROOF',
    qualificationProof: 'QUALIFICATION_PROOF',
    workExperienceProof: 'WORK_EXPERIENCE_PROOF',
    photograph: 'PHOTOGRAPH',
    policeClearanceCertificate: 'POLICE_CLEARANCE_CERTIFICATE',
    resumeProof: 'RESUME_PROOF',
    coveringLetterProof: 'COVERING_LETTER_PROOF',
    previousEmployerProof: 'PREVIOUS_EMPLOYER_PROOF',
    signatureDep: 'APPLICANT_SIGNATURE',
    previousEmployerProofDep: 'PREVIOUS_EMPLOYER_PROOF_DEP',
    candidateResumes: 'CANDIDATE_RESUME',
    adoption: 'ADOPTION_CERTIFICATE_PROOF',
    divorce: 'DIVORCE_CERTIFICATE_PROOF',
    marriage: 'MARRIAGE_CERTIFICATE_PROOF',
    academic: 'ACADEMIC_CERTIFICATE_PROOF',
    nameChange: 'NAME_CHANGE_PROOF',
    certifyBirth: 'CERTIFIED_COPY_OF_BIRTH_CERTIFICATE',
    biodata: 'BIODATA_PAGE_COPY',
    deedPoll: 'DEED_POLL_COPY',
    csmeCertificate: 'CSME_CERTIFICATE',
    passportSizePhoto: 'PASSPORT_SIZE_PHOTO',
    nolGender: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    nolSports: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    nolMusic: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    nolMedia: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    graduateQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    nurseQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    associateDegreeQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    teacherQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
  },
  userFormFields: [
    {
      type: 'text',
      compProps: {
        id: 'lastName',
        label: 'Last Name',
        placeholder: 'Enter last name',

        errorText: 'Last name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'firstName',
        label: 'First Name',
        placeholder: 'Enter first name',
        required: true,
        errorText: 'First name is required',
      },
    },

    {
      type: 'email',
      compProps: {
        id: 'userEmail',
        label: 'Email id',
        required: true,

        placeholder: 'Enter email address',
        errorText: 'Email is required',
      },
    },
  ],
  userFormLayout: [['lastName', 'firstName'], ['userEmail']],
  userValidatorConfig: {
    ruleSets: [
      {
        fields: {
          lastName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'Last Name is Invalid',
            },
          ],
          firstName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'First Name is Invalid',
            },
          ],
        },
      },
    ],
  },
};

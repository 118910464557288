export const errorConfig = {
  getApplications: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },

  deleteDraft: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
  csvFailure: {
    title: "Uh oh.",
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: [
      "Timeout : Data is big!",
      "Try with the shorter start and end dates"
    ],
    acknowledgementButtonText: "Close"
  }
};

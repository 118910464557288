export const headerConfig = {
  headerTitle: 'Application Detail',
  headerDescription: 'Shows all details in the application.',
  refreshButton: {
    id: 'refresh',
    label: 'Refresh',
    icon: 'RefreshIcon',
  },
};

export const applicationcategory = {
  DRAFT: 'Draft Application',
  APPLICANT_DRAFT: 'Draft Application',
  READY_TO_PAY: 'Pending Payment',
  APPLICATION_APPROVED: 'Pending Payment',
  SUBMITTED: 'Application Being Processed',
  RESUBMITTED: 'Application Being Processed',
  PROCESSED: 'Approved Application',
  MISSING_INFORMATION: 'Missing Information',
  DOCUMENTATION_REJECTED: 'Missing Information',
  APPLICATION_DENIED: 'Rejected Application',
  APPEALED: 'Rejected Application',
  PROCESSING_OFFICER_REVIEW: 'Application Being Processed',
  PROCESSING_FEES_RECEIVED: 'Application Being Processed',
  TERMINATION_REQUESTED: 'Termination requested',
  PROCESSING_OFFICER_SUPERVISOR_REVIEW: 'Application Being Processed',
  MINISTRY_REVIEW: 'Application Being Processed',
  DIRECTOR_REVIEW: 'Application Being Processed',
  DIRECTOR_REVIEW_INVESTIGATION: 'Application Being Processed',
  COMMITEE_REVIEW_INVESTIGATION: 'Application Being Processed',
  COMMITEE_REVIEW: 'Application Being Processed',
  MINISTER_REVIEW_INVESTIGATION: 'Application Being Processed',
  MINISTER_REVIEW: 'Application Being Processed',
  COMPLIANCE_ISSUE_DIRECTOR_REVIEW: 'Application Being Processed',
  WP_FEES_RECEIVED: 'Application Being Processed',
};

// Draft Application(Employer) : "DRAFT","APPLICANT_DRAFT"
// Draft Application(Applicant) : "APPLICANT_DRAFT"
// Application Status :no filter
// Pending Payments: "READY_TO_PAY" , "APPLICATION_APPROVED"
// Submitted Applications: "SUBMITTED"
// Approved Applications: "WP_ISSUED"
// Missing Information: "MISSING_INFORMATION","DOCUMENTATION_REJECTED"
// Rejected Applications :"APPLICATION_DENIED","APPEALED"
// Work Permit: no filter

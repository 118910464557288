//@ts-nocheck
export const getFormData = (
  initialData: any,
  applicationNo: any,
  credentialNo:any,
  workPermitFormData: any,
  csmeFormData: any,
  renewCsmeFormData: any,
  marriageExemptionFormData: any,
  csmeVerificationFormData: any,
  renewWorkPermitFormData: any,
  roeworkPermitFormData: any
) => {
  console.log('cred->',credentialNo,typeof credentialNo)

  const formData: any[] = [];
  const fileData: any[] = [];
  const _specialData: any[] = [];
  const _specialNavItems: any[] = [];

  const special = {
    qualification: 'Qualification',
    dependentInfo: 'Dependents',
    workExperience: 'Work Experience',
    // expatriateInfo : 'Expatriate Info',
    // jamaicanInfo : 'Jamaican Info',
    // marriageInfo : 'Marriage Info'
  };
  console.log("applicationdetails-->page--->",initialData,roeworkPermitFormData,workPermitFormData);
  let data = [];
  if(initialData?.type === "CARICOM_NEW_WORK_PERMIT" || initialData?.type === "CARICOM_RENEW_WORK_PERMIT"){
    data = roeworkPermitFormData;
  } 
  else if (initialData?.type === 'NEW_WORK_PERMIT') {
    data = workPermitFormData;
  } else if (initialData?.type === 'MARRIAGE_EXEMPTION') {
    data = marriageExemptionFormData;
  } else if (
  //  typeof credentialNo==='string' &&
  !credentialNo?.startsWith('WP') &&
  !credentialNo?.startsWith('GE') &&
    (initialData?.type === 'NEW_CSME_CERTIFICATE')
  ) {
    data = csmeFormData;
  } else if (applicationNo && applicationNo?.startsWith('RCSME')) {
    data = renewCsmeFormData;
  } else if (initialData?.type === 'CSME_VERIFICATION') {
    data = csmeVerificationFormData;
  } else if (
    (initialData?.type === 'RENEW_WORK_PERMIT') ||
    (credentialNo)
  ) {
    data = renewWorkPermitFormData;
  } 
  // else if (
  //   (applicationNo && applicationNo?.startsWith('RROE')) ||
  //   (applicationNo && applicationNo?.startsWith('NROE'))
  // ) {
  //   data = roeworkPermitFormData;
  // }
  let isUser = false;
  let compulsoryfields = [
    'noOfChildren',
    'jamaicanProfessional',
    'jamaicanClerks',
    'jamaicanSkilledWorkers',
    'jamaicanPlant',
    'jamaicanElementary',
    'carricomProfessional',
    'carricomClerks',
    'carricomSkilledWorkers',
    'carricomPlant',
    'carricomElementary',
    'commonWealthProfessional',
    'commonWealthClerks',
    'commonWealthSkilledWorkers',
    'commonWealthPlant',
    'commonWealthElementary',
    'foreignProfessional',
    'foreignClerks',
    'foreignSkilledWorkers',
    'foreignPlant',
    'foreignElementary',
    'finalAcknowledgementCheck',
  ];
  console.log('master->', initialData, data);
  for (let step = 0; step < 2; step++) {
    console.log(`eachloop->${step}`, data?.fields?.[step]);
    data?.fields?.[step]?.forEach((_item: any) => {
      console.log('jeyaramfinal->', _item?.compProps?.id);
      let ans = initialData?.[_item?.compProps?.id];
      if (
        special[_item?.compProps?.id] &&
        Array.isArray(ans) &&
        ans.length > 0
      ) {
        console.log('suspect->', ans);
        _specialNavItems.push({
          key: _item?.compProps?.id,
          title: special[_item?.compProps?.id],
          link: `/${_item?.compProps?.id}`,
          type: 'navbar',
        });
        const reqEle = _item?.compProps?.attributeForm?.fields;
        const totalData: any[] = [];
        console.log('liu->', ans);
        ans?.forEach((val) => {
          const currData: any[] = [];
          const currDocData: any[] = [];

          Object.entries(val)?.forEach((_item) => {
            const ans1 = reqEle?.filter(
              (element) => _item[0] === element?.compProps?.id
            )?.[0];
            console.log('suspect->->', ans1);
            if (_item[0] !== 'id' && ans1?.compProps?.label && _item[1]) {
              console.log('liop->', _item[1]);
              if (typeof _item[1] === 'string') {
                currData.push({
                  title: ans1?.compProps?.label,
                  subTitle: _item[1],
                  icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
                });
              } else if (
                _item?.[1]?.[0]?.type === 'image/png' ||
                _item?.[1]?.[0]?.type === 'image/jpg' ||
                _item?.[1]?.[0]?.type === 'image/jpeg'
              ) {
                currDocData.push({
                  type: 'img',
                  src: _item?.[1]?.[0]?.src,
                  meta: [_item?.[1]?.[0]?.name, _item?.[1]?.[0]?.size],
                  title: [ans1?.compProps?.label],
                });
              } else if (_item?.[1]?.[0]?.type === 'application/pdf') {
                console.log('pdfcoming...');
                currDocData.push({
                  type: 'pdf',
                  src: _item?.[1]?.[0]?.src,
                  meta: [_item?.[1]?.[0]?.name, _item?.[1]?.[0]?.size],
                  title: [ans1?.compProps?.label],
                });
              }
            }
          });
          console.log('liop->', currData, currDocData);
          if (currDocData?.length === 0) {
            totalData.push(currData);
          } else {
            totalData.push({ doc: currDocData, data: currData });
          }
        });

        _specialData.push({
          type: 'accordion',
          key: _item?.compProps?.id,
          data: totalData?.map((data, key: number) => {
            if (data?.doc) {
              return [
                {
                  type: 'multi-col-list',
                  title: `${special[_item?.compProps?.id]} ${key + 1}`,
                  numberOfColumns: '3',
                  data: data?.data,
                },
                {
                  type: 'media-carousel',
                  key: `org-documents ${key + 1}`,
                  data: data?.doc,
                },
              ];
            }
            return {
              type: 'multi-col-list',
              title: `${special[_item?.compProps?.id]} ${key + 1}`,
              numberOfColumns: '3',
              data: data,
            };
          }),
        });
        console.log('firstans', ans);
      } else if (Array.isArray(ans)) {
        if (ans?.length > 0) {
          if (
            ans[0]?.type === 'image/png' ||
            ans[0]?.type === 'image/jpg' ||
            ans[0]?.type === 'image/jpeg'
          ) {
            fileData.push({
              type: 'img',
              src: ans[0]?.src,
              meta: [ans[0]?.name, ans[0]?.size],
              title: [_item?.compProps?.label],
            });
          } else if (ans[0]?.type === 'application/pdf') {
            console.log('pdfcoming...');
            fileData.push({
              type: 'pdf',
              src: ans[0]?.src,
              meta: [ans[0]?.name, ans[0]?.size],
              title: [_item?.compProps?.label],
            });
          }
        }
      } else {
        console.log('jramfinal->', _item?.compProps?.id);
        if (
          _item?.compProps?.label &&
          (ans ||
            (ans === 0 && compulsoryfields.includes(_item?.compProps?.id))) &&
          !_item?.compProps?.id?.startsWith('is')
        ) {
          if (_item?.compProps?.id === 'firstName') {
            isUser = true;
          }
          formData.push({
            title: _item?.compProps?.label,
            subTitle: ans + '',
            icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
          });
        }
      }
    });
  }
  console.log('find->123', formData, fileData, isUser);
  return { formData, fileData, isUser, _specialData, _specialNavItems };
};

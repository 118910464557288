export const newUserConfig = {
  FIELDS: [
    {
      type: 'text',
      compProps: {
        id: 'firstName',
        label: 'First Name',
        placeholder: 'Enter first name',
        required: true,
        errorText: 'First name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'lastName',
        label: 'LastName',
        placeholder: 'Enter last name',
        required: true,
        errorText: 'Last name is required',
      },
    },
    {
      type: 'email',
      compProps: {
        id: 'email',
        label: 'Email',
        required: true,

        placeholder: 'Enter email',
        errorText: 'Email is required',
      },
    },
    {
      type: 'checkBox',
      compProps: {
        id: 'sendInvite',
        required: true,
        errorText: 'Terms of Use is required',
        direction: 'vertical',
        readOnly: true,
        options: [
          {
            id: 'yes',
            label: 'Send invite to email',
          },
        ],
      },
    },
  ],
  layout: [['lastName'], ['firstName'], ['email']],
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          firstName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'Name is invalid.',
            },
          ],
          lastName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]+([ ]+[a-zA-Z]*)*$',
              errorMessage: 'Name is not valid',
            },
          ],
        },
      },
    ],
  },
};

import styled from 'styled-components';

import sytled from 'styled-components';
export const Img = styled.img`
  width: ${(props: any) =>
    props?.fontScale !== undefined
      ? `${20 + props?.fontScale * 2.5}px`
      : '20px'} !important;
  margin-right: 5px;
`;

export const UserFormTitleStyle = {
  fontSize: '25px',
  fontWeight: 'bold',
};

export const headerConfig = {
  headerTitle: 'Users List',
  headerDescription: 'List of Users',
  refreshButton: {
    id: 'refresh',
    label: 'Refresh',
    icon: 'RefreshIcon',
  },
  // headerAction1: {
  //   id: 'invite_admin',
  //   label: 'Invite Admin',
  //   icon: 'PlusIcon',
  // },
};

export const notifications = {
  updateProfileSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['Profile has been updated Successfully'],
    acknowledgementButtonText: 'Close',
  },
  createProfileSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['Submitted for review succesfully.'],
    acknowledgementButtonText: 'Close',
  },
};

export const userListConfig = {
  url: 'https://kali-dev-dc.vlinder.io/digicert/user/find',
  bulkOptions: [
    // {
    //   value: 'reset_password',
    //   label: 'Reset Password',
    // },
    {
      value: 'delete_user',
      label: 'Delete User',
    },
    {
      value: 'suspend_user',
      label: 'Suspend User',
    },
    {
      value: 'invite_user',
      label: 'Invite User',
    },
  ],
  ultTableHeader: ['Name', 'Action', 'Email', 'Role', 'Status', 'Date Created'],
  ultUsers: [
    {
      id: '1',
      status: 'active',
      firstName: 'John',
      middleName: 'dae',
      lastName: 'Doe',
      email: 'john@gmail.com',
      dateCreated: '20 July 2023',
    },
    {
      id: '2',
      status: 'active',
      firstName: 'Johny',
      middleName: 'hims',
      lastName: 'Doe',
      email: 'jony@gmail.com',
      dateCreated: '21 July 2023',
    },
  ],
  currentPage: 1,
  pageSize: 10,
  totalCount: 2,
  totalPages: 1,
  activePage: 1,
  role: 'admin',
};

export const errorConfig = {
  getApplications: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },

  deleteDraft: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
};
